<template>
  <div>
    <strong>{{ $t("requests.old_status") }}: </strong>
    {{ getStatusName(log.oldStatus) }} <br />
    <strong>{{ $t("requests.new_status") }}: </strong>
    {{ $t("logs.request_order_created") }} <br />
  </div>
</template>

<script>
import { getStatusName } from "@/modules/crm/decorators/statusDecorator.js";

export default {
  name: "RequestLogOrderCreated",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: { getStatusName },
};
</script>

<style lang="scss" scoped></style>

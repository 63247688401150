<template>
  <tr class=" ">
    <td class="">
      {{ getLogDatetime(log) }}
    </td>
    <td v-if="showRequest" class="">
      <router-link
        v-if="log.requestNumber"
        :to="{ name: 'pest-request-view', params: { id: log.itemId } }"
        class=""
      >
        {{ log.requestNumber }}
      </router-link>
      <span v-else> {{ $t("requests.without_number") }} </span>
    </td>
    <td class="">
      {{ getLogUser(log) }}
    </td>
    <td class="">
      {{ getLogTypeTitle(log) }}
    </td>
    <td class="">
      <request-log-changed-assignee
        v-if="
          [
            types.REQUEST_CHANGED_ASSIGNEE,
            types.REQUEST_BITRIX_CHANGED_ASSIGNEE,
          ].includes(log.type)
        "
        :log="log"
      />
      <request-log-open
        v-else-if="log.type === types.REQUEST_OPEN"
        :log="log"
      />
      <request-log-changed-date
        v-else-if="
          [
            types.REQUEST_CHANGED_DATE,
            types.REQUEST_BITRIX_CHANGED_DATE,
          ].includes(log.type)
        "
        :log="log"
      />
      <request-log-changed-status
        v-else-if="
          [
            types.REQUEST_CHANGED_STATUS,
            types.REQUEST_BITRIX_CHANGED_STATUS,
          ].includes(log.type)
        "
        :log="log"
      />
      <request-log-changed-manager
        v-else-if="log.type === types.REQUEST_CHANGED_MANAGER"
        :log="log"
      />
      <request-log-done
        v-else-if="log.type === types.REQUEST_DONE"
        :log="log"
      />
      <request-log-declined
        v-else-if="log.type === types.REQUEST_DECLINED"
        :log="log"
      />
      <request-log-created
        v-else-if="
          [
            types.REQUEST_CREATED_DUE_IMPORT,
            types.REQUEST_CREATED_FROM_ROISTAT,
            types.REQUEST_CREATED_MANUALLY,
          ].includes(log.type)
        "
        :log="log"
      />
      <request-log-corrected
        v-else-if="log.type === types.REQUEST_CORRECTED"
        :log="log"
      />
      <lead-log-created-manually
        v-else-if="log.type === types.LEAD_CREATED_MANUALLY"
        :log="log"
      />
      <lead-log-changed-status
        v-else-if="log.type === types.LEAD_CHANGED_STATUS"
        :log="log"
      />
      <request-log-new-record
        v-else-if="log.type === types.REQUEST_NEW_RECORD"
        :log="log"
      />
      <request-log-changed-fields
        v-else-if="log.type === types.REQUEST_CHANGED_FIELDS"
        :log="log"
      />
      <log-call-to-client
        v-else-if="log.type === types.CALL_TO_CLIENT"
        :log="log"
      />
      <request-log-zadarma-call-record
        v-else-if="log.type === types.ZADARMA_CALL_RECORD"
        :log="log"
      />
      <request-log-mango-call-record
        v-else-if="log.type === types.MANGO_CALL_RECORD"
        :log="log"
      />
      <log-employee-unblocked
        v-else-if="log.type === types.EMPLOYEE_UNBLOCKED"
        :log="log"
      />
      <log-appartment-shown
        v-else-if="log.type === types.REQUEST_ACCESS_APPARTMENT_SUCCESS"
        :log="log"
      />
      <log-appartment-forbidden
        v-else-if="log.type === types.REQUEST_ACCESS_APPARTMENT_FAILURE"
        :log="log"
      />
      <log-user-coords
        v-else-if="log.type === types.USER_COORDINATES"
        :log="log"
      />
      <deposit-created-log-info
        v-else-if="log.type === types.DEPOSIT_CREATED"
        :log="log"
      />
      <payment-created-log-info
        v-else-if="log.type === types.PAYMENT_CREATED"
        :log="log"
      />
      <request-log-converted-to-contract
        v-else-if="log.type === types.CONVERTED_TO_CONTRACT"
        :log="log"
      />
      <request-log-duplicate-lead
        v-else-if="log.type === types.DUPLICATE_LEAD"
        :log="log"
      />
      <request-log-duplicate-lead-assigned
        v-else-if="log.type === types.DUPLICATE_LEAD_ASSIGNED"
        :log="log"
      />
      <request-log-rejected
        v-else-if="log.type === types.REQUEST_REJECTED"
        :log="log"
      />
      <request-log-order-created
        v-else-if="log.type === types.REQUEST_ORDER_CREATED"
        :log="log"
      />
      <request-log-aftercall
        v-else-if="log.type === types.REQUEST_AFTERCALL"
        :log="log"
      />
      <request-yandex-conversion-log
        v-else-if="log.type === types.REQUEST_YANDEX_CONVERSION_SEND"
        :log="log"
      />
      <request-yandex-conversion-log
        v-else-if="log.type === types.REQUEST_YANDEX_CONVERSION_SKIPPED"
        :log="log"
      />
      <request-notification-call
        v-else-if="
          [
            types.NOTIFICATION_CALL_ADDED,
            types.NOTIFICATION_CALL_DELETED,
          ].includes(log.type)
        "
        :log="log"
      />
      <user-change-password
        v-else-if="log.type === types.USER_CHANGE_PASSWORD"
        :log="log"
      />
      <span
        v-else-if="
          [
            types.USER_LOGIN_OK,
            types.USER_LOGIN_ERROR,
            types.USER_LOGOUT,
            types.REQUEST_RETURN_VISIT,
          ].includes(log.type)
        "
      ></span>
      <span v-else-if="log.type === types.REQUEST_EDIT_SHOW_TO_ASSIGNEE">{{
        $t("logs." + types.REQUEST_EDIT_SHOW_TO_ASSIGNEE)
      }}</span>
      <span v-else-if="log.type === types.REQUEST_EDIT_HIDE_FROM_ASSIGNEE">{{
        $t("logs." + types.REQUEST_EDIT_HIDE_FROM_ASSIGNEE)
      }}</span>
      <span
        v-else-if="log.type === types.REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE"
        >{{ $t("logs." + types.REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE) }}</span
      >
      <span
        v-else-if="log.type === types.REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE"
        >{{ $t("logs." + types.REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE) }}</span
      >
      <request-log-recurring-queued
        v-else-if="log.type === types.LEAD_AUTO_RECURRING_CALL_QUEUED"
        :log="log"
      />
      <div v-else-if="log.type == types.REQUEST_REANIMATED"></div>
      <div v-else-if="isCallServiceMessage"></div>
      <div v-else>{{ $t("notifications.unknown_msg") }}</div>
    </td>
  </tr>
</template>

<script>
import * as types from "@/modules/pest/decorators/logs";
import {
  getLogDatetime,
  getLogRequestTitle,
  getLogTypeTitle,
  getLogUser,
  REQUEST_RETURN_VISIT,
} from "@/modules/pest/decorators/logs";
import RequestLogChangedAssignee from "@/modules/pest/views/logs/details/RequestLogChangedAssignee";
import RequestLogDone from "@/modules/pest/views/logs/details/RequestLogDone";
import RequestLogDeclined from "@/modules/pest/views/logs/details/RequestLogDeclined";
import RequestLogOrderCreated from "@/modules/pest/views/logs/details/RequestLogOrderCreated";
import RequestLogCreated from "@/modules/pest/views/logs/details/RequestLogCreated";
import LogCallToClient from "@/modules/pest/views/logs/details/LogCallToClient.vue";
import RequestLogCorrected from "@/modules/pest/views/logs/details/RequestLogCorrected.vue";
import LeadLogChangedStatus from "@/modules/pest/views/logs/details/LeadLogChangedStatus.vue";
import LeadLogCreatedManually from "@/modules/pest/views/logs/details/LeadLogCreatedManually.vue";
import RequestLogNewRecord from "@/modules/pest/views/logs/details/RequestLogNewRecord.vue";
import LogEmployeeUnblocked from "@/modules/pest/views/logs/details/LogEmployeeUnblocked.vue";
import LogAppartmentShown from "@/modules/pest/views/logs/details/LogAppartmentShown.vue";
import LogAppartmentForbidden from "@/modules/pest/views/logs/details/LogAppartmentForbidden.vue";
import LogUserCoords from "@/modules/pest/views/logs/details/LogUserCoords.vue";
import DepositCreatedLogInfo from "@/modules/pest/views/logs/details/DepositCreatedLogInfo.vue";
import PaymentCreatedLogInfo from "./details/PaymentCreatedLogInfo.vue";
import RequestLogConvertedToContract from "@/modules/pest/views/logs/details/RequestLogConvertedToContract";
import RequestNotificationCall from "@/modules/pest/views/logs/details/RequestNotificationCall";
import RequestLogAftercall from "@/modules/pest/views/logs/details/RequestLogAftercall";
import RequestYandexConversionLog from "@/modules/pest/views/logs/details/RequestYandexConversionLog";
import UserChangePassword from "@/modules/pest/views/logs/details/UserChangePassword";
import RequestLogChangedDate from "@/modules/pest/views/logs/details/RequestLogChangedDate";
import RequestLogChangedStatus from "@/modules/pest/views/logs/details/RequestLogChangedStatus";
import RequestLogRecurringQueued from "@/modules/pest/views/logs/details/RequestLogRecurringQueued.vue";
import RequestLogZadarmaCallRecord from "@/modules/pest/views/logs/details/RequestLogZadarmaCallRecord.vue";
import RequestLogMangoCallRecord from "@/modules/pest/views/logs/details/RequestLogMangoCallRecord.vue";
import RequestLogRejected from "@/modules/pest/views/logs/details/RequestLogRejected.vue";
import RequestLogChangedManager from "@/modules/pest/views/logs/details/RequestLogChangedManager.vue";
import RequestLogOpen from "@/modules/pest/views/logs/details/RequestLogOpen.vue";
import RequestLogDuplicateLead from "@/modules/pest/views/logs/details/RequestLogDuplicateLead.vue";
import RequestLogDuplicateLeadAssigned from "@/modules/pest/views/logs/details/RequestLogDuplicateLeadAssigned.vue";
import RequestLogChangedFields from "@/modules/pest/views/logs/details/RequestLogChangedFields.vue";

export default {
  name: "RequestLog",
  components: {
    RequestLogChangedFields,
    RequestLogDuplicateLead,
    RequestLogDuplicateLeadAssigned,
    RequestLogOpen,
    RequestLogChangedManager,
    RequestLogRejected,
    RequestLogOrderCreated,
    RequestLogMangoCallRecord,
    RequestLogZadarmaCallRecord,
    RequestLogRecurringQueued,
    RequestYandexConversionLog,
    RequestLogAftercall,
    RequestNotificationCall,
    RequestLogConvertedToContract,
    RequestLogChangedStatus,
    RequestLogChangedDate,
    UserChangePassword,
    PaymentCreatedLogInfo,
    DepositCreatedLogInfo,
    LogUserCoords,
    LogAppartmentForbidden,
    LogAppartmentShown,
    LogEmployeeUnblocked,
    LeadLogCreatedManually,
    LeadLogChangedStatus,
    RequestLogNewRecord,
    RequestLogCorrected,
    LogCallToClient,
    RequestLogCreated,
    RequestLogDeclined,
    RequestLogDone,
    RequestLogChangedAssignee,
  },
  props: ["log", "showRequest"],
  data() {
    return {
      types,
    };
  },
  computed: {
    isCallServiceMessage() {
      return [
        types.LEAD_AUTO_INITIAL_CALL_QUEUED,
        types.LEAD_AUTO_INITIAL_CALL_ANSWERED,
        types.LEAD_AUTO_INITIAL_CALL_NOT_ANSWERED,
        // types.LEAD_AUTO_RECURRING_CALL_QUEUED,
        types.LEAD_AUTO_RECURRING_CALL_ANSWERED,
        types.LEAD_AUTO_RECURRING_CALL_NOT_ANSWERED,
        types.LEAD_AUTO_RECURRING_CALL_STOPPED,
      ].includes(this.log.type);
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getLogDatetime,
    getLogUser,
    getLogRequestTitle,
    getLogTypeTitle,
  },
};
</script>

<style lang="scss" scoped></style>

import EmployeePledge from "@/modules/pest/views/pledge/EmployeePledge.vue";

export default [
  {
    path: "/pledge/my",
    name: "pest-employee-pledge",
    components: {
      default: EmployeePledge,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "menu.my_pledge",
    },
  },
];

import PriceList from "@/modules/pest/views/prices/PriceList";
import PriceCreate from "@/modules/pest/views/prices/PriceCreate";
import PriceUpdate from "@/modules/pest/views/prices/PriceUpdate";
import PriceView from "@/modules/pest/views/prices/PriceView";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/prices/page:page?",
    name: "pest-price-list",
    components: {
      default: PriceList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/prices/new",
    name: "pest-price-create",
    components: {
      default: PriceCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/prices/:id/update",
    name: "pest-price-update",
    components: {
      default: PriceUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/prices/:id",
    name: "pest-price-view",
    components: {
      default: PriceView,
    },
    meta: { requiresAuth: true },
  },
];

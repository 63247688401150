export const ru = {
  requests: {
    request: "Заявка",
    requests: "Заявки",
    contract: "Контракт",
    lead: "Лид",
    info: "Информация о заявке",

    looking_up_duplicates: "Ищем двойников",
    contract_n: "Контракт №",
    request_n: "Заявка  №",
    deal_n: "Сделка  №",
    lead_n: "Лид №",
    new_request: "Новая заявка",
    new_deal: "Новая сделка",
    new_lead: "Новый лид",
    source_lead: "Исходный лид",
    duplicate_lead: "Повторный лид",

    add_request: "Добавить заявку",
    close_request: "Закрыть заявку",
    assign_request: "Прикрепить",
    request_declined: "Заявка отменилась",
    add_contact: "Добавить контакт",
    remove_contact: "Удалить контакт",
    select_contact: "Выберите контакт",
    add_phone: "Добавить телефон",
    lead_created: "Лид создан",
    reopen_request: "Реанимировать",
    without_name: "[Нет имени]",

    contact: "Контакт",
    requests_contacts: "Контактные данные",
    requests_visits: "Заявки",
    requests_history: "История",
    first_request: "Первичное обращение",
    first_request_sh: "Первичное",
    follow_up_request: "Повторное обращение",
    follow_up_request_sh: "Повторное",
    assign_follow_up_treatment: "Повторное обращение",
    number: "Номер",
    title: "Название",
    roistat: "Номер Roistat",
    roistat_label: "Метка Roistat",
    without_number: "Без номера",
    manager: "Менеджер",
    old_manager: "Старый менеджер",
    new_manager: "Новый менеджер",
    status: "Статус",
    status_changed: "Статус изменен",
    old_status: "Старый статус",
    new_status: "Новый статус",
    assignee: "Исполнитель",
    old_assignee: "Старый исполнитель",
    new_assignee: "Новый исполнитель",
    assignee1st: "Исполнитель по первичке",
    project: "Проект",
    select_project: "Выберите проект",
    client_name: "Имя",
    phone: "Телефон",
    request_source: "Источник заявки",
    lead_source: "Источник лида",
    select_source: "Выберите источник",
    rejection_comment: "Причина провала",
    enter_rejection_comment: "Введите причину провала",

    assignee_cancel_cause: "Причина отмены мастера",
    enter_assignee_cancel_cause: "Введите причину отмены мастера",
    cost_calculation: "Расчет стоимости",
    town: "Город",
    select_town: "Выберите город",
    pest: "Тип вредителя",
    select_pest: "Выберите вредителя",
    facility: "Объект",
    select_facility: "Выберите объект",
    first_follow_up: "Первичное/вторичное",
    client: "Клиент",
    client_type: "Тип клиента",
    select_client_type: "Выберите тип клиента",
    type_individual: "Физ. лицо",
    type_organization: "Организация",

    payment_type: "Тип оплаты",
    select_payment_type: "Выберите тип оплаты",
    payment_cash: "Нал",
    payment_bank_trans: "Банковский Перевод",
    payment_received: "Оплачено",

    payment_variant: "Тип расчета",
    payment_regular: "Стандарт",
    payment_exchange: "Из биржи (нет рекорда)",
    extra: "Дополнительно",

    sum: "Сумма",
    known_sum: "Стоимость",
    known_sum_manager: "Сумма заявки указанная менеджером",
    trip: "Транспорт",
    trip_distance: "Дорога, км",
    trip_sum: "Дорога, р",
    trip_after_last_request: "Транспорт после последней заявки",
    trip_after_last_request_distance:
      "Стоимость проезда после последней заявки, р",
    trip_after_last_request_sum:
      "Длительность проезда после последней заявки, км",

    cardId: "Номер карточки",
    address: "Адрес",
    address_info_add: "Адрес. доп",
    search_by_address: "Поиск по адресу",
    search_address_example: "ул Ленина, 24",
    address_postcode: "Индекс",
    address_town: "Населенный пункт",
    address_street: "Улица",
    address_enter_address: "Введите адрес",
    address_subway: "Метро",
    address_subway_old: "Метро (старое)",
    address_house: "Дом",
    address_block: "Корпус",
    address_entrance: "Подъезд",
    address_floor: "Этаж",
    address_appartment: "Квартира",
    address_intercom: "Домофон",
    address_info: "Доп информация",
    address_coords: "Координаты",
    search_contract_or_request: "Поиск контракта или лида",

    address_appartment_not_set: "[Квартира не назначена]",
    click_to_see_appartment: "Нажмите на кнопку, чтобы увидеть квартиру",
    show_to_me: "Показать мне",
    phone_is_forbidden: "У вас нет доступа к телефону",
    appartment_is_forbidden: "Вы не видите номер квартиры",
    appartment_visibility: "Видимость квартиры",
    appartment_is_shown_to_assignee: "Квартира отображается исполнителю",
    appartment_is_hidden_from_assignee: "Квартира скрыта от исполнителя",
    address_is_shown_to_assignee: "Адрес отображается исполнителю",
    address_is_hidden_from_assignee: "Адрес скрыт от исполнителя",
    hide_from_assignee: "Скрыть от исполнителя",
    open_to_assignee: "Открыть исполнителю",
    opening_appartment: "Открываем доступ",
    hiding_appartment: "Скрываем доступ",

    appartment_is_shown: "Номер квартиры открыт",
    appartment_is_hidden: "Номер квартиры скрыт",
    access_closed: "Доступ закрыт",
    access_granted: "Доступ открыт",
    you_have_no_access_to_appartment: "Вам недоступен номер квартиры",
    geolocation_is_not_working: "Не удалось определить ваше месторасположение",

    calling: "Соединяю",
    processing_the_call: "Осуществляется звонок",
    make_call: "Позвонить",
    specify_call_cause: "Укажите причину звонка",
    call_cause: "Причину звонка",
    cant_make_the_call: "Не удалось совершить звонок",

    cant_open_the_appartment: "Не удалось открыть адрес",

    time: "Время",
    date: "Дата",
    old_date: "Старая дата",
    new_date: "Новая дата",
    datetime: "Дата / время",
    work_date: "Дата работ",
    created: "Создано",
    request_create_date: "Дата создания лида",
    order_date: "Оформление сделки",
    choose_date: "Выберите дату",
    choose_time: "Выберите дату",

    description: "Описание",
    warning: "Особое внимание",

    results: "Результаты",
    results_correction: "Корректировка результатов",
    error_during_saving_results: "Не удалось сохранить результат",
    confirm_correction: "Скорректировать расчет",
    correct: "Скорректировать",
    services: "Услуги",
    service: "Услуга",
    service_price: "Стоимость услуг",
    products: "Товары",
    product: "Товар",
    products_price_net: "Стоимость товаров (себестоимость)",
    products_price: "Стоимость товаров",
    price: "Цена",
    net_price: "Себестоимость",
    equipment: "Оборудование",
    equipment_sum: "Сумма за оборудование, р",
    equipment_percent_desc: "% от стоимости услуг",
    equipment_fixed_desc: "р фиксированно",
    resources: "Материалы",
    resource: "Материал",
    without_resource: "[Без материала]",
    dosage_1l_ml: "Доз.на 1л, мл",
    dosage_short: "доз.",
    consumption: "расход",
    solution_volume_l: "Объем раствора, л",
    solution_volume_short: "раб.р.",
    earned: "Заработано",
    sum_and_earned: "Сумма / Заработано",
    record: "Рекорд",
    new_record: "Новый рекорд",
    prev_record: "Предыдущий рекорд",
    has_record: "Есть рекорд",
    no_record: "Нет рекорда",
    before_record: "До рекорда",
    from_exchange_without_record_msg: "Заявка из биржи, рекорд не считается",
    close_as_duplicate_msg: "Повторное обращение зарегистрировано",
    close_as_duplicate_error_msg:
      "Не удалось зарегистрировать повторное обращение ",

    request_sum: "Сумма",
    assignee_earn: "Earn",
    assignee_in_hand: "На руки",
    assignee_payment_sum: "К выплате",
    is_paid: "Выплачено",

    trips_history: "Выезды по цепочке",
    follow_up_treatment: "Повтор",
    follow_up_appointed: "Назначен",
    follow_up_not_appointed: "Не назначен",

    duration: "Длительность",
    duration_min: "Длительность, мин",
    comment: "Комментарий",
    cause: "Причина",
    assignee_comment: "Комментарий",
    manager_comment: "Комментарий",
    documents: "Документы",

    follow_up_call_result: "Исход прозвона",
    without_follow_up_call: "Без прозвона",
    follow_up_call_comment: "Комментарий",
    follow_up_call_reason: "Причина",
    follow_up_call_fail_reason: "Причина провала прозвона",
    enter_results: "Внести результаты",

    assignee_novice: "Стажер",
    assignee_regular: "Обычный",
    request_time_updated_mgs: "Время у заявки отредактировано",
    request_time_not_updated_mgs: "Не удалось отредактировать время",
    enter_name: "Введите имя",
    enter_name_mgs: "Введите имя без цифр",
    full_address_mgs: "Для расчета стоимости дороги заполни полный адрес",
    cant_create_request: "Не удалось добавить заявку",
    cant_update_request: "Не удалось отредактировать заявку",
    cant_delete_request: "Не удалось удалить заявку",
    confirm_request_delete: "Вы действительно хотите удалить заявку",
    confirm_request_return: "Подтвердите возврат сделки",
    assignee_not_set: "[Не назначен]",
    request_deleted: "Заявка удалена",
    request_created: "Заявка добавлена",
    choose_another: "Выбрать другого",
    request_updated: "Заявка отредактирована",
    request_closed: "Заявка закрыта",
  },
};
export const en = {
  requests: {
    request: "Request",
    requests: "Requests",
    contract: "Contract",
    lead: "Lead",
    info: "Info",

    looking_up_duplicates: "Looking up duplicates",
    contract_n: "Client #",
    request_n: "Request #",
    deal_n: "Order #",
    lead_n: "Request #",
    new_request: "New request",
    new_deal: "New deal",
    new_lead: "New request",
    source_lead: "Source lead",
    duplicate_lead: "Duplicate lead",

    add_request: "Create request",
    close_request: "Close request",
    assign_request: "Assign",
    request_declined: "Request declined",
    add_contact: "Add contact",
    remove_contact: "Remove contact",
    select_contact: "Select contact",
    add_phone: "Add phone",
    lead_created: "Request created",
    reopen_request: "Reopen request",
    without_name: "[Without name]",

    contact: "Contact",
    requests_contacts: "Contacts",
    requests_visits: "Visits",
    requests_history: "History",
    first_request: "First",
    treatment_type: "Treatment type",
    first_request_sh: "First treatment",
    follow_up_request: "Follow-up request",
    follow_up_request_sh: "Follow-up treatment",
    inspection_request_sh: "Inspection",
    assign_follow_up_treatment: "Assign follow-up treatment",
    number: "Number",
    title: "Title",
    roistat: "Roistat number",
    roistat_label: "Roistat label",
    without_number: "Without number",
    manager: "Operator",
    old_manager: "Old operator",
    new_manager: "New operator",
    status: "Status",
    status_changed: "Status changed",
    old_status: "Old status",
    new_status: "New status",
    assignee: "Technician",
    extra_technician: "Extra technician",
    extra_technicians: "Extra technicians",
    old_assignee: "Old technician",
    new_assignee: "New technician",
    assignee1st: "Technician of first treatment",
    project: "Project",
    select_project: "Select project",
    client: "Client",
    client_name: "Client name",
    phone: "Phone",
    change_assignee_after_inspection_warning:
      "Do you really want to change the original technician",

    request_source: "Request source",
    lead_source: "Request source",
    select_source: "Select source",
    rejection_comment: "Rejection comment",
    enter_rejection_comment: "Enter rejection comment",

    assignee_cancel_cause: "Assignee cancel cause",
    enter_assignee_cancel_cause: "Enter assignee cancel cause",

    cost_calculation: "Cost calculation",
    town: "Town",
    select_town: "Select town",
    pest: "Pest",
    additionalPest: "Additional pest",
    select_pest: "Select pest",
    facility: "Facility",
    select_facility: "Select facility",
    first_follow_up: "First / Follow up",
    client_type: "Client type",
    select_client_type: "Select client type",
    type_individual: "Individual",
    type_organization: "Organization",

    payment_type: "Payment type",
    select_payment_type: "Select payment type",
    payment_cash: "Cash",
    payment_bank_trans: "Bank transfer",
    payment_payment_link: "Payment link",
    payment_received: "Payment received",
    free_inspection: "Free inspection",
    change_treatment_type_question:
      "Are you sure you want to change your treatment type?",

    payment_variant: "Payment variant",
    payment_regular: "Regular",
    payment_exchange: "Exchange (without record)",
    extra: "Extra",

    sum: "Sum",
    known_sum: "Price",
    known_sum_manager: "Price specified by operator",
    trip: "Trip",
    trip_distance: "Trip, km",
    trip_sum: "Trip, $",
    trip_after_last_request: "Trip after last request",
    trip_after_last_request_distance: "Trip after last request, km",
    trip_after_last_request_sum: "Trip after last request, $",

    cardId: "Card Id",
    address: "Address",
    address_info_add: "Address (more)",
    search_by_address: "Search by address",
    search_address_example: "Main st, 23",
    address_postcode: "Postcode",
    address_town: "Town",
    address_street: "Street",
    address_enter_address: "Enter address",
    address_subway: "Subway",
    address_subway_old: "Subway (old)",
    region: "Region",

    google_maps_link: "Google Maps link",

    address_house: "House",
    address_block: "Block",
    address_entrance: "Entrance",
    address_floor: "Floor",
    address_appartment: "Appartment",
    address_intercom: "Intercom",
    address_info: "Info",
    address_coords: "Coordinates",
    search_contract_or_request: "Search contract or request",

    address_appartment_not_set: "[Appartment not set]",
    click_to_see_appartment: "Click to see appartment",
    show_to_me: "Show me",
    phone_is_forbidden: "Phone is hidden",
    appartment_is_forbidden: "Appartment is hidden",
    appartment_visibility: "Appartment visibility",
    appartment_is_shown_to_assignee: "Appartment is shown to assignee",
    appartment_is_hidden_from_assignee: "Appartment is hidden from assignee",
    address_is_shown_to_assignee: "Address is shown to assignee",
    address_is_hidden_from_assignee: "Address is hidden from assignee",
    hide_from_assignee: "Hide from assignee",
    open_to_assignee: "Open to assignee",
    opening_appartment: "Opening appartment",
    hiding_appartment: "Hiding appartment",

    request_can_be_edited_by_assignee: "Request can be edited by assignee",
    request_edit_prohibited_to_assignee: "Request edit prohibited to assignee",

    appartment_is_shown: "Appartment is shown",
    appartment_is_hidden: "Appartment is скрыт",
    access_closed: "Access closed",
    access_granted: "Access granted",
    you_have_no_access_to_appartment: "You have no access to appartment",
    geolocation_is_not_working: "Geolocation is not working",

    calling: "Calling",
    processing_the_call: "Calling",
    make_call: "Make call",
    call_cause: "Call call cause",
    specify_call_cause: "Specify call cause",
    cant_open_the_appartment: "Cant open the appartment",
    cant_make_the_call: "Can't make the call",

    cant_open_edits_to_assignee: "Cant open the edits to assignee",
    results_blocked: "Results blocked",
    results_unblocked: "Results unblocked",

    time: "Time",
    date: "Date",
    old_date: "Old date",
    new_date: "New date",
    datetime: "Datetime",
    work_date: "Work date",
    created: "Created",
    request_create_date: "Request create date",
    order_date: "Order date",
    choose_date: "Choose date",
    choose_time: "Choose time",
    description: "Description",
    warning: "Warning",

    results: "Results",
    results_correction: "Results correction",
    error_during_saving_results: "Error during saving results",
    confirm_correction: "Correct results",
    correct: "Correct",
    services: "Services",
    service: "Service",
    service_price: "Service price",
    products: "Products",
    product: "Product",
    products_price_net: "Products net price",
    products_price: "Products price",
    price: "Price",
    vat_fee: "VAT",
    net_price: "Net price",
    equipment: "Equipment",
    equipment_sum: "Equipment sum, $",
    equipment_percent_desc: "% from service cost",
    equipment_fixed_desc: "$ fixed",
    resources: "Resources",
    resource: "Resource",
    without_resource: "[Without resource]",
    dosage_1l_ml: "Dosage for 1l, ml",
    dosage_short: "dos.",
    consumption: "consumption",
    solution_volume_l: "Solution volume, l",
    solution_volume_short: "Sol. vlm.",
    earned: "Earned",
    sum_and_earned: "Sum / earned",
    record: "Record",
    new_record: "New record",
    prev_record: "Previous record",
    has_record: "Has record",
    no_record: "No record",
    before_record: "Before record",
    from_exchange_without_record_msg:
      "Order from the exchange, the record does not count",
    close_as_duplicate_msg: "Duplicate request registered",
    close_as_duplicate_error_msg: "Can't register duplicate request",

    vat_not_changed: "Vat not changed",
    invalid_value: "Invalid value",

    request_sum: "Sum",
    assignee_earn: "Earn",
    assignee_in_hand: "Profit in hand",
    assignee_payment_sum: "Payment sum",
    is_paid: "Is paid",

    trips_history: "Trips history",
    follow_up_treatment: "Follow up treatment",
    follow_up_appointed: "Appointed",
    follow_up_not_appointed: "Not appointed",

    duration: "Duration",
    duration_min: "Duration, min",

    comment: "Comment",
    cause: "Cause",
    assignee_comment: "Comment",
    manager_comment: "Comment",
    documents: "Documents",
    follow_up_call_result: "Follow up call result",
    follow_up_call_comment: "Comment",
    follow_up_call_reason: "Reason",
    follow_up_call_fail_reason: "Follow up call fail reason",
    enter_results: "Enter results",

    expenses_and_marketing: "Expenses and marketing",

    assignee_novice: "Novice",
    assignee_regular: "Regular",

    request_time_updated_mgs: "Request time is updated",
    request_time_not_updated_mgs: "Can't update request time",

    enter_name: "Enter name",
    enter_name_mgs: "Enter name without numbers",
    full_address_mgs:
      "To calculate the cost of the road, fill in the full address",
    cant_create_request: "Can't create request",
    cant_update_request: "Can't update request",
    cant_delete_request: "Can't delete request",
    confirm_request_delete: "Confirm request removal",
    confirm_request_return: "Confirm request return",
    assignee_not_set: "[Masters not set]",
    request_deleted: "Request deleted",
    request_created: "Request created",
    choose_another: "Choose another",
    request_updated: "Request updated",
    request_closed: "Request closed",
  },
};

<template>
  <div
    v-click-outside="cancelSearch"
    class="autocomplete-wrapper"
    @keydown.esc="cancelSearch"
  >
    <b-form-input
      v-model="search"
      :disabled="disabled"
      :required="required"
      autocomplete="off"
      @change="startSearch"
      @keyup="startSearch"
      @focus="startSearch"
    ></b-form-input>
    <div v-if="(items && items.length) || addNew">
      <ul v-if="showResults" class="autocomplete-search-list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="autocomplete-search-item"
          @click.prevent="selectItem(item)"
        >
          {{ getTitle(item) }}
        </li>
        <li v-if="addNew" class="autocomplete-search-item">Добавить клиента</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UniversalAutocomplete",
  components: {},
  props: {
    label: {},
    title: {},
    placeholder: {},
    required: {},
    defaultValue: {},
    getTitle: {},
    getData: {},
    getValue: {},
    addNew: { type: Boolean, defaultValue: false },
    disabled: {},
  },
  data() {
    return {
      search: "",
      lastSearch: "",
      showResults: false,
      selected: false,
      items: [],
      loading: false,
    };
  },

  computed: {
    // propModel: {
    //   get() {
    //     return this.model
    //   },
    //   set(value) {
    //     this.$emit('update:model', value)
    //   },
    // },
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(value) {
        this.search = value;
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getItemValue(item) {
      if (this.getValue) {
        return this.getValue(item);
      }
      return item ? item.id : null;
    },
    fetchData(search) {
      new Promise((resolve) => {
        this.getData(search, resolve);
      }).then((result) => (this.items = result));
    },

    selectItem(item) {
      this.showResults = false;
      this.search = this.getTitle(item);
      this.propModel = this.getItemValue(item);
      this.$emit("selected", { value: item });
    },
    startSearch() {
      if (this.search === this.defaultValue) {
        this.search = "";
      }
      this.showResults = true;
      this.fetchData(this.search);
    },
    cancelSearch() {
      if (!this.search || this.search.trim() === "") {
        this.search = this.defaultValue;
      }
      this.showResults = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-wrapper {
  position: relative;
}

ul.autocomplete-search-list {
  position: absolute;
  top: 35px;
  z-index: 10000;

  background: #ffffff;
  width: 100%;

  list-style: none;
  border: 1px solid lightgray;
  padding: 0;

  max-height: 300px;
  overflow-y: auto;
}

.autocomplete-search-item:hover {
  cursor: pointer;
  background: #d09e23;
  color: white;
}

.autocomplete-search-item {
  width: 100%;
  margin: 0;
  padding: 10px 20px;
}
</style>

import { TYPE_CLIENT } from "@/modules/users/decorators/users";

const canViewLeads = function () {
  return true;
};
const canViewIssues = function () {
  return this.$store.state.user.access.canViewIssues;
};
const canViewClients = function () {
  return this.$store.state.user.access.canViewClients;
};
const canViewSettings = function () {
  return this.$store.state.user.access.canViewSettings;
};
const canViewTasks = function () {
  return this.$store.state.user.access.canViewTasks;
};
const canViewDocs = function () {
  return this.$store.state.user.access.canViewDocs;
};

const canUpdateIssue = function (issue) {
  return !isClientI(this.$store.state.user);
};
const canAddIssue = function () {
  return !isClientI(this.$store.state.user);
};
const canDeleteIssue = function (issue) {
  return !isClientI(this.$store.state.user);
};
const isClientI = function (user) {
  return user.type === TYPE_CLIENT;
};
const isClient = function () {
  return this.$store.state.user.type === TYPE_CLIENT;
};

export {
  canViewLeads,
  canViewIssues,
  canViewClients,
  canViewTasks,
  canViewDocs,
  canViewSettings,
  isClient,
  canUpdateIssue,
  canAddIssue,
  canDeleteIssue,
};

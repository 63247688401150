/**
 * Подсвечиваем заявку
 * @param vue
 * @param lookupRequest заявка
 * @param toggle Boolean подсветить / убрать
 * @param timer Boolean убрать автоматически спустя некоторое время
 */
export const highlightRequest = function (vue, lookupRequest, toggle, timer) {
  let request = vue.requests.find((r) => r.id === lookupRequest.id);
  if (request) {
    request.highlighted = toggle;
    vue.counter++; // костыль, чтобы обновить нереактивное поле.
    if (toggle && timer) {
      setTimeout(() => {
        request.highlighted = !toggle;
        vue.counter++;
      }, 1000);
    }
  }
};

/**
 * Изменение свойств заявки
 * @param vue
 * @param request
 */
export const updateRequest = function (vue, request) {
  for (let cmpRequest of vue.requests) {
    if (cmpRequest.id === request.id) {
      Object.assign(cmpRequest, request);
      vue.filterRequests();
      return;
    }
  }
};

<template>
  <div class="pest-price-page">
    <div class="row">
      <div class="col-md-8" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title d-flex align-items-center pr-4">
            <div class="ml-1 mr-2">
              <router-link
                :to="{ name: 'pest-price-list' }"
                class="h4 text-muted"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>
            </div>
            <h1>Изменить цены</h1>

            <b-button
              variant="primary"
              class="ml-2"
              @click.prevent="createOrUpdate"
            >
              <font-awesome-icon icon="check" />
              Сохранить
            </b-button>
          </div>
          <div class="ibox-content">
            <pest-price-form
              ref="price-form"
              :price-model="price"
            ></pest-price-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceForm from "./PriceForm";
import {
  CLIENT_TYPE_IND,
  PT_CASH,
} from "@/modules/pest/decorators/requests.js";

export default {
  name: "Price",
  components: {
    "pest-price-form": PriceForm,
  },
  data() {
    return {
      price: {
        id: "new",
        title: "",
        townId: "",
        paymentType: PT_CASH,
        clientType: CLIENT_TYPE_IND,
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
    createOrUpdate() {
      this.$refs["price-form"].createOrUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>

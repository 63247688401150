<template>
  <div class="pb-4">
    <div v-for="(day, dIndex) in days" :key="'d' + dIndex">
      <div class="mb-2 mt-2 text-center">
        <div class="btn btn-outline btn-primary btn-rounded">
          {{ day }}
        </div>
      </div>
      <div
        v-for="(notification, notIndex) in notificationsByDays[day]
          .notifications"
        :key="notIndex"
      >
        <user-notification :notification="notification" />
      </div>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import UserNotification from "@/modules/notifications/views/notifications/UserNotification.vue";
import { getNotificationDate } from "@/modules/notifications/decorators/notifications.js";

export default {
  name: "UserNotifications",
  components: {
    UserNotification,
  },
  props: ["type", "model"],
  data() {
    return {
      notifications: [],
      days: [],
      notificationsByDays: [],
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      loading: false,
      updating: false,
      unreadCount: 0,
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created() {
    this.checkUrl();
  },
  beforeDestroy() {},
  methods: {
    checkUrl: function () {
      this.fetchData();
    },
    fetchData: function () {
      let itemsUrl = "/notifications/notification/my";
      let params = {
        page: this.page,
      };
      this.loading = true;
      this.$http
        .get(itemsUrl, {
          params: params,
        })
        .then((response) => {
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          this.notifications = response.data;
          this.processNotifications();
          this.loading = false;
          this.$eventHub.$emit("update-unread-count");
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    linkGen(pageNum) {
      return {
        name: "notifications-notification-user-list",
        params: { page: pageNum },
      };
    },
    processNotifications() {
      let all = {};
      let days = [];
      for (let notification of this.notifications) {
        let day = getNotificationDate(notification);
        if (!days.includes(day)) {
          days.push(day);
          all[day] = {
            notifications: [],
          };
        }
        all[day].notifications.push(notification);
      }
      this.days = days;
      this.notificationsByDays = all;
    },
  },
};
</script>

<style scoped>
.log-list {
  max-height: 300px;
  overflow-y: auto;
}

.col-time {
  min-width: 100px;
}

.col-description {
}

.col-status {
  min-width: 150px;
}
</style>

import StatusList from "@/modules/crm/views/status/StatusList";
import StatusView from "@/modules/crm/views/status/StatusView";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/crm/statuses/create",
    name: "crm-status-create",
    components: {
      default: StatusView,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/crm/statuses/:id/update",
    name: "crm-status-update",
    components: {
      default: StatusView,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/crm/statuses/page:page?",
    name: "crm-status-list",
    components: {
      default: StatusList,
    },
    meta: { requiresAuth: true },
  },
];

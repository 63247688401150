<template>
  <button v-if="opening" class="btn btn-primary btn-sm" @click.prevent="">
    <font-awesome-icon spin icon="rotate" />
  </button>
  <button
    v-else-if="request.assigneeCanEditRequest"
    v-b-tooltip.hover.right
    class="btn btn-xs btn-info ml-auto"
    :title="$t('requests.results_unblocked')"
    @click.prevent="toggleToAssignee(!request.assigneeCanEditRequest)"
  >
    <font-awesome-icon icon="lock-open" fixed-width />
  </button>
  <button
    v-else
    v-b-tooltip.hover.right
    class="btn btn-xs btn-danger ml-auto"
    :title="$t('requests.results_blocked')"
    @click.prevent="toggleToAssignee(!request.assigneeCanEditRequest)"
  >
    <font-awesome-icon icon="lock" fixed-width />
  </button>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import * as Sentry from "@sentry/vue";

export default {
  name: "ToggleResultsEditForTechnician",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["request"],
  data() {
    return {
      opening: false,
    };
  },
  computed: {},
  watch: {
    request: {
      immediate: true,
      handler(request) {},
    },
  },
  created: function () {},
  methods: {
    toggleToAssignee(assigneeCanEditRequest) {
      if (this.opening) {
        return;
      }

      let callUrl =
        "/pest/request/open-request-edit-for-technician/" + this.request.id;
      this.opening = true;
      this.$http
        .patch(callUrl, {
          assigneeCanEditRequest: assigneeCanEditRequest,
        })
        .then((response) => {
          this.request.assigneeCanEditRequest = assigneeCanEditRequest;
          if (assigneeCanEditRequest) {
            this.$bvToast.toast(
              this.$t("requests.request_can_be_edited_by_assignee"),
              {
                title: this.$t("requests.access_granted"),
                variant: "success",
              }
            );
          } else {
            this.$bvToast.toast(
              this.$t("requests.request_edit_prohibited_to_assignee"),
              {
                title: this.$t("requests.access_closed"),
                variant: "success",
              }
            );
          }

          this.opening = false;
        })
        .catch((error) => {
          this.$bvToast.toast(
            this.$t("reqeuests.cant_open_edits_to_assignee"),
            {
              title: this.$t("common.error"),
              variant: "danger",
            }
          );
          this.opening = false;
          Sentry.captureException(error, {
            code: error.code,
            message: error.message,
            request: this.request,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  z-index: 999999;
}
</style>

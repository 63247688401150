<template>
  <b-form
    id="pest-resource-form"
    ref="resource"
    :model="resource"
    class="pest-resource-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input
              v-model="resource.title"
              :placeholder="$t('common.title')"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.unit')" prop="unit">
            <b-form-input
              v-model="resource.unit"
              placeholder=""
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div v-if="!modal" class="row">
        <div class="col-12">
          <b-form-group :label="$t('resources.linked_resources')" prop="title">
            <div v-if="resource && resource.shopResources" class="mb-2">
              <div
                v-for="(assignedResource, apI) in resource.shopResources"
                :key="apI"
                class="mb-2"
              >
                <div class="btn btn-primary btn-xs mr-2">
                  {{ assignedResource.title }}
                </div>

                <router-link
                  class="btn btn-outline-primary btn-xs mr-2"
                  target="_blank"
                  :to="{
                    name: 'pest-shop-resource-update',
                    params: { id: assignedResource.id },
                  }"
                >
                  <font-awesome-icon icon="eye" />
                </router-link>
                <button
                  class="btn btn-outline-danger btn-xs"
                  :disabled="deleting"
                  @click="unassignResource(assignedResource, apI)"
                >
                  <font-awesome-icon
                    v-if="deleting === apI"
                    icon="rotate"
                    spin
                    fixed-width
                  />
                  <font-awesome-icon v-else icon="trash" fixed-width />
                </button>
              </div>
            </div>
            <div v-else>{{ $t("resources.no_linked_resource_yet") }}}</div>
          </b-form-group>
          <b-form-group
            :label="$t('resources.add_linked_resource')"
            prop="title"
          >
            <multiply-autocomplete
              :get-data="fetchResourceData"
              :get-title="(p) => p.title"
              @selected="selectMultipleResource"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.description')" prop="title">
            <b-form-textarea
              v-model="resource.comment"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div v-if="!fromResults" class="row">
        <div class="col-12">
          <b-form-group :label="$t('resources.visibility')" prop="title">
            <b-form-checkbox
              v-model="resource.enabled"
              tabindex="1"
              value="1"
            ></b-form-checkbox>
          </b-form-group>
        </div>
      </div>

      <div v-if="!fromResults" class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="resource.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteResource"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete.vue";

export default {
  name: "PestResourceForm",
  directives: { mask: VueMaskDirective },
  components: { MultiplyAutocomplete },
  props: ["resource", "fromResults", "modal"],
  data() {
    return {
      phoneMask: false,
      deleting: false,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.resource.id;
    },
    name() {
      let name = this.resource.name
        ? this.resource.name
        : this.$t("resources.new_resource");
      return name ? name : "resource";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/resources/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.resource)
        .then((response) => {
          this.resource = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-resource-info", this.resource);
          // this.$router.push({name: 'pest-resource-view', params: {id: this.resource.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("resources.resource_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/resources";
      this.loading = true;
      this.$http
        .post(updateUrl, this.resource)
        .then((response) => {
          this.resource = response.data;
          this.$eventHub.$emit("update-pest-resource-info", this.resource);
          this.$router.push({
            name: "pest-resource-update",
            params: { id: this.resource.id },
          });
          this.$bvToast.toast(this.$t("resources.resource_created"), {
            title: this.$t("resources.resource_created"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("resources.cant_create_resource"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-resource-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },

    selectMultipleResource(list) {
      let shopResourceIds = [];
      if (list) {
        for (let resource of list) {
          shopResourceIds.push(resource.id);
        }
      }
      this.resource.shopResourceIds = shopResourceIds;
    },

    fetchResourceData: function (search, resolve) {
      this.resourceList = [];
      let resourceUrl = "/pest/shop-resources";
      let params = {
        search: search,
        service: false,
        all: true,
      };
      this.$http
        .get(resourceUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },

    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteResource() {
      if (confirm(this.$t("resources.delete_resource_question"))) {
        let deleteUrl = "/pest/resources/" + this.resource.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-resource-list" });
            this.$bvToast.toast(this.$t("resources.resource_deleted"), {
              title: this.$t("resources.resource_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("resources.cant_delete_resource"),
                variant: "danger",
              });
            }
          });
      }
    },
    unassignResource(shopResource, index) {
      if (this.deleting) {
        return;
      }
      this.deleting = index;
      if (confirm(this.$t("resources.delete_resource_question"))) {
        let deleteUrl = "/pest/resource/unassign?id=" + this.resource.id;
        this.$http
          .post(deleteUrl, { shopId: shopResource.id })
          .then((response) => {
            this.deleting = false;
            this.resource.shopResources.splice(index, 1);
            this.$bvToast.toast(this.$t("resources.resource_deleted"), {
              title: this.$t("resources.resource_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.deleting = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("resources.cant_delete_resource"),
                variant: "danger",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>

import t from "@/i18n";

export const getFacilityTitle = function (facility) {
  if (!facility || !facility.title) {
    return t("common.not_set");
  }
  return facility.title;
};

export const CAT_ORG = "2";
export const CAT_IND = "3";

export const facilityTypeTitles = {
  [CAT_ORG]: "type_individual",
  [CAT_IND]: "type_organization",
};
export const facilityTypeOptions = function () {
  return [
    { value: CAT_ORG, text: t("requests.type_individual") },
    { value: CAT_IND, text: t("requests.type_organization") },
  ];
};
export const getFacilityTypeTitle = function (facility) {
  if (!facility.type) {
    return t("common.not_set");
  }
  return t("requests." + facilityTypeTitles[facility.type]);
};

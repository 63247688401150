export const ru = {
  settings: {
    employees: "Сотрудники",
    requests: "Заявки",
    requests_msg: "Перечень заявок в виде таблицы",
    requests_indices: "Справочники по заявкам",
    yandex_direct: "Яндекс Директ",
    settings_updated: "Настройки сохранены",
    settings_are_not_updated: "Не удалось сохранить настройки",
    request_settings: "Настройки заявок",
    request_settings_desc: "Рекорд и прочие",
    maintenance: "Сервисное меню",
    logs_history: "История событий",
    logs_history_desc: "Все события в системе",
    price_suggestions: "Ценовые подсказки",
    price_suggestions_desc: "Предложения цены для нового лида",
    interval_appartment_opening:
      "Время в минутах до и после заявки на открытие номер квартиры",
    request_duration: "Длительность заявки",
    cur_msk_record: "Текущий рекорд в Москве и области",
    cur_spb_record: "Текущий рекорд в Санкт-Петербурге и области",
    min_record_sum: "Минимальная сумма рекорда",
    max_record_sum: "Максимальная сумма рекорда",
    record_interval: "Интервал увеличения рекорда",
    payment_percent: "Процент оплаты",
    payment_percent_record: "Процент оплаты при рекорде",
    equipment_price: "Стоимость аренды оборудования",
    work_shift_count: "Количество рабочих смен",
    team_head_percent: "Начисляемый процент командиру команды",
    min_pledge: "Минимальный депозит для всех",
    pledge_limit: "Лимит депозита, для блокировки мастера",
    pledge_fine: "Штраф за несдачу депозита",
    shop_distance: "Допустимая дистанция до магазина в метрах",
    since: "От",
    to: "До",
    sum: "Сумма",
    cpo: "CPO",
    manager_salary: "Зарплата менеджера",
    office_cost: "Издержки офиса",
    expenses: "Затраты",

    common: "Общее",
    novice_payments: "Оплата стажерам",
    payments_and_record: "Оплата и рекорд",
    pledge: "Депозит",
    stats: "Статистика",
    referral: "Реферальная программа",
    shops: "Магазины",
    pledge_sum_and_earnings: "Сумма депозита при заработке за смену",
  },
};
export const en = {
  settings: {
    employees: "Employees",
    requests: "Requests",
    requests_msg: "Requests table",
    requests_indices: "Requests indices",
    yandex_direct: "Yandex direct",
    settings_updated: "Settings updated",
    settings_are_not_updated: "Settings are not updated",
    request_settings: "Request settings",
    request_settings_desc: "Record, limits, etc",
    maintenance: "Maintenance",
    maintenance_desc: "Service requests",
    logs_history: "Logs history",
    logs_history_desc: "All system history",
    price_suggestions: "Price suggestions",
    price_suggestions_desc: "Price suggestions for new request",
    interval_appartment_opening:
      "Time in minutes before and after for appartment opening",
    request_duration: "Request duration",
    cur_msk_record: "Current Moskow and region record",
    cur_spb_record: "Current St.Peterburg and region record",
    min_record_sum: "Min record sum",
    max_record_sum: "Max record sum",
    record_interval: "Record increase step",
    payment_percent: "Payment percent",
    payment_percent_record: "Payment percent on record",
    equipment_price: "Equipment rent price",
    work_shift_count: "Work shift count",
    team_head_percent: "Team head earning percent",
    min_pledge: "Min pledge for all",
    pledge_limit: "Pledge limit to masters blocking",
    pledge_fine: "Fine for missing pledge",
    shop_distance: "Distance to shop in meters",
    since: "Since",
    to: "To",
    sum: "Sum",
    cpo: "CPO",
    manager_salary: "Manager salary",
    office_cost: "Office cost",
    expenses: "Expenses",

    common: "Common",
    payments_and_record: "Payments and record",
    novice_payments: "Payments for novices",
    pledge: "Pledge",
    stats: "Stats",
    shops: "Shops",
    referral: "Referral",
    pledge_sum_and_earnings: "Pledge sum and earnings",
  },
};

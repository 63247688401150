<template>
  <div class="ibox">
    <div class="ibox-title d-flex pr-2">
      <h4>Тестовая заявка в Roistat</h4>

      <div class="ml-auto">
        <b-button v-if="!saving" variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          Отправить
        </b-button>
        <b-button v-else variant="primary" disabled @click="createOrUpdate">
          <font-awesome-icon icon="sync" spin="" />
          Отправляется
        </b-button>
      </div>
    </div>
    <div class="ibox-content">
      <b-form
        id="test-roistat-form"
        ref="request"
        :model="request"
        class="test-roistat-form"
        novalidate
        @submit.prevent="createOrUpdate('roistat')"
        @reset="onReset"
      >
        <div class="div">
          <b-form-group label="Проект" prop="projectNumber">
            <b-form-select
              v-model="request.projectNumber"
              :options="projectSelectOptions"
              placeholder="Проект"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Источник" prop="title">
            <source-autocomplete
              default-value="Без источника"
              @selected="selectSource"
            ></source-autocomplete>
          </b-form-group>
          <b-form-group label="Номер" prop="title">
            <template #label>
              Телефон
              <a href="" @click.prevent="generatePhone">Обновить</a>
            </template>
            <b-form-input
              v-model="request.phone"
              tabindex="1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Номер Roistat" prop="title">
            <template #label>
              Номер Roistat
              <a href="" @click.prevent="generateRoistat">Обновить</a>
            </template>
            <b-form-input
              v-model="request.roistat"
              tabindex="1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Имя" prop="title">
            <b-form-input v-model="request.name" tabindex="2"></b-form-input>
          </b-form-group>
          <b-form-group label="Email" prop="title">
            <b-form-input v-model="request.email" tabindex="3"></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input v-model="request.title" tabindex="4"></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('requests.comment')" prop="comment">
            <b-form-input v-model="request.comment" tabindex="5"></b-form-input>
          </b-form-group>
          <div class="row">
            <div class="col-6">
              <b-form-group prop="title">
                <template #label>
                  clientIdYandex
                  <a href="" @click.prevent="generateTag('clientIdYandex')"
                    >Обновить</a
                  >
                </template>
                <b-form-input
                  v-model="request.clientIdYandex"
                  tabindex="1"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group prop="title">
                <template #label>
                  counterYandex
                  <a href="" @click.prevent="generateTag('counterYandex')"
                    >Обновить</a
                  >
                </template>
                <b-form-input
                  v-model="request.counterYandex"
                  tabindex="1"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group prop="title">
                <template #label>
                  clientIdGoogle
                  <a href="" @click.prevent="generateTag('clientIdGoogle')"
                    >Обновить</a
                  >
                </template>
                <b-form-input
                  v-model="request.clientIdGoogle"
                  tabindex="1"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group prop="title">
                <template #label>
                  counterGoogle
                  <a href="" @click.prevent="generateTag('counterGoogle')"
                    >Обновить</a
                  >
                </template>
                <b-form-input
                  v-model="request.counterGoogle"
                  tabindex="1"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form>
    </div>

    <div class="ibox-footer d-flex">
      <div class=" ">
        <b-button
          v-if="!saving"
          variant="primary"
          @click="createOrUpdate('roistat')"
        >
          <font-awesome-icon icon="paper-plane" />
          Отправить в roistat
        </b-button>
      </div>

      <div class="ml-auto">
        <b-button
          v-if="!saving"
          variant="primary"
          @click="createOrUpdate('crm')"
        >
          <font-awesome-icon icon="download" />
          Сохранить в crm
        </b-button>
      </div>

      <b-button v-if="saving" variant="primary" disabled @click.prevent>
        <font-awesome-icon icon="sync" spin="" />
        Отправляется
      </b-button>
    </div>
  </div>
</template>

<script>
import SourceAutocomplete from "../../../../pest/views/sources/SourceAutocomplete.vue";

export default {
  name: "TestRoistatRequest",
  components: { SourceAutocomplete },
  data() {
    return {
      request: {
        projectNumber: "",
        source: "",
        phone: "",
        name: "",
        email: "",
        title: "",
        comment: "",
        roistat: "",
      },
      projectSelectOptions: [],
      saving: false,
    };
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.refreshForm();
    this.fetchProjectSelectOptions();
  },
  methods: {
    refreshForm() {
      this.request = {
        projectNumber: "",
        source: "",
        phone: "",
        name: "",
        email: "",
        title: "",
        comment: "",
        clientIdYandex: "",
        counterYandex: "",
        clientIdGoogle: "",
        counterGoogle: "",
      };
      this.$forceUpdate();
      this.generateRoistat();
      this.generatePhone();
      this.generateTag("clientIdYandex");
      this.generateTag("counterYandex");
      this.generateTag("clientIdGoogle");
      this.generateTag("counterGoogle");
    },
    fetchProjectSelectOptions: function () {
      this.projectSelectOptions = [];
      let projectUrl = "/pest/projects?enabled=1";
      this.$http
        .get(projectUrl)
        .then((response) => {
          for (let item of response.data) {
            this.projectSelectOptions.push({
              value: item.projectNumber,
              text: item.title,
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    create: function (target) {
      let updateUrl;
      updateUrl = "/roistat/test/create";
      if (this.saving) {
        this.$bvToast.toast(this.$t("common.data_is_saving"), {
          title: this.$t("common.please_wait"),
          variant: "info",
        });
        return;
      }
      this.request.target = target;
      this.saving = true;
      this.$http
        .post(updateUrl, this.request)
        .then((response) => {
          this.saving = false;
          this.$bvToast.toast(this.$t("common.loading"), {
            title: this.$t("common.success"),
            variant: "success",
          });

          // this.refreshForm();
        })
        .catch((error) => {
          this.saving = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast("Не удалось отправить запрос", {
              title: this.$t("common.error"),
              variant: "danger",
            });
          }
        });
    },
    generateTag(tag) {
      this.request[tag] = this.getRandomNumber(100000, 999999);
    },
    generateRoistat() {
      this.request.roistat = "rnd" + this.getRandomNumber(100000, 999999);
    },
    generatePhone() {
      this.request.phone = "+7" + this.getRandomNumber(1000000000, 9999999999);
    },
    getRandomNumber(min, max) {
      return Math.round(Math.random() * (max - min) + min);
    },
    selectSource(info) {
      if (info.value) {
        this.request.source = info.value.title;
      } else {
        this.request.source = "";
      }
    },
    createOrUpdate: function (target) {
      let form = document.getElementById("test-roistat-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        this.create(target);
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>

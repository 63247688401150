import CampaignList from "@/modules/direct/views/campaigns/CampaignList";
import CampaignCreate from "@/modules/direct/views/campaigns/CampaignCreate";
import CampaignUpdate from "@/modules/direct/views/campaigns/CampaignUpdate";
import CampaignView from "@/modules/direct/views/campaigns/CampaignView";

export default [
  {
    path: "/campaigns/page:page?",
    name: "direct-campaign-list",
    components: {
      default: CampaignList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/campaigns/new",
    name: "direct-campaign-create",
    components: {
      default: CampaignCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/:id/update",
    name: "direct-campaign-update",
    components: {
      default: CampaignUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/:id",
    name: "direct-campaign-view",
    components: {
      default: CampaignView,
    },
    meta: { requiresAuth: true },
  },
];

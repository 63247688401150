<template>
  <div class="pest-resource-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>
              <router-link
                :to="{ name: 'pest-resource-list', id: resource.id }"
                class="h4 text-muted"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>
              {{ $t("resources.resource_update") }}
            </h1>
          </div>
          <div class="ibox-content">
            <pest-resource-form :resource="resource"></pest-resource-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceForm from "./ResourceForm";

export default {
  name: "Resource",
  components: {
    "pest-resource-form": ResourceForm,
  },
  data() {
    return {
      resource: {
        id: "new",
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl() {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      let resourcesUrl = "/pest/resources/" + this.id;
      this.loading = true;
      this.$http
        .get(resourcesUrl)
        .then((response) => {
          this.loading = false;
          this.resource = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-modal
    id="modal-assignee-comment"
    ref="modal"
    :title="modalTitle"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @ok="handleTaskModalOk"
  >
    <b-form-group :label="$t('requests.comment')" prop="title">
      <b-form-textarea v-model="comment" tabindex="1"></b-form-textarea>
    </b-form-group>
  </b-modal>
</template>

<script>
import { getAssigneeName } from "@/modules/crm/common";
import dayjs from "dayjs";

export default {
  name: "AssigneeCommentPopup",
  components: {},
  data() {
    return {
      comment: "",
      assignee: {},
    };
  },
  computed: {
    modalTitle() {
      return (
        this.getAssigneeName(this.assignee) +
        " – " +
        dayjs(this.date).format("DD.MM.YYYY")
      );
    },
    formattedDate(date) {
      return dayjs(date).format("DD.MM.YYYY");
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("assignee-comment-popup", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('assignee-comment-popup');
  },
  methods: {
    getAssigneeName,
    handleSuccess(info) {
      this.$bvModal.hide("modal-assignee-comment");
      if (this.successHandler) {
        this.successHandler(info);
      }
      this.successHandler = false;
      this.comment = "";
      this.assignee = {};
    },
    handleTaskModalOk() {
      this.handleSuccess({
        comment: this.comment,
      });
    },
    openPopup(info) {
      this.assignee = Object.assign({}, info.assignee);
      this.comment = this.assignee.scheduleComment;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-assignee-comment");
    },
  },
};
</script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}
</style>

import RequestList from "@/modules/pest/views/requests/RequestList";
import RequestDispatchingBoard from "@/modules/pest/views/requests/dispatching/RequestDispatchingBoard.vue";
import RequestCreate from "@/modules/pest/views/requests/RequestCreate";
import RequestUpdate from "@/modules/pest/views/requests/RequestUpdate";
import RequestView from "@/modules/pest/views/requests/RequestView";
import RequestMap from "@/modules/pest/views/requests/map/RequestMap.vue";
import EmployeeCurrentRequestList from "@/modules/pest/views/requests/_employee/EmployeeCurrentRequestList.vue";
import EmployeeArchiveRequestList from "@/modules/pest/views/requests/_employee/EmployeeArchiveRequestList.vue";
import RequestBoard from "@/modules/pest/views/requests/kanban/RequestBoard.vue";
import RequestBoardGlobal from "@/modules/pest/views/requests/kanban/RequestBoardGlobal.vue";

export default [
  {
    path: "/requests/list:page?",
    name: "pest-request-list",
    components: {
      default: RequestList,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "menu.requests_in_work",
    },
  },
  {
    path: "/requests/board/:project",
    name: "pest-request-board",
    components: {
      default: RequestBoard,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/requests/board/",
    name: "pest-request-board-global",
    components: {
      default: RequestBoardGlobal,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/requests/in-work/:page?",
    name: "pest-employee-current-request-list",
    components: {
      default: EmployeeCurrentRequestList,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "menu.requests_in_work",
    },
  },

  {
    path: "/requests/my-archive/:page?",
    name: "pest-employee-archive-request-list",
    components: {
      default: EmployeeArchiveRequestList,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "menu.my_closed_request",
    },
  },

  {
    path: "/requests/dispatching",
    name: "request-dispatching-board",
    components: {
      default: RequestDispatchingBoard,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/requests/new",
    name: "pest-request-create",
    components: {
      default: RequestCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/requests/update/:id/",
    name: "pest-request-update",
    components: {
      default: RequestUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/requests/view/:id",
    name: "pest-request-view",
    components: {
      default: RequestView,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/requests/map",
    name: "pest-request-map",
    components: {
      default: RequestMap,
    },
    meta: { requiresAuth: true },
  },
];

<template>
  <b-modal
    id="modal-board-columns-popup"
    ref="modal"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @ok="handleDealModalOk"
  >
    <template #modal-title>{{ $t("search.col_filter_msg") }}</template>
    <div v-for="(col, cIndex) in columns" :key="cIndex">
      <b-form-checkbox v-model="checkedColumns" :value="col.id">
        {{ col.title }}
      </b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "BoardColumnsPopup",
  components: {},
  props: {
    columns: { type: Array, default: () => [] },
    selectedColumns: { type: Array, default: () => undefined },
    storeKey: { type: String, default: "" },
  },
  data() {
    return {
      checkedColumns: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$eventHub.$on("modal-board-columns-popup", this.openPopup);
  },
  beforeDestroy() {
    this.$eventHub.$off("modal-board-columns-popup");
  },
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    handleDealModalOk() {
      this.$emit("changed", this.checkedColumns);
    },
    openPopup() {
      this.checkedColumns =
        this.selectedColumns !== null
          ? this.selectedColumns
          : this.columns.map((col) => col.id);

      this.$bvModal.show("modal-board-columns-popup");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title a {
  color: inherit;
}

.modal-title a .link-icon {
  display: none;
}

.modal-title a:hover {
  color: #007bff;
}

.modal-title a:hover .link-icon {
  display: inline;
}
</style>

import Vue from "vue";
import Router from "vue-router";
import UserRoutes from "./modules/users/router.js";
import DataRoutes from "./modules/data/router.js";
import CrmRoutes from "./modules/crm/router.js";
import PestRoutes from "./modules/pest/router.js";
import SmsRoutes from "./modules/sms/router.js";
import RoistatRoutes from "./modules/roistat/router.js";
import DirectRoutes from "./modules/direct/router.js";
import NotificationRoutes from "./modules/notifications/router.js";

import store from "@/store";
import Login from "@/modules/users/views/login/Login";
import { isGuest } from "@/modules/users/access/common.js";
import t from "@/i18n";

Vue.use(Router);

let MainRoutes = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
];

let Routes = MainRoutes.concat(
  UserRoutes,
  CrmRoutes,
  PestRoutes,
  DataRoutes,
  NotificationRoutes,
  RoistatRoutes,
  SmsRoutes,
  DirectRoutes
);

let router = new Router({
  routes: Routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isGuest(store.state.user)) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (to.meta.checkRule && !to.meta.checkRule(store.state.user)) {
      Vue.$toast.open({
        message: "You have no access",
        type: "danger",
      });
      next({
        path: "/",
        name: "home",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

router.afterEach((to, from) => {
  store.dispatch({
    type: "user/setPageTitle",
    pageTitle: to.meta.pageTitle ? t(to.meta.pageTitle) : "",
  });
});

export default router;

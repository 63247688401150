import ReportList from "@/modules/direct/views/reports/ReportList";

export default [
  {
    path: "/reports/page:page?",
    name: "direct-report-list",
    components: {
      default: ReportList,
    },
    meta: { requiresAuth: true },
  },
];

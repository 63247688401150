import { getTownTitle } from "@/modules/pest/decorators/towns";
import dayjs from "dayjs";
import t from "@/i18n";

const getPaymentTitle = function (payment) {
  if (!payment || !payment.title) {
    return t("common.not_set");
  }
  return payment.title;
};
const getPaymentTownTitle = function (payment) {
  if (!payment || !payment.town) {
    return t("common.not_set");
  }
  return getTownTitle(payment.town);
};

const getPaymentTime = function (payment) {
  return dayjs.unix(payment.time).format("DD.MM.YYYY HH:mm:ss");
};

export { getPaymentTitle, getPaymentTownTitle, getPaymentTime };

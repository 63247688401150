export const ru = {
  places: {
    places: "Места",
    place_list: "Перечень мест",
    place: "Место",
    add_place: "Добавить место",
    new_place: "Новок место",
    place_update: "Изменение места",
    place_updated: "Место отредактировано",
    cant_create_place: "Не удалось добавить место",
    cant_delete_place: "Не удалось удалить место",
    place_created: "Место добавлено",
    place_deleted: "Место удалено",
    delete_place_question: "Вы действительно хотите удалить место?",
    address: "Адрес",
    coords: "Координаты",
    search_by_address: "Поиск по адресу",
  },
};
export const en = {
  places: {
    places: "Places",
    place_list: "Place list",
    place: "Place",
    add_place: "Add place",
    new_place: "New place",
    change_place: "Change place",
    place_update: "Update place",
    place_updated: "Place changed",
    cant_create_place: "Can't add place",
    cant_delete_place: "Can't delete place",
    place_created: "Place added",
    place_deleted: "Place deleted",
    delete_place_question: "Do you want to delete the place?",
    address: "Address",
    coords: "Coordinates",
    search_by_address: "Search by address",
  },
};

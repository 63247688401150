<template>
  <b-form
    id="pest-source-form"
    ref="source"
    :model="source"
    class="pest-source-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input
              v-model="source.title"
              :placeholder="$t('common.title')"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('sources.type')" prop="title">
            <b-form-select
              v-model="source.type"
              :options="typeOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
          <b-form-group :label="$t('sources.project')" prop="projectId">
            <b-form-select
              v-model="source.projectId"
              :options="projectSelectOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            v-if="source.type === TYPE_MANUAL"
            :label="$t('sources.target')"
            prop="title"
          >
            <b-form-select
              v-model="source.target"
              :options="targetOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.description')" prop="title">
            <b-form-textarea
              v-model="source.comment"
              :placeholder="$t('common.description')"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group
            :label="$t('sources.search_only_by_phone')"
            prop="title"
          >
            <b-form-checkbox v-model="source.searchOnlyByPhone" value="1">
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="source.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteSource"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import {
  sourceTargetOptions,
  sourceTypeOptions,
  TYPE_MANUAL,
} from "@/modules/pest/decorators/sources";

export default {
  name: "PestSourceForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["source"],
  data() {
    return {
      phoneMask: false,
      loading: false,
      projectSelectOptions: [],
      TYPE_MANUAL,
    };
  },
  computed: {
    typeOptions() {
      return sourceTypeOptions();
    },
    targetOptions() {
      return sourceTargetOptions();
    },
    id() {
      return this.source.id;
    },
    name() {
      let name = this.source.name
        ? this.source.name
        : this.$t("sources.new_source");
      return name ? name : "source";
    },
    managerName() {
      return this.source.managerId && this.source.manager
        ? getUserName(this.client.manager)
        : "";
    },
  },
  watch: {},
  created: function () {
    this.fetchProjectSelectOptions();
  },
  methods: {
    getUserName,
    fetchProjectSelectOptions: function () {
      this.projectSelectOptions = [];
      let projectUrl = "/pest/projects?enabled=1";
      this.$http
        .get(projectUrl)
        .then((response) => {
          for (let item of response.data) {
            this.projectSelectOptions.push({
              value: item.id,
              text: item.title,
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/sources/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.source)
        .then((response) => {
          this.source = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-source-info", this.source);
          // this.$router.push({name: 'pest-source-view', params: {id: this.source.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("sources.source_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/sources";
      this.loading = true;
      this.$http
        .post(updateUrl, this.source)
        .then((response) => {
          this.source = response.data;
          this.$eventHub.$emit("update-pest-source-info", this.source);
          this.$router.push({
            name: "pest-source-update",
            params: { id: this.source.id },
          });
          this.$bvToast.toast(this.$t("sources.source_added"), {
            title: this.$t("sources.source_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("sources.cant_create_source"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-source-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteSource() {
      if (confirm(this.$t("sources.delete_source_question"))) {
        let deleteUrl = "/pest/sources/" + this.source.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-source-list" });
            this.$bvToast.toast(this.$t("sources.source_deleted"), {
              title: this.$t("sources.source_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("sources.cant_delete_source"),
                variant: "danger",
              });
            }
          });
      }
    },

    getManagerName(user) {
      return getUserName(user);
    },
    handleManagerSubmit(result) {
      this.source.managerId = result.id;
    },
    getResultManagerAutocompleteValue(user) {
      return this.getManagerName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>

<template>
  <div class="pest-payment-page">
    <pest-payment-form :payment="payment"></pest-payment-form>
  </div>
</template>

<script>
import PaymentForm from "./PaymentForm";

export default {
  name: "Payment",
  components: {
    "pest-payment-form": PaymentForm,
  },
  data() {
    return {
      payment: {
        id: "new",
        title: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div :data-call-id="log.callId">
    {{ callTime }}
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "RequestNotificationCall",
  components: {},
  props: ["log"],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  computed: {
    callTime() {
      return dayjs.unix(this.log.time).format("DD.MM.YYYY в HH:mm");
    },
  },
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <tr class=" " :class="notificationClass(notification)">
    <td class="">
      {{ getNotificationCreateDatetime(notification) }}
    </td>
    <td>
      {{ getNotificationTypeTitle(notification) }}
    </td>
    <td>
      {{ getNotificationUser(notification) }}
    </td>
    <td class="">
      <notification-full-changed-assignee
        v-if="notification.type === REQUEST_CHANGED_ASSIGNEE"
        :notification="notification"
      />

      <notification-full-fine-approved
        v-else-if="notification.type === FINE_APPROVED"
        :notification="notification"
      />
      <notification-full-new-record
        v-else-if="notification.type === REQUEST_NEW_RECORD"
        :notification="notification"
      />
      <notification-full-message
        v-else-if="notification.type === MESSAGE"
        :notification="notification"
      />
      <div v-else>{{ $t("notifications.unknown_msg") }}</div>
    </td>
    <td>
      <div v-if="notification.readTime">
        {{ $t("notifications.read_at") }}
        {{ getNotificationConfirmDatetime(notification) }}
      </div>
      <div v-else>{{ $t("notifications.not_read") }}</div>
    </td>
  </tr>
</template>

<script>
import {
  FINE_APPROVED,
  getNotificationConfirmDatetime,
  getNotificationCreateDatetime,
  getNotificationTypeTitle,
  getNotificationUser,
  MESSAGE,
  REQUEST_CHANGED_ASSIGNEE,
  REQUEST_NEW_RECORD,
} from "@/modules/notifications/decorators/notifications";
import NotificationFullChangedAssignee from "@/modules/notifications/views/details-admin/NotificationFullChangedAssignee.vue";
import NotificationFullFineApproved from "@/modules/notifications/views/details-admin/NotificationFullFineApproved.vue";
import NotificationFullNewRecord from "@/modules/notifications/views/details-admin/NotificationFullNewRecord.vue";
import NotificationFullMessage from "@/modules/notifications/views/details-admin/NotificationFullMessage.vue";

export default {
  name: "FullNotification",
  components: {
    NotificationFullMessage,
    NotificationFullNewRecord,
    NotificationFullFineApproved,
    NotificationFullChangedAssignee,
  },
  props: {
    notification: {},
    toAdmin: {},
  },
  data() {
    return {
      REQUEST_CHANGED_ASSIGNEE,
      FINE_APPROVED,
      MESSAGE,
      REQUEST_NEW_RECORD,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {
    getNotificationCreateDatetime,
    getNotificationConfirmDatetime,
    getNotificationUser,
    getNotificationTypeTitle,
    notificationClass(notification) {
      if (!notification.readTime) {
        return "table-info";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-confirm {
  width: 150px;
}
</style>

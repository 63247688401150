<template>
  <div v-if="!loadingRequests && sequence.length > 1" class="mb-3">
    <button
      v-for="(request, srIndex) in sequence"
      :key="'sr' + srIndex"
      class="btn mr-2"
      :class="getRequestTabClass(request.id)"
      @click.prevent="showRequestPopup(request)"
    >
      <span class="label mr-2" :class="getLabelTabClass(request.id)">
        {{ request.indexNumber }}</span
      >
      {{ getRequestNumber(request) }}
    </button>
    <button v-if="addingRequest" class="btn btn-primary mr-2" @click.prevent>
      {{ $t("requests.new_request") }}
    </button>
  </div>
</template>

<script>
import { getRequestNumber } from "@/modules/pest/decorators/requests.js";

export default {
  name: "RequestsSequenceList",
  components: {},
  props: ["requestId", "addingRequest"],
  data() {
    return {
      sequence: [],
      curRequest: null,
      loadingRequests: false,
    };
  },
  computed: {},
  watch: {
    requestId: {
      immediate: true,
      updating: false,
      handler(requestId) {
        if (requestId !== "new") {
          this.fetchRequests();
        } else {
          this.sequence = [];
        }
      },
    },
  },
  created: function () {
    this.$eventHub.$on("sequence-refresh", this.fetchRequests);
  },
  mounted() {},
  methods: {
    getRequestNumber,
    fetchRequests() {
      this.loadingRequests = true;
      let requestsUrl = "/pest/request/sequence";
      let params = {
        id: this.requestId,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingRequests = false;
          this.sequence = response.data.requests;
          this.sequence.sort((a, b) => {
            return a.indexNumber - b.indexNumber;
          });
          this.$emit("sequence-loaded", this.sequence);
        })
        .catch((error) => {
          this.loadingRequests = false;
          this.processRequestError(error);
        });
    },

    getRequestTabClass(id) {
      if (id === this.requestId) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    getLabelTabClass(id) {
      if (id === this.requestId) {
        return "label-default";
      } else {
        return "label-primary";
      }
    },
    showRequestPopup(request) {
      this.$emit("open-request", {
        requestId: request.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

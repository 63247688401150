<template>
  <div v-if="canViewData" class="client-page">
    <div class="row">
      <div class="col-12">
        <div class="ibox mb-2">
          <div class="ibox-title pb-1">
            <h1>
              <router-link
                :to="{ name: 'crm-client-list' }"
                class="ml-1 mr-1 h4"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>

              {{ clientName }}

              <small>
                <a
                  href=""
                  class="h6 ml-2"
                  @click.prevent="toggleUpdate(!updating)"
                >
                  <font-awesome-icon v-if="updating" icon="eye" />
                  <font-awesome-icon v-else icon="edit" />
                </a>
                <a href="" class="h6 ml-2" @click.prevent="deleteClient">
                  <font-awesome-icon icon="trash" />
                </a>
              </small>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!updating" class="row">
      <div class="col-md-4">
        <b-tabs v-model="infoTabIndex" card @activate-tab="onInfoTabSelect">
          <b-tab :title="clientTabTitle" active no-body>
            <client-info :client="client"></client-info>
          </b-tab>
          <b-tab
            v-if="client.type === TYPE_ORGANIZATION"
            title="Контакты"
            no-body
          >
            <organization-contacts
              ref="contacts"
              :client="client"
            ></organization-contacts>
          </b-tab>
        </b-tabs>
      </div>

      <div v-if="!loading" class="col-md-8">
        <b-tabs v-model="dataTabIndex" card @activate-tab="onDataTabSelect">
          <b-tab title="Дела" active no-body>
            <div class="ibox">
              <div class="ibox-content"></div>
            </div>
          </b-tab>
          <b-tab title="Задачи" active no-body>
            <div class="ibox">
              <div class="ibox-content">
                <client-tasks ref="tasks" :client="client"></client-tasks>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-12">
        <div class="ibox mb-1">
          <div class="ibox-content pb-1">
            <client-form
              :client-model="client"
              :data-loaded="!loading"
              :return-url="{
                name: 'crm-client-view',
                params: { id: client.id },
              }"
              @update-client-info="afterClientUpdate"
              @cancel-updating="toggleUpdate(false)"
            ></client-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientInfo from "./ClientInfo";
import OrganizationContacts from "@/modules/crm/views/client/OrganizationContacts";
import {
  getClientName,
  NUMERATION_COMMON,
  TYPE_EMPLOYEE,
  TYPE_ORGANIZATION,
  TYPE_PERSON,
} from "@/modules/crm/decorators/client";
import ClientTasks from "@/modules/crm/views/tasks/ClientTasks";
import ClientForm from "@/modules/crm/views/client/ClientForm";

export default {
  name: "Client",
  components: {
    "client-info": ClientInfo,
    "organization-contacts": OrganizationContacts,
    "client-tasks": ClientTasks,
    "client-form": ClientForm,
  },
  data() {
    return {
      TYPE_PERSON,
      TYPE_ORGANIZATION,
      updating: false,
      infoTabIndex: 0,
      dataTabIndex: 0,
      genders: [
        { text: "M", value: "male" },
        { text: "Ж", value: "female" },
      ],
      client: {
        id: "new",
        type: TYPE_EMPLOYEE,
        managerId: "",
        managerName: "",
        firstName: "",
        lastName: "",
        patronymic: "",
        gender: "",
        phone: "",
        email: "",
        phoneSearch: "",
        birthday: "",
        clientBirthPlace: "",
        country: "",
        town: "",
        addressPassport: "",
        addressLiving: "",
        // 'addressPassport': '',
        // 'addressLiving': '',
        address: "",
        postAddress: "",
        legalAddress: "",
        phones: [""],
        emails: [""],
        passportSerial: "",
        passportNumber: "",
        passportAddress: "",
        passportIssuedDate: "",
        passportIssuedByTitle: "",
        passportIssuedByCode: "",

        numerationStart: 1,
        numeration: NUMERATION_COMMON,
      },

      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
    clientTabTitle() {
      if (this.client.type === TYPE_ORGANIZATION) {
        return "Организация";
      }
      if (this.client.type === TYPE_EMPLOYEE) {
        return "Сотрудник";
      }
      return "Клиент";
    },
    clientName() {
      return getClientName(this.client);
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    onInfoTabSelect() {
      if (this.infoTabIndex === 0) {
        this.$refs.contacts.fetchData();
      }
    },
    onDataTabSelect() {
      if (this.dataTabIndex === 0) {
        // this.$refs.contacts.fetchData()
      }
    },
    checkUrl: function () {
      if (this.id === "new") {
        this.header = "Создать клиента";
      } else {
        this.fetchData();
      }
      window.scrollTo(0, 0);
    },
    fetchData: function () {
      this.client.phone = "";
      let clientsUrl = "/crm/clients/" + this.id;
      this.loading = true;
      this.$http
        .get(clientsUrl)
        .then((response) => {
          this.loading = false;
          this.client = response.data;
          this.client.managerName = this.client.manager.username;
          this.header = this.client.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    deleteClient() {
      if (confirm("Вы действительно хотите удалить клиента?")) {
        let deleteUrl = "/crm/clients/" + this.client.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            // this.client.archived = 1;
            this.loading = false;
            this.$router.push({ name: "crm-client-list" });
            // this.$eventHub.$emit('update-client-info', this.client);
            this.$bvToast.toast(this.$t("clients.client_deleted"), {
              title: this.$t("clients.client_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("clients.cant_delete_client"),
                variant: "danger",
              });
            }
          });
      }
    },
    toggleUpdate(updating) {
      this.updating = updating;
    },
    afterClientUpdate(client) {
      this.client = client;
      this.toggleUpdate(false);
    },
    canViewData() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>

<template>
  <div>
    <div v-if="isGeoForbidden" class="panel panel-danger mb-4">
      <div class="panel-heading">
        <font-awesome-icon icon="exclamation-triangle" />
        {{ $t("notifications.geolocation_blocked") }}
      </div>
      <div class="panel-body">
        {{ $t("notifications.geolocation_blocked_msg") }}
      </div>
    </div>

    <div v-if="hasGeoError" id="geoError" class="panel panel-danger mb-4">
      <div class="panel-heading">
        <font-awesome-icon icon="exclamation-triangle" />
        {{ $t("notifications.geolocation_wrong_title") }}
      </div>
      <div class="panel-body">
        {{ $t("notifications.geolocation_wrong_msg") }}
      </div>
    </div>

    <div class="ibox">
      <div class="ibox-content">
        <b-form
          id="pest-deposit-form"
          ref="deposit"
          :model="deposit"
          :coords="coords"
          class="pest-deposit-form"
          novalidate
          @submit="createOrUpdate"
          @reset="onReset"
        >
          <div class="div">
            <div v-if="loadingStats">{{ $t("common.loading") }}</div>
            <div v-else-if="!loadingStats && !count">
              <div class="panel panel-primary mb-0">
                <div class="panel-heading">
                  <!--                  <font-awesome-icon icon="warning"/>-->
                  {{ $t("notifications.you_have_no_open_shifts") }}
                </div>
              </div>
            </div>

            <div v-else>
              <div>
                <h2>{{ $t("common.select_date") }}</h2>
                <button
                  v-if="false"
                  class="btn btn-primary"
                  @click.prevent="fetchDepositData"
                >
                  Refresh
                </button>

                <div v-if="loadingStats">{{ $t("common.loading") }}</div>
                <div v-else-if="days">
                  <table class="table table-bordered table-condensed">
                    <thead>
                      <tr>
                        <th>{{ $t("deposit.date") }}</th>
                        <th>{{ $t("deposit.deposit_sum_sh") }}</th>
                        <th>{{ $t("fines.fine") }}</th>
                        <th>{{ $t("deposit.pledge") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(day, dIndex) in days"
                        :key="dIndex"
                        @click.prevent="toggleDay(day)"
                      >
                        <td class="">
                          <div class="d-flex align-items-center">
                            <b-form-checkbox
                              v-model="day.checked"
                              type="checkbox"
                              class="mr-1"
                              @click.prevent
                            />
                            <span>
                              {{ day.dayF2 }}
                            </span>
                          </div>
                        </td>
                        <td>{{ formatNumber(day.requestSum) }}</td>
                        <td>{{ formatNumber(day.fineSum) }}</td>
                        <td>{{ formatNumber(day.pledgeSum) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>{{ $t("deposit.you_have_no_debt") }}</div>
              </div>
              <div>
                <h2>{{ $t("deposit.deposit_sum") }}</h2>

                <div class="d-flex align-items-center">
                  <div class="w150">
                    <strong> {{ $t("requests.requests") }}:</strong>
                  </div>
                  <div class="h5 pt-0 mt-0 mr-1 ml-auto">
                    {{ formatNumber(totalRequestSum) }}
                  </div>
                  {{ $t("common.cur") }}
                </div>
                <div class="d-flex align-items-center">
                  <div class="w150">
                    <strong> {{ $t("fines.fines") }}:</strong>
                  </div>
                  <div class="h5 pt-0 mt-0 mr-1 ml-auto">
                    {{ formatNumber(totalFineSum) }}
                  </div>
                  {{ $t("common.cur") }}.
                </div>
                <div class="d-flex align-items-center">
                  <div class="w150">
                    <strong> {{ $t("deposit.pledge") }}:</strong>
                  </div>
                  <div class="h5 pt-0 mt-0 mr-1 ml-auto">
                    {{ formatNumber(totalPledgeSum) }}
                  </div>
                  {{ $t("common.cur") }}.
                </div>
                <div class="d-flex align-items-center">
                  <div class="w150">
                    <strong> {{ $t("deposit.deposit_sum_to_pay") }}:</strong>
                  </div>
                  <div class="h3 pt-0 mt-0 mr-1 ml-auto">
                    {{ formatNumber(totalSum) }}
                  </div>
                  {{ $t("common.cur") }}
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-button
                    v-if="!isGeoForbidden"
                    variant="primary"
                    @click="createOrUpdate"
                  >
                    <font-awesome-icon icon="piggy-bank" />
                    {{ $t("deposit.make_deposit") }}
                  </b-button>
                  <b-button v-else variant="default" @click.prevent>
                    <font-awesome-icon icon="piggy-bank" />
                    {{ $t("deposit.make_deposit") }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getTownTitle, DXB, MSK, SPB } from "@/modules/pest/decorators/towns";
import { formatNumber } from "@/modules/pest/decorators/common.js";

export default {
  name: "PestDepositForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["deposit"],
  data() {
    return {
      requestingAddress: false,
      isGeoForbidden: false,
      hasGeoError: false,
      coords: false,
      MSK,
      SPB,
      DXB,
      totalSumI: 0,
      phoneMask: false,
      loading: false,
      loadingStats: false,
      days: [],
      count: [],
    };
  },
  computed: {
    totalRequestSum() {
      if (!this.days) {
        return 0;
      }
      let sum = 0;
      for (let day in this.days) {
        if (this.days[day].checked) {
          sum += this.days[day].requestSum;
        }
      }
      return sum;
    },
    totalFineSum() {
      if (!this.days) {
        return 0;
      }
      let sum = 0;
      for (let day in this.days) {
        if (this.days[day].checked) {
          sum += this.days[day].fineSum;
        }
      }
      return sum;
    },
    totalPledgeSum() {
      if (!this.days) {
        return 0;
      }
      let sum = 0;
      for (let day in this.days) {
        if (this.days[day].checked) {
          sum += this.days[day].pledgeSum;
        }
      }
      return sum;
    },
    totalSum() {
      return this.totalRequestSum + this.totalFineSum + this.totalPledgeSum;
    },
    id() {
      return this.deposit.id;
    },
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.checkGeo();
    this.fetchDepositData();
  },
  methods: {
    formatNumber,
    getUserName,
    getTownTitle,
    prepareVariables: function () {
      let days = [];

      for (let day in this.days) {
        if (this.days[day].checked) {
          days.push(day);
        }
      }

      return {
        days: days,
        coords: this.coords,
        requestSum: this.requestSum,
        fineSum: this.fineSum,
        pledgeSum: this.pledgeSum,
        totalSum: this.totalSum,
      };
    },
    getTownClass(town) {
      if (this.deposit.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },

    fetchDepositData: function () {
      this.loadingStats = true;

      let depositsUrl = "/pest/deposit/stats";
      let params = {};
      this.$http
        .get(depositsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;
          this.days = response.data.days;
          this.count = response.data.count;
        })
        .catch((error) => {
          this.loadingStats = false;
          this.processRequestError(error);
        });
    },

    selectTown(town) {
      this.deposit.townId = town;
      this.fetchData();
    },

    update: function () {
      let updateUrl;
      updateUrl = "/pest/deposits/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.prepareVariables(this.deposit))
        .then((response) => {
          this.deposit = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-deposit-info", this.deposit);
          // this.$router.push({name: 'pest-deposit-view', params: {id: this.deposit.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("deposit.deposit_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/deposits";
      this.loading = true;
      this.hasGeoError = false;
      this.$http
        .post(updateUrl, this.prepareVariables(this.deposit))
        .then((response) => {
          this.$emit("success", response.data);
          // this.fetchDepositData();
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            if (error.response.data && error.response.data.coordsError) {
              this.$bvToast.toast(
                this.$t("notifications.you_re_at_the_wrong_place"),
                {
                  title: this.$t("common.error"),
                  variant: "danger",
                }
              );
              this.hasGeoError = true;
              this.$nextTick(() => {
                document.getElementById("geoError").scrollIntoView();
              });
            } else {
              this.$bvToast.toast(this.$t("deposit.cant_create_deposit"), {
                title: this.$t("common.error"),
                variant: "danger",
              });
            }
          }
        });
    },

    checkGeo() {
      if (!navigator.geolocation) {
        this.isGeoForbidden = true;
        console.log(this.$t("geo.geolocation_is_not_supported"));
        // this.$bvToast.toast('У вас заблокировано определение месторасположения', {
        //   title: 'Ошибка',
        //   variant: 'danger'
        // });
        return;
      }
      let $this = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          // Текущие координаты.
          let lat = position.coords.latitude;
          let lon = position.coords.longitude;
          $this.coords = {
            lat: lat,
            lon: lon,
          };
        },
        function (e) {
          $this.isGeoForbidden = true;
          console.log(this.$t("geo.geolocation_is_not_supported"));
        }
      );
    },

    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-deposit-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.create();
        // if (this.id === 'new') {
        // } else {
        //   this.update()
        // }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    handleTownSubmit(result) {
      this.deposit.townId = result.id;
    },
    getResultTownAutocompleteValue(town) {
      return this.getTownTitle(town);
    },
    searchTown(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let categoriesUrl = "/pest/towns/search";
        this.loadingTownsList = true;
        this.$http
          .get(categoriesUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loadingTownsList = false;
            resolve(response.data);
            // this.task = response.data;
            // this.header = this.task.email;
          })
          .catch((error) => {
            this.loadingTownsList = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    deleteDeposit() {
      if (confirm(this.$t("инкассация.delete_инкассация_question"))) {
        let deleteUrl = "/pest/deposits/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast(this.$t("инкассация.инкассация_deleted"), {
              title: "инкассация удалено",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("инкассация.cant_delete_инкассация"),
                variant: "danger",
              });
            }
          });
      }
    },
    toggleDay(day) {
      day.checked = !day.checked;
    },
    dayToggled(day) {
      this.$nextTick(function () {
        if (day.checked) {
          console.log(day.day);
        }
      });
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.w150 {
  width: 150px;
}
</style>

import AfterCallFailRoutes from "@/modules/pest/routers/afterCallFailRouter";
import CallCauseRoutes from "@/modules/pest/routers/callCauseRouter";
import DefaultRoutes from "@/modules/pest/routers/defaultRouter";
import EquipmentRoutes from "@/modules/pest/routers/equipmentRouter";
import FacilityRoutes from "@/modules/pest/routers/facilityRouter";
import SourceRoutes from "@/modules/pest/routers/sourceRouter";
import PaymentRoutes from "@/modules/pest/routers/paymentRouter";
import LeadRoutes from "@/modules/pest/routers/leadRouter";
import PestRoutes from "@/modules/pest/routers/pestRouter";
import PlaceRoutes from "@/modules/pest/routers/placeRouter";
import DepositRoutes from "@/modules/pest/routers/depositRouter";
import RegionRoutes from "@/modules/pest/routers/regionRouter";
import SubwayRoutes from "@/modules/pest/routers/subwayRouter";
import RejectCauseRoutes from "@/modules/pest/routers/rejectCauseRouter";
import RequestRoutes from "@/modules/pest/routers/requestRouter";
import ReportRoutes from "@/modules/pest/routers/reportRouter";
import ResourceRoutes from "@/modules/pest/routers/resourceRouter";
import ServiceRoutes from "@/modules/pest/routers/serviceRouter";
import ProductRoutes from "@/modules/pest/routers/productRouter";
import ShopResourceRoutes from "@/modules/pest/routers/shopResourceRouter";
import TownRoutes from "@/modules/pest/routers/townRouter";
import FineRoutes from "@/modules/pest/routers/fineRouter";
import ScheduleRoutes from "@/modules/pest/routers/scheduleRouter.js";
import PenaltyRoutes from "@/modules/pest/routers/penaltyRouter";
import PledgeRouter from "@/modules/pest/routers/pledgeRouter.js";
import LogRoutes from "@/modules/pest/routers/logRouter";
import PriceRoutes from "@/modules/pest/routers/priceRouter";

export default DefaultRoutes.concat(
  CallCauseRoutes,
  AfterCallFailRoutes,
  EquipmentRoutes,
  FacilityRoutes,
  PaymentRoutes,
  PestRoutes,
  LeadRoutes,
  RegionRoutes,
  SubwayRoutes,
  PlaceRoutes,
  DepositRoutes,
  RejectCauseRoutes,
  RequestRoutes,
  ReportRoutes,
  ResourceRoutes,
  SourceRoutes,
  ServiceRoutes,
  ProductRoutes,
  ShopResourceRoutes,
  TownRoutes,
  FineRoutes,
  PenaltyRoutes,
  PledgeRouter,
  LogRoutes,
  PriceRoutes,
  ScheduleRoutes
);

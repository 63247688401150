<template>
  <div
    v-click-outside="cancelSearch"
    class="autocomplete-wrapper"
    @keydown.esc="cancelSearch"
  >
    <b-form-input
      v-model="search"
      autocomplete="off"
      @keyup="startSearch"
      @keypress.enter.prevent="selectItem"
    ></b-form-input>
    <div v-if="items && items.length">
      <ul v-if="showResults" class="autocomplete-search-list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="autocomplete-search-item"
          @click.prevent="selectItem(item)"
        >
          {{ item.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DadataAutocomplete",
  components: {},
  props: [
    "model",
    "value",
    "label",
    "title",
    "placeholder",
    // 'valueProp',
    "required",
    "defaultValue",
  ],
  data() {
    return {
      key: "",
      url: "",

      search: this.value,
      lastSearch: "",
      showResults: false,
      selected: false,
      items: [],
      loading: false,
    };
  },

  computed: {
    // propModel: {
    //   get() {
    //     return this.model
    //   },
    //   set(value) {
    //     this.$emit('update:model', value)
    //   },
    // },
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(value) {
        if (value) {
          this.search = value;
        }
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.value = value;
        }
      },
    },
  },
  mounted() {
    this.initDataVariables();
    this.fetchData();
  },
  methods: {
    selectItem(item) {
      this.search = item.value;
      this.propModel = item.id;
      this.$emit("input", item.value);
      this.$emit("selected", { value: item });
      this.showResults = false;
    },
    startSearch() {
      if (this.search === this.defaultValue) {
        this.search = "";
      }
      this.showResults = true;
      this.fetchData();
    },
    cancelSearch() {
      if (!this.search || this.search.trim() === "") {
        this.search = this.defaultValue;
      }
      this.showResults = false;
    },

    fetchData() {
      this.loading = true;
      let datataUrl = this.url;
      let params = {
        query: this.search,
      };
      axios
        .get(datataUrl, {
          params: params,
          headers: { Authorization: "Token " + this.key },
        })
        .then((response) => {
          this.loading = false;
          this.items = response.data.suggestions;
          this.$forceUpdate();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    initDataVariables() {
      this.url = import.meta.env.VITE_DADATA_URL;
      this.key = import.meta.env.VITE_DADATA_KEY;
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-wrapper {
  position: relative;
}

ul.autocomplete-search-list {
  position: absolute;
  top: 35px;
  z-index: 10000;

  background: #ffffff;
  width: 100%;

  list-style: none;
  border: 1px solid lightgray;
  padding: 0;
}

.autocomplete-search-item:hover {
  cursor: pointer;
  background: #1ab394;
  color: white;
}

.autocomplete-search-item {
  width: 100%;
  margin: 0;
  padding: 10px 20px;
}
</style>

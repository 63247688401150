export const ru = {
  deposit: {
    new: "Новый",
    deposits: "Инкассации",
    assignee_blocked: "Заблокирован",
    assignee_unblocked: "Разблокирован",
    used_50_deposit: "Использовано 50% депозита",
    no_issues: "Нет проблем",

    date_from: "Дата от",
    date_to: "Дата до",
    sum: "Сумма",
    date: "Дата",

    deposit_sum: "Сдать в кассу",
    deposit_sum_to_pay: "Сумма к сдаче",
    deposit_sum_sh: "Сдать",
    make_deposit: "Сдать кассу",
    requests_in_work: "Заявок в работе",
    pledge: "Депозит",
    pledged_msg: "Накоплено",
    pledge_left_msg: "Осталось накопить:",
    pledge_change_confirmation_title: "Подтверждение",
    pledge_change_confirmation_text: "Сумма депозита успешно изменена",
    empty_list_msg: "В указанный период инкассаций не было",
    status_new_label: "На проверке",
    status_confirmed_label: "Подтвержден",
    status_error_label: "Ошибка",
    no_deposits_in_selected_time: "В указанный период инкассаций не было",
    dps_msg_sum: "Касса на сумму",
    dps_msg_period: "за период",
    dps_msg_sum_success: "успешно сдана",
    you_have_no_debt: "У вас нет долга",
    deposit_updated: "Инкассация отредактирована",
    cant_create_deposit: "Не получается удалить инкассацию",
    cant_update_deposit: "Не получается обновить депозит",
    new_deposit_must_be_greater_than_current:
      "Новый депозит должен быть больше текущего",
  },
};
export const en = {
  deposit: {
    new: "New",
    deposits: "Deposits",
    assignee_blocked: "Blocked",
    assignee_unblocked: "Unblocked",
    used_50_deposit: "50% deposit used",
    no_issues: "No issues",

    date_from: "Date from",
    date_to: "Date to",
    sum: "Sum",
    date: "Date",

    deposit_sum: "Deposit sum",
    deposit_sum_to_pay: "Deposit sum",
    deposit_sum_sh: "Deposit",
    requests_in_work: "Requests in work",
    pledge: "Pledge",
    pledged_msg: "Pledged:",
    pledge_left_msg: "Pledge left:",
    pledge_change_confirmation_title: "Confirmation",
    pledge_change_confirmation_text: "Pledge sum changed",
    empty_list_msg: "No deposits for selected period",

    status_new_label: "Verifying",
    status_confirmed_label: "Confirmed",
    status_error_label: "Error",
    make_deposit: "Make deposit",
    no_deposits_in_selected_time: "No deposits in selected time",
    dps_msg_sum: "Cash",
    dps_msg_period: "for period",
    dps_msg_sum_success: "successfully deposited",
    you_have_no_debt: "You have no debt",
    deposit_updated: "Deposit updated",
    cant_create_deposit: "Can't create deposit",
    cant_update_deposit: "Can't update deposit",
    new_deposit_must_be_greater_than_current:
      "New deposit must be greater than current",
  },
};

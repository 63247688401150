<template>
  <b-form
    v-if="dataLoaded"
    id="request-form"
    ref="request"
    :model="request"
    class="request-form gray-bg"
    novalidate
    @submit.prevent="createOrUpdate"
    @reset="onReset"
    @keydown.enter="onEnterDown"
  >
    <div class="ibox">
      <div class="ibox-title d-flex pr-4 toptitle">
        <div>
          <div class="d-flex align-items-baseline">
            <h3 class="mt-0">{{ getRequestStageTitle(request) }}</h3>
            <div v-if="request.id !== 'new'" class="ml-2 small">
              [{{ $t("requests.lead_created") }}
              {{ getRequestCreateDatetime(request) }}]
            </div>
          </div>
          <div>
            <div v-if="request.source" class="ml-auto">
              <a :href="getRequestSourceUrl(request)" target="_blank">{{
                request.source.title
              }}</a>
            </div>
          </div>
          <!--          <div v-if="request.id!=='new'">Дата создания | Менеджер</div>-->
        </div>
        <div class="ml-auto">
          <request-call-notifications :request="request" />
        </div>
        <div v-if="showRepeat" class="ml-2">
          <button
            class="btn btn-primary"
            @click.prevent="createReturnVisit(request)"
          >
            <font-awesome-icon icon="reply" />
            {{ $t("requests.assign_follow_up_treatment") }}
          </button>
        </div>
      </div>

      <div class="ibox-content">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.manager')" prop="managerId">
              <user-autocomplete
                v-model="request.managerId"
                :type="TYPE_MANAGER"
                :disabled="!canChangeManager"
                :default-value="getRequestManagerName(request)"
                @selected="selectManager"
              ></user-autocomplete>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.status')" prop="leadStatusId">
              <b-input-group>
                <b-form-select
                  v-model="request.leadFormStatusId"
                  :options="statusesList"
                  :disabled="loadingStatuses"
                  @change="updateLeadStatus"
                />
                <b-input-group-append v-if="loadingStatuses">
                  <button class="btn btn-outline-secondary" @click.prevent>
                    <font-awesome-icon icon="rotate" spin />
                  </button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-3">
            <b-form-group :label="$t('requests.assignee')" prop="assigneeId">
              <!--user-autocomplete
                v-model="request.assigneeId"
                :type="TYPE_WORKER"
                :default-value="getRequestAssigneeName(request)"
                :disabled="!canChangeAssignee"
                :show-reset="request.hasPredefinedAssignee"
                @selected="selectAssignee"
              ></user-autocomplete-->

              <technician-autocomplete
                v-model="request.assigneeId"
                :extra-technicians="request.extraTechnicians"
                :technician="request.assignee"
                :type="TYPE_WORKER"
                :default-value="getRequestAssigneeName(request)"
                :disabled="!canChangeAssignee"
                :show-reset="request.hasPredefinedAssignee"
                @selected="selectAssignee"
              ></technician-autocomplete>
            </b-form-group>

            <b-form-group
              v-if="showAssigneeCancelCause"
              :label="$t('requests.assignee_cancel_cause')"
              prop="assigneeCancelCause"
            >
              <b-form-textarea
                v-model="request.assigneeCancelCause"
                required
                :disabled="!showAssigneeCancelCauseField"
                :state="validation.assigneeCancelCause"
              ></b-form-textarea>
              <b-form-invalid-feedback>{{
                $t("requests.enter_assignee_cancel_cause")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-md-1 d-flex align-items-end pb-16">
            <button
              :disabled="!canChangeAssignee"
              class="btn btn-outline btn-outline-primary addPestButton"
              @click.prevent="addExtraTechnician"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>

          <div
            v-for="(extraTechnician, index) in request.extraTechnicians"
            :key="index"
            class="col-md-4"
          >
            <!--       :default-value="getRequestPestName(request)"
            :state="validation.pest"
            -->
            <b-form-group :label="$t('requests.extra_technician')" prop="title">
              <!--technician-autocomplete
               :show-reset не включал
               -->
              <technician-autocomplete
                :id="index"
                v-model="request.extraTechnicians[index].id"
                :extra-technicians="request.extraTechnicians"
                :technician="request.assignee"
                :type="TYPE_WORKER"
                :disabled="!canChangeAssignee"
                :default-value="getRequestTechnicianName(extraTechnician)"
                :secondary-technician="true"
                @selected="selectExtraTechnician($event, index)"
              ></technician-autocomplete>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <!--div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.project')" prop="project">
              <universal-autocomplete
                ref="projectAutocomplete"
                :add-new="false"
                :default-value="
                  request.projectId ? getProjectTitle(request.project) : ''
                "
                :required="true"
                :get-title="getProjectTitle"
                :get-data="getProjects"
                :disabled="projectDisabled"
                @selected="selectProject"
              ></universal-autocomplete>
              <b-form-invalid-feedback :force-show="!request.projectId"
                >{{ $t("requests.select_project") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div-->
          <div v-if="showManualSource" class="col-sm-12 col-md-4">
            <b-form-group
              :label="
                request.contractId
                  ? $t('requests.request_source')
                  : $t('requests.lead_source')
              "
              prop="manualSource"
            >
              <source-autocomplete
                ref="sourceAutocomplete"
                v-model="request.sourceId"
                :type="TYPE_MANUAL"
                :target="request.contractId ? TARGET_IN_CONTRACT : TARGET_ALL"
                :state="validation.sourceId"
                :project-id="request.projectId"
                :required="true"
                :disabled="!request.projectId"
                @selected="selectSource"
              ></source-autocomplete>
              <b-form-invalid-feedback :force-show="!validation.sourceId"
                >{{ $t("requests.select_source") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div v-if="showRejectionComment" class="row">
          <div class="col-sm-12 col-md-4"></div>
          <div class="col-sm-12 col-md-8">
            <b-form-group
              :label="$t('requests.rejection_comment')"
              prop="rejectionComment"
            >
              <b-form-textarea
                v-model="request.rejectionComment"
                required
                :state="validation.rejectionComment"
              ></b-form-textarea>
              <b-form-invalid-feedback
                >{{ $t("requests.enter_rejection_comment") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <!--  новые контакты -->
        <fieldset>
          <legend
            id="__BVID__420__BV_label_"
            class="bv-no-focus-ring col-form-label pt-0"
          >
            {{ $t("requests.requests_contacts") }}
            <a
              v-b-tooltip.hover
              class="btn btn-xs btn-outline-primary ml-2"
              :title="$t('requests.add_contact')"
              @click.prevent="addCommunication"
            >
              <font-awesome-icon icon="plus" />
              {{ $t("requests.add_contact") }}
            </a>
          </legend>
          <div
            v-if="request.communications && request.communications.length"
            class="d-flex flex-wrap"
          >
            <div
              v-for="(communication, cIndex) in request.communications"
              :key="'communication' + cIndex"
              class="contact-card px-2 pt-2 pb-2 mr-2 mb-2"
            >
              <!--                style="position: relative"-->
              <div class="d-flex mb-2">
                <div>
                  <h4>{{ $t("requests.contact") }} {{ cIndex + 1 }}</h4>
                </div>

                <div class="ml-auto">
                  <button
                    v-if="false"
                    v-b-tooltip.hover
                    class="btn btn-outline-primary success btn-xs"
                    :title="$t('common.edit')"
                    @click.prevent="editContact(communication)"
                  >
                    <font-awesome-icon icon="pencil" />
                    {{ $t("common.edit") }}
                  </button>

                  <button
                    v-if="request.contractId"
                    v-b-tooltip.hover
                    class="btn btn-outline-danger btn-xs ml-2"
                    :title="$t('requests.add_contact')"
                    @click.prevent="deleteCommunication(cIndex)"
                  >
                    <font-awesome-icon icon="times" />
                    {{ $t("common.remove") }}
                  </button>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <div class="mb-2">
                  <b-button-group>
                    <div v-if="!request.contractId">
                      <b-form-input
                        v-model="communication.clientName"
                        class="contact-text-input"
                        required
                        :state="
                          checkValidCommunicationClientName(
                            communication.clientName
                          )
                        "
                        :disabled="!!communication.blockedName"
                        :placeholder="$t('requests.enter_name')"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ $t("requests.enter_name_mgs") }}
                      </b-form-invalid-feedback>
                    </div>

                    <universal-autocomplete
                      v-else
                      :default-value="
                        communication.clientId
                          ? communication.clientName
                            ? communication.clientName
                            : $t('requests.without_name')
                          : $t('requests.select_contact')
                      "
                      :add-new="false"
                      :get-title="getClientName"
                      :get-data="getClientsForRequest"
                      :disabled="!!communication.clientId"
                      @selected="
                        selectClientForCommunication($event, communication)
                      "
                    ></universal-autocomplete>
                    <button
                      v-if="false"
                      class="btn btn-outline-danger"
                      @click.prevent="deleteCommunication(cIndex)"
                    >
                      <font-awesome-icon icon="times" />
                    </button>
                  </b-button-group>
                </div>
              </div>
              <div>
                <div
                  v-for="(phone, pIndex) in communication.phones"
                  :key="'phone' + cIndex + '-' + pIndex"
                  class="mb-2 d-flex phones"
                >
                  <b-form-input
                    v-if="!request.contractId"
                    v-model="communication.phones[pIndex]"
                    class="contact-text-input"
                    :disabled="
                      communication.blocked && pIndex < communication.count
                    "
                    :placeholder="$t('clients.enter_phone')"
                  ></b-form-input>
                  <universal-autocomplete
                    v-else
                    :default-value="phone ? phone : $t('clients.choose_phone')"
                    :add-new="false"
                    :get-title="getClientPhoneFromContact"
                    :get-data="getClientCardPhones(communication.clientId)"
                    :disabled="!communication.clientId"
                    @selected="
                      selectPhoneForCommunication(
                        $event,
                        communication.clientId,
                        communication,
                        pIndex
                      )
                    "
                  ></universal-autocomplete>

                  <button
                    v-if="request.contractId"
                    v-b-tooltip.hover
                    class="btn btn-outline-gray btn-xs"
                    :disabled="!request.contractId"
                    :title="$t('clients.remove_phone_from_contact')"
                    @click.prevent="
                      deletePhoneFromCommunication(communication, pIndex)
                    "
                  >
                    <font-awesome-icon fixed-width icon="times" />
                  </button>
                  <button
                    v-b-tooltip.hover
                    class="btn btn-success btn-sm ml-auto"
                    :title="$t('clients.add_phone')"
                    @click.prevent="makeCallInternal(communication, phone)"
                  >
                    <font-awesome-icon icon="phone" />
                    {{ $t("clients.make_call") }}
                  </button>
                </div>
                <!--              v-if="!communication.hideButtons"-->
                <div class="d-flex">
                  <div>
                    <button
                      v-b-tooltip.hover
                      class="btn btn-outline-primary btn-xs"
                      :title="$t('clients.add_phone')"
                      @click.prevent="addPhoneToCommunication(communication)"
                    >
                      <font-awesome-icon icon="plus" />
                      {{ $t("requests.add_phone") }}
                    </button>
                  </div>

                  <div v-if="false">
                    <button
                      v-b-tooltip.hover
                      :title="$t('requests.remove_contact')"
                      class="btn btn-outline-danger btn-xs ml-2"
                      @click.prevent="deleteContact(cIndex)"
                    >
                      {{ $t("requests.remove_contact") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <!--Расчет стоимости-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>{{ $t("requests.cost_calculation") }}</h3>
      </div>

      <div class="ibox-content">
        <div class="row">
          <div class="col-md-4">
            <b-form-group :label="$t('requests.town')" prop="townIds">
              <town-autocomplete
                ref="townAutocomplete"
                required=""
                :state="validation.town"
                :default-value="
                  request.townId ? getTownTitle(request.town) : ''
                "
                @selected="selectTown"
              ></town-autocomplete>
              <b-form-invalid-feedback :force-show="validation.town === false">
                {{ $t("requests.select_town") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group :label="$t('requests.pest')" prop="title">
              <pest-autocomplete
                v-model="request.pestId"
                :additional-pest="request.additionalPests"
                :pest="request.pest"
                :state="validation.pest"
                :default-value="getRequestPestName(request)"
                @selected="selectPest"
              ></pest-autocomplete>
              <b-form-invalid-feedback :force-show="validation.pest === false">
                {{ $t("requests.select_pest") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-md-1 d-flex align-items-end pb-16">
            <!--- v-if="request.id !== 'new'" --->
            <button
              class="btn btn-outline btn-outline-primary addPestButton"
              @click.prevent="addAdditionalPest"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>

          <div
            v-for="(additionalPest, index) in request.additionalPests"
            :key="index"
            class="col-md-4"
          >
            <!--       :default-value="getRequestPestName(request)"
            :state="validation.pest"
            -->
            <b-form-group :label="$t('requests.additionalPest')" prop="title">
              <additional-pest-autocomplete
                :id="index"
                v-model="request.additionalPests[index].pestId"
                :additional-pest="request.additionalPests"
                :pest="request.pest"
                :default-value="getRequestPestName(additionalPest)"
                @selected="selectAdditionalPest($event, index)"
              ></additional-pest-autocomplete>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group :label="$t('requests.facility')" prop="title">
              <facility-autocomplete
                v-model="request.facilityId"
                :add-new="true"
                :state="validation.facility"
                :type="request.clientType"
                :default-value="getRequestFacilityName(request)"
                @selected="selectFacility"
              ></facility-autocomplete>
              <b-form-invalid-feedback
                :force-show="validation.facility === false"
                >{{ $t("requests.select_facility") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!--div class="col-md-4">
            <b-form-group
              :label="$t('requests.client_type')"
              prop="title"
              :state="validation.clientType"
            >
              <b-button-group>
                <button
                  class="btn"
                  :class="getClientTypeClass(CLIENT_TYPE_IND)"
                  @click.prevent="selectClientType(CLIENT_TYPE_IND)"
                >
                  {{ $t("requests.type_individual") }}
                </button>
                <button
                  class="btn"
                  :class="getClientTypeClass(CLIENT_TYPE_ORG)"
                  @click.prevent="selectClientType(CLIENT_TYPE_ORG)"
                >
                  {{ $t("requests.type_organization") }}
                </button>
              </b-button-group>
              <b-form-invalid-feedback
                :force-show="validation.clientType === false"
                >{{ $t("requests.client_type") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group :label="$t('requests.payment_variant')" prop="title">
              <b-button-group>
                <button
                  class="btn"
                  :class="getClientExchangeSourceType(false)"
                  @click.prevent="selectExchangeSourceType(0)"
                >
                  {{ $t("requests.payment_regular") }}
                </button>
                <button
                  class="btn"
                  :class="getClientExchangeSourceType(true)"
                  @click.prevent="selectExchangeSourceType(1)"
                >
                  {{ $t("requests.payment_exchange") }}
                </button>
              </b-button-group>
            </b-form-group>
          </div-->
          <div class="col-md-4">
            <b-form-group
              v-if="request.clientType === CLIENT_TYPE_IND"
              :label="$t('requests.payment_type')"
              prop="title"
              :state="validation.paymentType"
            >
              <b-button-group>
                <button
                  class="btn"
                  :class="getClientPaymentTypeClass(PT_CASH)"
                  @click.prevent="selectClientPaymentType(PT_CASH)"
                >
                  {{ $t("requests.payment_cash") }}
                </button>
                <button
                  class="btn"
                  :class="getClientPaymentTypeClass(PT_BANK_TRANS)"
                  @click.prevent="selectClientPaymentType(PT_BANK_TRANS)"
                >
                  {{ $t("requests.payment_bank_trans") }}
                </button>
                <button
                  class="btn"
                  :class="getClientPaymentTypeClass(PT_PAYMENT_LINK)"
                  @click.prevent="selectClientPaymentType(PT_PAYMENT_LINK)"
                >
                  {{ $t("requests.payment_payment_link") }}
                </button>
              </b-button-group>
              <b-form-invalid-feedback
                :force-show="validation.paymentType === false"
                >{{ $t("requests.select_payment_type") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group
              v-if="
                [PT_BANK_TRANS, PT_PAYMENT_LINK].includes(request.paymentType)
              "
              :label="$t('requests.payment_received')"
              prop="title"
              :state="validation.clientPaymentReceived"
            >
              <b-button-group>
                <button
                  class="btn"
                  :class="getClientPaymentReceivedClass(1)"
                  @click.prevent="selectClientPaymentReceived(1)"
                >
                  {{ $t("common.yes") }}
                </button>
                <button
                  class="btn"
                  :class="getClientPaymentReceivedClass(0)"
                  @click.prevent="selectClientPaymentReceived(0)"
                >
                  {{ $t("common.no") }}
                </button>
              </b-button-group>
              <b-form-invalid-feedback
                :force-show="validation.paymentType === false"
                >{{ $t("requests.select_payment_type") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div v-if="showInspectionCheckbox" class="col-md-2">
            <b-form-group :label="$t('requests.free_inspection')">
              <b-form-checkbox
                v-model="request.freeInspection"
                class="form-checkboxes"
                >{{ $t("users.enabled") }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>

        <!--div class="row">
          <div class="col-md-6">
            <b-form-group :label="$t('requests.known_sum')" prop="title">
              <div class="input-group">
                <b-button-group class="mr-1">
                  <button
                    v-for="(price, pIndex) in prices"
                    :key="'p' + pIndex"
                    class="btn"
                    :class="getPriceClass(price.price)"
                    @click.prevent="selectPrice(price.price)"
                  >
                    {{ price.price }}
                    {{ price.comment ? "(" + price.comment + ")" : "" }}
                  </button>
                  <button
                    class="btn"
                    :class="getPriceClass('-')"
                    @click.prevent="selectPrice('-')"
                  >
                    {{ $t("common.other") }}
                  </button>
                </b-button-group>
                <b-form-input
                  v-model="request.knownSum"
                  :disabled="!priceManual"
                ></b-form-input>
              </div>
          -->
        <!--              <b-form-input v-model="request.sum" placeholder="" number></b-form-input>-->
        <!-- </b-form-group>
         </div>

          <div class="col-md-2">
            <b-form-group :label="$t('requests.trip_distance')" prop="title">
              <b-form-input v-model="request.tripDistance"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group :label="$t('requests.trip_sum')" prop="title">
              <b-form-input v-model="request.tripSum"></b-form-input>
            </b-form-group>
          </div>
        </div-->

        <div
          v-if="
            request.clientType === CLIENT_TYPE_ORG &&
            request.paymentType === PT_BANK_TRANS
          "
        >
          <hr />
          <div class="panel panel-warning mb-4">
            <div class="panel-heading">
              <font-awesome-icon icon="exclamation-triangle" />
              {{ $t("notifications.required_client_sms_msg") }}
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <b-form-group :label="$t('clients.phone')">
                <b-form-input v-model="sendSmsPhone"></b-form-input>
              </b-form-group>
            </div>

            <div v-if="false" class="col-md-3 pt-4">
              <button
                v-if="!sendingSms"
                class="btn btn-outline-primary"
                @click.prevent="sendSms"
              >
                <font-awesome-icon icon="paper-plane" />
                {{ $t("common.send") }}
              </button>
              <button v-else class="btn btn-outline-primary disabled">
                <font-awesome-icon icon="rotate" spin />
                {{ $t("common.sending") }}
              </button>
            </div>
            <div class="col-md-4 pt-4">
              <div
                v-if="showSuccessSmsMessage"
                class="panel panel-success mb-0"
              >
                <div class="panel-heading">
                  <font-awesome-icon icon="ok" />
                  {{ $t("notifications.message_sent") }}
                </div>
              </div>
              <div v-if="showErrorSmsMessage" class="panel panel-warning mb-0">
                <div class="panel-heading">
                  <font-awesome-icon icon="exclamation-triangle" />
                  {{ errorSmsMessage }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group label="Текст">
                <b-form-textarea
                  v-model="sendSmsText"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </div>

        <div
          v-if="[SPB_C, MSK_C].includes(request.townId)"
          class="panel panel-warning mb-0"
        >
          <div class="panel-heading">
            <font-awesome-icon icon="exclamation-triangle" />
            {{ $t("requests.full_address_mgs") }}
          </div>
        </div>
      </div>
    </div>
    <!--Адрес-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>{{ $t("requests.address") }}</h3>
      </div>
      <div class="ibox-content">
        <!--div class="row">
          <div class="col-md-12">
            <b-form-group
              :label="$t('requests.search_by_address')"
              prop="address"
            >
              <dadata-autocomplete
                v-model="request.searchAddress"
                :placeholder="$t('requests.search_address_example')"
                @selected="selectAddress"
              />
            </b-form-group>
          </div>
        </div-->

        <div class="row">
          <!--div class="col-md-3">
            <b-form-group :label="$t('requests.address_postcode')" prop="">
              <b-form-input v-model="request.addressIndex"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group :label="$t('requests.address_town')" prop="">
              <b-form-input v-model="request.addressTown"></b-form-input>
            </b-form-group>
          </div-->

          <!-- :state="validation.address" -->

          <div class="col-md-3">
            <b-form-group :label="$t('requests.address')" prop="address">
              <b-form-input v-model="request.address"></b-form-input>
              <b-form-invalid-feedback>{{
                $t("requests.address_enter_address")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <!--v-if="showSubway"-->
            <b-form-group :label="$t('requests.region')" prop="regionId">
              <region-autocomplete
                ref="subwayAutocomplete"
                required=""
                :town-id="request.townId"
                :all="true"
                :default-value="
                  request.regionId ? getSubwayTitle(request.region) : ''
                "
                @selected="selectRegion"
              ></region-autocomplete>
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group
              :label="$t('requests.address_appartment')"
              prop="appartment"
            >
              <template #label>
                {{ $t("requests.address_appartment") }}
                <request-appartment-visibility
                  class="ml-auto"
                  :request="request"
                  :assignee-can-see-address="request.assigneeCanSeeAddress"
                />
              </template>
              <b-form-input v-model="request.appartment"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group :label="$t('requests.google_maps_link')" prop="">
              <b-form-input v-model="request.googleMapsLink"></b-form-input>
            </b-form-group>
          </div>
        </div>

        <!--div class="row">
          <div class="col-md-2">
            <b-form-group :label="$t('requests.address_house')" prop="">
              <b-form-input v-model="request.addressHouse"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group :label="$t('requests.address_block')" prop="">
              <b-form-input v-model="request.addressBlock"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group :label="$t('requests.address_entrance')" prop="">
              <b-form-input v-model="request.addressEntrance"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group
              :label="$t('requests.address_floor')"
              prop="appartment"
            >
              <b-form-input v-model="request.addressFloor"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group
              :label="$t('requests.address_intercom')"
              prop="appartment"
            >
              <b-form-input v-model="request.addressIntercom"></b-form-input>
            </b-form-group>
          </div>
        </div-->

        <div class="row">
          <div class="col-md-8">
            <b-form-group
              :label="$t('requests.address_info')"
              prop="addressInfo"
            >
              <b-form-input v-model="request.addressInfo"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <!--prop="addressInfo"-->
            <b-form-group :label="$t('requests.address_coords')">
              <!--{{ request.addressCoords }}-->
              <b-form-input v-model="request.addressCoords"></b-form-input>
            </b-form-group>
          </div>
        </div>

        <request-form-map
          ref="map"
          :town-id="request.townId"
          :coords="request.addressCoords"
          @distance="distanceCalculated"
        />
      </div>
    </div>

    <!-- Выезды по цепочке -->
    <requests-sequence-history
      v-if="sequence && sequence.length > 1"
      :sequence="sequence"
      :request:="request"
    />

    <!--Время-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>{{ $t("requests.time") }}</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              :label="$t('requests.date')"
              prop="duration"
              :state="validation.dateDay"
            >
              <date-picker
                v-model="request.dateDay"
                :state="validation.dateDay"
                @change="dateChanged"
              ></date-picker>
              <b-form-invalid-feedback
                :force-show="validation.dateDay === false"
                >{{ $t("requests.choose_date") }}</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group :label="$t('requests.time')" prop="duration">
              <b-form-select
                v-model="request.dateTime"
                :options="timeOptions"
                :state="validation.dateTime"
                :disabled="!request.dateDay"
              ></b-form-select>
              <b-form-invalid-feedback>{{
                $t("requests.choose_time")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="col-md-4 pt-4">
            <font-awesome-icon
              v-if="loadingBusiness"
              icon="rotate"
              class="mt-2"
              spin
            />
            <!--            <button class="btn btn-outline-primary mt-2" v-if="loadingBusiness">-->
            <!--            </button>-->
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group :label="$t('requests.description')" prop="comment">
              <b-form-textarea v-model="request.comment"></b-form-textarea>
            </b-form-group>
            <b-form-group :label="$t('requests.warning')" prop="warning">
              <b-form-textarea v-model="request.warning"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <!--Сохранить-->

    <div v-if="!embedded" class="ibox">
      <div class="ibox-content">
        <b-button variant="primary" @click.prevent="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ $t("common.save") }}
        </b-button>
      </div>
    </div>
    <contact-popup />
    <new-contact-phone-popup />
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import {
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  getRequestTechnicianName,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateDatetime,
  getRequestFacilityName,
  getRequestManagerName,
  getRequestNumber,
  getRequestPestName,
  getRequestSourceUrl,
  getRequestStageTitle,
  PT_CASH,
  PT_BANK_TRANS,
  PT_PAYMENT_LINK,
  STAGE_DEAL,
  STAGE_LEAD,
  STATUS_DECLINE,
  STATUS_DECLINE_LEAD_BOARD,
  STATUS_DONE,
  STATUS_NEW,
  confirmationAssigneeWhenInspection,
} from "@/modules/pest/decorators/requests.js";
import { TYPE_MANAGER, TYPE_WORKER } from "@/modules/users/decorators/users.js";
import DatePicker from "@/components/shared/DatePicker.vue";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import TechnicianAutocomplete from "@/modules/users/views/user/technicians/TechnicianAutocomplete.vue";
import PestAutocomplete from "@/modules/pest/views/pests/PestAutocomplete.vue";
import FacilityAutocomplete from "@/modules/pest/views/facilities/FacilityAutocomplete.vue";
import {
  getTownTitle,
  DXB,
  MSK,
  MSK_C,
  SPB,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import DadataAutocomplete from "@/components/shared/DadataAutocomplete.vue";
import TownAutocomplete from "@/modules/pest/views/towns/TownAutocomplete.vue";
import clone from "just-clone";

import { getSubwayTitle } from "@/modules/pest/decorators/subways.js";
import RequestFormMap from "@/modules/pest/views/requests/card/RequestFormMap.vue";
import dayjs from "dayjs";
import UniversalAutocomplete from "@/components/shared/UniversalAutocomplete.vue";
import RequestAppartmentVisibility from "@/modules/pest/views/requests/dispatching/_request/view/RequestAppartmentVisibility";
import ContactPopup from "@/modules/pest/views/requests/contract/contacts/ContactPopup";
import NewContactPhonePopup from "@/modules/pest/views/requests/contract/contacts/NewContactPhonePopup";
import {
  durationOptions,
  timeHours,
  timeOptions,
} from "@/modules/pest/decorators/schedule";
import RequestCallNotifications from "@/modules/pest/views/requests/kanban/popup/RequestCallNotifications";
import { getRejectCauseTitle } from "@/modules/pest/decorators/reject-causes";
import RequestsSequenceHistory from "@/modules/pest/views/requests/kanban/popup/RequestSequenceHistory.vue";
import * as Sentry from "@sentry/vue";
import SourceAutocomplete from "@/modules/pest/views/sources/SourceAutocomplete.vue";
import {
  TARGET_IN_CONTRACT,
  TARGET_LEAD,
  TYPE_MANUAL,
} from "@/modules/pest/decorators/sources";
import {
  canChangeAssignee,
  canChangeManager,
} from "@/modules/users/access/common";
import { getProjectTitle } from "@/modules/pest/decorators/project";
// import SubwayAutocomplete from "@/modules/pest/views/subways/SubwayAutocomplete.vue";
import RegionAutocomplete from "@/modules/pest/views/regions/RegionAutocomplete.vue";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import t from "@/i18n";
import AdditionalPestAutocomplete from "@/modules/pest/views/pests/AdditionalPestAutocomplete.vue";

export default {
  name: "RequestForm",
  components: {
    AdditionalPestAutocomplete,
    SourceAutocomplete,
    RequestsSequenceHistory,
    RequestCallNotifications,
    NewContactPhonePopup,
    ContactPopup,
    RequestAppartmentVisibility,
    UniversalAutocomplete,
    RequestFormMap,
    TownAutocomplete,
    PestAutocomplete,
    FacilityAutocomplete,
    UserAutocomplete,
    DatePicker,
    RegionAutocomplete,
    TechnicianAutocomplete,
    //SubwayAutocomplete,
    //DadataAutocomplete,
  },
  props: ["requestModel", "dataLoaded", "embedded", "sequence"],
  data() {
    return {
      show: true,
      TYPE_MANUAL,
      TARGET_ALL: TARGET_LEAD,
      TARGET_IN_CONTRACT,
      STATUS_DECLINE_LEAD_BOARD,
      STATUS_DECLINE,
      MSK,
      SPB,
      DXB,
      MSK_C,
      SPB_C,
      PT_BANK_TRANS,
      PT_PAYMENT_LINK,
      PT_CASH,
      CLIENT_TYPE_ORG,
      CLIENT_TYPE_IND,
      TYPE_MANAGER,
      TYPE_WORKER,
      STAGE_DEAL,
      STAGE_LEAD,
      dateFormat: "DD.MM.YYYY",
      labelWidth: "150px",
      loading: false,
      loadingBusiness: false,
      schedule: false,
      sendSmsPhone: "",
      sendingSms: false,
      loadingPrices: false,
      loadingStatuses: false,
      prices: [],
      priceManual: false,
      sendSmsText:
        "Пришлите пожалуйста реквизиты вашей компании на нашу почту, спасибо: sanitar-centr@yandex.ru",
      leadStatusesList: [],
      statusesList: [],
      projectsList: [],
      startWorkStatusId: null,
      showSuccessSmsMessage: false,
      showErrorSmsMessage: false,
      showAssigneeCancelCauseField: false,
      errorSmsMessage: "",
      calling: false,
      closeLeadId: false,
      validationAddress: null,
      validation: {
        address: null,
        pest: null,
        town: null,
        clientType: null,
        paymentType: null,
        dateDay: null,
        dateTime: null,
        rejectionComment: null,
        assigneeCancelCause: null,
        clientPaymentReceived: null,
      },
      request: {
        clientType: CLIENT_TYPE_IND,
        stage: STAGE_DEAL,
        dateDay: "",
        knownSum: "",
        dateTime: 10,
        duration: 120,
        pestId: null,
        pest: null,
        address: null,
        addressInfo: null,
        addressCoords: null,
        appartment: null,
        townId: this.$store.state.user.profile.townId,
        managerId: this.$store.state.user.profile.id,
        manager: this.$store.state.user.profile,
        rejectCause: null,
        rejectCauseComment: "",
        rejectionComment: "",
        assigneeDeclineCause: "",
        sourceId: null,
        warning: "",
        additionalPests: [],
        extraTechnicians: [],
        previousTechnician: null,
        isExtraTechnician: false,
      },
      timeHours: timeHours,
      timeOptions: timeOptions,
      durationOptions: durationOptions,
      assigneeSchedule: null,
    };
  },
  computed: {
    showInspectionCheckbox() {
      if (this.request.id === "new") {
        switch (this.request.isFirstTime) {
          case 0:
            return false;
          case 1:
            return !this.request.previousNotInspection;
          default:
            return true;
        }
      } else return this.request.isFirstTime !== 0;
    },

    projectDisabled() {
      return (
        !this.isNew ||
        Boolean(this.request.contractId && this.request.projectId)
      );
    },
    /*showSubway() {
      return [SPB, MSK].includes(this.request.townId);
    },*/
    showRepeat() {
      return (
        this.request.stage === STAGE_DEAL &&
        (this.request.status === STATUS_DONE ||
          this.request.status === STATUS_DECLINE) &&
        !this.request.hasNext
      );
    },
    addressRequired() {
      return [SPB_C, MSK_C].includes(this.request.townId);
    },
    canChangeAssignee() {
      return canChangeAssignee(this.$store.state.user);
    },
    canChangeManager() {
      return canChangeManager(this.$store.state.user);
    },
    clientName() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    managerName() {
      return this.request && this.request.managerId && this.request.manager
        ? getUserName(this.request.manager)
        : "";
    },
    assigneeName() {
      return this.request && this.request.assigneeId && this.request.assignee
        ? getUserName(this.request.assignee)
        : "";
    },
    id() {
      return this.request.id;
    },
    isNewDeal() {
      return (
        this.request.stage === STAGE_DEAL && this.request.status === STATUS_NEW
      );
    },
    showManualSource() {
      return this.request.id === "new";
    },
    isNew() {
      return this.request.id === "new";
    },
    showAssigneeCancelCause() {
      let request = this.request;
      return !!request.assigneeCancelCause || this.showAssigneeCancelCauseField;
    },
    showRejectionComment() {
      let request = this.request;
      return (
        (request.stage === STAGE_DEAL && request.status === STATUS_DECLINE) ||
        (request.stage === STAGE_LEAD &&
          request.leadStatusId === this.closeLeadId)
      );
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = clone(obj);
        this.setLeadStatus();
        this.loadPrices();
        this.checkStatusList();
        this.fetchAssigneeCalendar();
        this.loadBusiness();
      },
    },
  },
  created() {
    this.fetchStatuses();
    this.checkProjects();
  },
  methods: {
    checkProjects() {
      if (this.request.projectId && !this.request.project) {
        let projectUrl = "pest/project/search";
        this.$http
          .get(projectUrl)
          .then((response) => {
            this.projectsList = response.data;
            for (let project of this.projectsList) {
              if (this.request.projectId === project.id) {
                this.request.project = project;
                this.$forceUpdate();
              }
            }
          })
          .catch((error) => {});
      }
    },
    selectProject(project) {
      this.request.projectId = project.value.id;
      this.request.project = project.value;
      this.resetRequestSource();
    },
    getProjectTitle,
    resetRequestSource() {
      this.request.sourceId = null;
      this.$refs.sourceAutocomplete.selectItem(false);
    },
    onEnterDown(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
      }
    },
    getRequestAssigneeName,
    getRequestTechnicianName,
    getRequestStageTitle,
    getRejectCauseTitle,
    getRequestNumber,
    getTownTitle,
    getRequestPestName,
    getRequestCreateDatetime,
    getRequestManagerName,
    getRequestClientName,
    getRequestFacilityName,
    getClientName,
    getUserName,
    getStatusTitle,
    getSubwayTitle,
    getClientNameWithContacts,
    getRequestSourceUrl,
    // form submit methods
    checkValidCommunicationClientName(clientName) {
      let hasNumberRegex = /\d/;
      clientName = clientName.trim();
      return clientName.length > 0 && !hasNumberRegex.test(clientName);
    },
    processRequestFields(request) {
      function getAdditionalPestsIds(data) {
        let result = [];
        data.forEach((el, id) => {
          result[id] = el.pestId;
        });
        return result;
      }

      let fields = {
        contractId: request.contractId,
        sourceId: request.sourceId,
        previousId: request.previousId,
        duplicateId: request.duplicateId,
        knownSum: request.knownSum,
        dateDay: request.dateDay,
        dateTime: request.dateTime,
        duration: request.duration,
        address: request.address,
        clientName: request.clientName,
        addressInfo: request.addressInfo,
        addressCoords: request.addressCoords,
        appartment: request.appartment,
        pestId: request.pestId,
        stage: request.stage,
        status: request.status,
        leadStatusId: request.leadStatusId,
        townId: request.townId,
        managerId: request.managerId,
        assigneeId: request.assigneeId ? request.assigneeId : null,
        facilityId: request.facilityId,
        subwayId: request.subwayId,
        regionId: request.regionId,
        clientId: request.clientId,
        comment: request.comment,
        warning: request.warning,
        phone: request.phone,
        tripDistance: request.tripDistance,
        tripSum: request.tripSum,
        fromExchange: request.fromExchange,
        contactPhone: request.contactPhone,
        contactPhone2: request.contactPhone2,
        contactPhone3: request.contactPhone3,
        addressIndex: request.addressIndex,
        addressTown: request.addressTown,
        addressHouse: request.addressHouse,
        googleMapsLink: request.googleMapsLink,
        addressBlock: request.addressBlock,
        addressEntrance: request.addressEntrance,
        addressFloor: request.addressFloor,
        addressIntercom: request.addressIntercom,
        communications: request.communications,
        rejectionComment: request.rejectionComment,
        assigneeCancelCause: request.assigneeCancelCause,
        clientType: request.clientType,
        paymentType: request.paymentType,
        clientPaymentReceived: request.clientPaymentReceived,
        reanimated: request.reanimated,
        projectId: request.projectId,
        additionalPests: getAdditionalPestsIds(request.additionalPests),
        freeInspection: request.freeInspection,
        extraTechnicians: request.extraTechnicians,
      };
      if (request.id !== "new") {
        fields.id = request.id;
      }
      return fields;
    },

    create: function (successCallback, errorCallback) {
      let updateUrl;
      let $this = this;
      updateUrl = "/pest/leads";
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$emit("start-creating");
      this.$http
        .post(updateUrl, this.processRequestFields(this.request))
        .then((response) => {
          this.loading = false;
          if (successCallback) {
            successCallback(response.data);
          }
          // this.request = response.data;
          $this.$emit("end-creating");
          $this.$emit("request-created", response.data);
          // this.$router.push({name: 'pest-lead-list', params: {id: this.request.id}});
          this.$bvToast.toast(this.$t("requests.request_created"), {
            title: this.$t("requests.request_created"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (errorCallback) {
            errorCallback();
          }
          $this.$emit("end-creating");
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("requests.cant_create_request"),
              variant: "danger",
            });
          }
        });
    },
    update: function (successCallback, errorCallback) {
      let updateUrl;
      updateUrl = "/pest/requests/" + this.id;
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$emit("start-creating");
      this.$http
        .put(updateUrl, this.processRequestFields(this.request))
        .then((response) => {
          if (successCallback) {
            successCallback(response.data);
            // } else {
          }
          // this.request = response.data;
          this.loading = false;
          this.$emit("end-creating");
          this.$eventHub.$emit("update-request-info", this.request);
          this.$emit("update-request-info", this.request);
          // this.$router.push({name: 'request-view', params: {id: this.request.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("requests.request_updated"),
            request: "success",
          });
        })
        .catch((error) => {
          if (errorCallback) {
            errorCallback();
          }
          this.$emit("end-creating");
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("requests.cant_update_request"),
              request: "danger",
            });
          }
        });
    },

    validateForm: function () {
      let ok = true;
      this.resetValidation();
      if (this.isNewDeal) {
        ok = this.validateNewDealFields();
      } else {
        if (this.showRejectionComment) {
          ok = this.validateRejectionFields();
        } else if (this.showAssigneeCancelCause) {
          ok = this.validateAssigneeCancelFields();
        }
        let form = document.getElementById("request-form");
        ok = form.checkValidity() && ok;
      }
      this.validation.validated = true;
      return ok;
    },
    resetValidation: function () {
      this.validation.validated = false;
      this.validation.address = null;
      this.validation.pest = null;
      this.validation.town = null;
      this.validation.clientType = null;
      this.validation.dateDay = null;
      this.validation.dateTime = null;
      this.validation.sourceId = null;
      this.validation.projectId = null;
      this.validation.paymentType = null;
    },
    validateRejectionFields: function () {
      this.validation.rejectionComment = !!this.request.rejectionComment;
      return this.validation.rejectionComment;
    },
    validateAssigneeCancelFields: function () {
      this.validation.assigneeCancelCause = !!this.request.assigneeCancelCause;
      return this.validation.assigneeCancelCause;
    },
    validateNewDealFields: function () {
      this.validation.address = !!this.request.address;
      this.validation.pest = !!this.request.pestId;
      this.validation.facility = !!this.request.facilityId;
      this.validation.town = !!this.request.townId;
      this.validation.clientType = !!this.request.clientType;
      this.validation.dateDay = !!this.request.dateDay;
      this.validation.dateTime = !!this.request.dateTime;
      this.validation.projectId = !!this.request.projectId;
      this.validation.sourceId =
        !!this.request.sourceId || !this.showManualSource;
      this.validation.paymentType =
        this.request.clientType === CLIENT_TYPE_IND ||
        !!this.request.paymentType;
      let ok =
        this.validation.projectId !== false &&
        //this.validation.address !== false &&
        this.validation.pest !== false &&
        this.validation.facility !== false &&
        this.validation.sourceId !== false &&
        this.validation.town !== false &&
        //this.validation.clientType !== false &&
        this.validation.dateDay !== false &&
        this.validation.dateTime !== false &&
        this.validation.paymentType !== false;

      if (this.showRejectionComment) {
        ok = this.validateRejectionFields() && ok;
      }

      return ok;
    },
    createOrUpdate: function (success, error) {
      let form = document.getElementById("request-form");
      let valid = this.validateForm();
      if (valid) {
        form.classList.add("was-validated");
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create(success, error);
        } else {
          this.update(success, error);
        }
        form.classList.remove("was-validated");
        this.$eventHub.$emit("update-request-info", this.request);
      } else {
        if (error) {
          error();
        }
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          title: this.$t("common.error"),
          variant: "danger",
        });
        return false;
      }
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },

    // fetch data
    checkStatusList: function () {
      this.statusesList = [];

      if (this.request.stage === STAGE_LEAD) {
        this.statusesList = [...this.leadStatusesList];
        this.statusesList.push({
          text: this.$t("requests_statuses.order_new"),
          value: -STATUS_NEW,
        });

        if (this.request.id === "new") {
          this.request.leadFormStatusId = this.startWorkStatusId;
          this.request.leadStatusId = this.startWorkStatusId;
        }
      } else {
        this.addRequestStages();
      }
    },
    getProjects: function (search, resolve) {
      this.projectsList = [];
      let projectUrl = "pest/project/search";
      this.$http
        .get(projectUrl)
        .then((response) => {
          resolve(response.data);
          this.projectsList = response.data;
        })
        .catch((error) => {
          resolve([]);
        });
    },
    fetchStatuses: function () {
      this.leadStatusesList = [];
      this.loadingStatuses = true;
      // this.statusesList.push({value: '', text: 'Все'});
      let statusesUrl = "/crm/statuses";
      let params = {
        page: this.page,
      };
      this.$http
        .get(statusesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            if (item.closeStatus) {
              this.closeLeadId = item.id;
            }
            this.leadStatusesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
            if (item.startWork) {
              this.startWorkStatusId = item.id;
            }
          }
          this.checkStatusList();
          this.loadingStatuses = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          this.loadingStatuses = false;
          this.processRequestError(error);
        });
    },
    loadPrices: function () {
      if (
        !this.request.townId ||
        !this.request.pestId ||
        !this.request.facilityId ||
        (this.request.clientType === CLIENT_TYPE_ORG &&
          !this.request.paymentType)
      ) {
        return;
      }
      this.loadingPrices = true;
      let pricesUrl = "/pest/prices";
      let params = {
        all: 1,
        townId: this.request.townId,
        pestId: this.request.pestId,
        facilityId: this.request.facilityId,
        clientType: this.request.clientType,
        paymentType: this.request.paymentType,
      };
      this.$http
        .get(pricesUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingPrices = false;
          this.prices = response.data;
          if (this.prices.length === 0) {
            this.prices.push({
              facilityId: null,
              price: null,
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loadingPrices = false;
          this.processRequestError(error);
        });
    },
    dateChanged() {
      if (!this.request.hasPredefinedAssignee) {
        this.request.assignee = null;
        this.request.assigneeId = null;
      }
      this.loadBusiness();
    },
    loadBusiness() {
      let scheduleUrl = "/pest/schedule/busyness";
      this.loadingBusiness = true;
      let params = {
        dateFrom: this.request.dateDay,
        dateTo: this.request.dateDay,
        townId: this.request.townId,
        assigneeId: this.request.assigneeId,
      };
      this.$http
        .get(scheduleUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingBusiness = false;
          this.schedule = response.data;
          let d = Object.keys(this.schedule.dates)[0];
          let slots = this.schedule.dates[d].slots;
          let options = [];
          for (let sl of this.timeHours) {
            let i = sl;
            if (i < 10) {
              i = "0" + i;
            }
            if (sl === 24) {
              i = "00";
            }
            let business = slots[i];
            let option = {
              value: sl,
              text: sl + ":00 (" + (business ? business : 0) + ")",
            };
            if (this.request.assigneeId && business > 0) {
              option.disabled = true;
            }
            options.push(option);
          }
          this.timeOptions = options;
        })
        .catch((error) => {
          this.loadingBusiness = false;
          this.processRequestError(error);
        });
    },

    // form process methods

    searchAssignee(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: {
              term: input,
              type: TYPE_WORKER,
            },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    selectManager(data) {
      if (data && data.value) {
        this.request.manager = data.value;
        this.request.managerId = data.value.id;
      } else {
        this.request.manager = data.value;
        this.request.managerId = data.value.id;
      }
      this.$forceUpdate();
    },
    selectAssignee(data) {
      if (data && data.value) {
        if (
          !confirmationAssigneeWhenInspection(
            this.request.previousNotInspection,
            this.request.previousTechnician,
            data.value
          )
        ) {
          return;
        }

        this.request.assignee = data.value;
        this.request.assigneeId = data.value.id;
      } else {
        this.request.assignee = data.value;
        this.request.assigneeId = data.value.id;
        if (this.request.hasPredefinedAssignee) {
          this.showAssigneeCancelCauseField = true;
          this.timeOptions = timeOptions;
        }
      }
      this.request.hasPredefinedAssignee = false;
      this.$forceUpdate();
    },
    selectSource(data) {
      if (data && data.value) {
        this.request.source = data.value;
        this.request.sourceId = data.value.id;
      } else {
        this.request.source = data.value;
        this.request.sourceId = data.value.id;
      }
      this.$forceUpdate();
    },
    selectClient(data) {
      if (data && data.value) {
        this.client = data.value;
        this.request.clientId = data.value.id;
        this.request.client = data.value;
        this.request.clientName = getClientName(data.value);
        // this.request.phone = getClientName(data.value)
      } else {
        this.client = {};
        this.request.clientId = null;
      }
      this.$forceUpdate();
    },
    selectPest(data) {
      if (data && data.value) {
        this.request.pest = data.value;
        this.request.pestId = data.value.id;
      } else {
        this.request.pest = {};
        this.request.pestId = null;
      }
      this.$forceUpdate();
      this.loadPrices();
    },
    selectAdditionalPest(data, indexElement) {
      if (data && data.value) {
        this.request.additionalPests[indexElement].pest = data.value;
        this.request.additionalPests[indexElement].pestId = data.value.id;
      } else {
        this.request.additionalPests[indexElement].pest = t("common.not_set");
        this.request.additionalPests[indexElement].pestId = undefined;
      }
      this.$forceUpdate();
      //this.loadPrices();
    },
    selectExtraTechnician(data, indexElement) {
      if (data && data.value) {
        this.request.extraTechnicians[indexElement] = data.value;
      } else {
        this.request.extraTechnicians[indexElement].id = undefined;
      }
      this.$forceUpdate();
      //this.loadPrices();
    },
    selectFacility(data) {
      if (data && data.value) {
        this.request.facility = data.value;
        this.request.facilityId = data.value.id;
      } else {
        this.request.facility = {};
        this.request.facilityId = null;
      }
      this.$forceUpdate();
      this.loadPrices();
    },
    selectTown(data) {
      if (data && data.value) {
        this.request.town = data.value;
        this.request.townId = data.value.id;
      } else {
        this.request.town = {};
        this.request.townId = null;
      }
      this.selectRegion();
      this.loadPrices();
      this.$forceUpdate();
    },
    selectRegion(data) {
      if (data && data.value) {
        this.request.region = data.value;
        this.request.regionId = data.value.id;
      } else {
        this.request.region = {};
        this.request.regionId = null;
      }
      this.$forceUpdate();
    },
    getTownClass(town) {
      if (this.request.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientType(type) {
      this.request.clientType = type;
      this.$forceUpdate();
      this.loadPrices();
    },
    getClientTypeClass(type) {
      if (this.request.clientType === type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientPaymentType(type) {
      this.request.paymentType = type;
      this.loadPrices();
    },
    selectClientPaymentReceived(status) {
      this.request.clientPaymentReceived = status;
    },
    getClientExchangeSourceType(value) {
      if (
        (value && this.request.fromExchange) ||
        (!value && !this.request.fromExchange)
      ) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectExchangeSourceType(value) {
      this.request.fromExchange = value;
    },
    getPriceClass(price) {
      if (this.priceManual) {
        if (price === "-") {
          return "btn-primary";
        } else {
          return "btn-outline-primary";
        }
      }
      if (this.request.knownSum === price) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectPrice(sum) {
      if (sum === "-") {
        this.priceManual = true;
      } else {
        this.request.knownSum = sum;
        this.priceManual = false;
      }
      this.$forceUpdate();
    },
    getClientPaymentTypeClass(type) {
      if (this.request.paymentType === type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },

    getClientPaymentReceivedClass(status) {
      if (this.request.clientPaymentReceived == status) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },

    selectAddress(info) {
      if (!info) {
        return;
      }
      let data = info.value.data;
      let fullAreaName = [];
      if (data.region_with_type) {
        fullAreaName.push(data.region_with_type);
      }
      if (data.area_with_type) {
        fullAreaName.push(data.area_with_type);
      }
      if (data.city_district_with_type) {
        fullAreaName.push(data.city_district_with_type);
      }
      if (data.city_with_type && data.city_with_type != data.region_with_type) {
        fullAreaName.push(data.city_with_type);
      }
      if (data.settlement_with_type) {
        fullAreaName.push(data.settlement_with_type);
      }
      this.request.address = [
        data.city_with_type,
        data.settlement_with_type,
        data.street_with_type,
        data.house_type,
        data.house,
        data.block_type,
        data.block,
      ]
        .filter(Boolean)
        .join(", ");
      // data.city_with_type
      this.request.addressIndex = data.postal_code;
      this.request.addressTown = fullAreaName.join(", ");
      this.request.address = data.street_with_type;
      this.request.addressHouse = data.house;
      this.request.addressBlock = data.block;
      this.request.addressEntrance = this.request.addressEntrance
        ? this.request.addressEntrance
        : data.entrance;
      this.request.appartment = data.flat ? data.flat : this.request.appartment;
      this.request.addressFloor = data.floor
        ? data.floor
        : this.request.addressFloor;

      this.request.addressCoords = JSON.stringify({
        lat: data.geo_lat,
        lng: data.geo_lon,
      });
    },
    setLeadStatus() {
      if (this.request.stage === STAGE_DEAL) {
        if (this.request.status === STATUS_DECLINE) {
          this.request.leadFormStatusId = -STATUS_DECLINE_LEAD_BOARD;
        } else {
          this.request.leadFormStatusId = -this.request.status;
        }
      } else {
        this.request.leadFormStatusId = this.request.leadStatusId
          ? this.request.leadStatusId
          : null;
      }
      this.$forceUpdate();
    },
    updateLeadStatus() {
      if (this.request.leadFormStatusId < 0) {
        this.request.stage = STAGE_DEAL;
        if (this.request.leadFormStatusId === -STATUS_DECLINE_LEAD_BOARD) {
          //константа совпадает
          this.request.status = STATUS_DECLINE;
        } else {
          this.request.status = -this.request.leadFormStatusId;
        }
      } else {
        this.request.stage = STAGE_LEAD;
        this.request.leadStatusId = this.request.leadFormStatusId;
      }
    },

    // some logic

    addRequestStages() {
      this.statusesList.push({
        text: this.$t("requests_statuses.order_new"),
        value: -STATUS_NEW,
      });
      this.statusesList.push({
        text: this.$t("requests_statuses.order_declined"),
        value: -STATUS_DECLINE_LEAD_BOARD,
      });
      this.statusesList.push({
        text: this.$t("requests_statuses.order_done"),
        value: -STATUS_DONE,
        disabled: true,
      });
    },
    distanceCalculated(info) {
      this.request.tripDistance = Math.round(info.distance / 1000);
      this.request.tripTime = info.time;
    },
    sendSms() {
      let $this = this;
      if (this.sendingSms) {
        return;
      }
      if (!this.sendSmsPhone) {
        $this.showErrorSmsMessage = true;
        $this.errorSmsMessage = "Заполните телефон";
        setTimeout(function () {
          $this.showErrorSmsMessage = false;
        }, 2000);
        return;
      }
      if (!this.sendSmsPhone) {
        $this.showErrorSmsMessage = true;
        $this.errorSmsMessage = this.$t("messages.enter_text");

        setTimeout(function () {
          $this.showErrorSmsMessage = false;
        }, 2000);
        return;
      }

      this.sendingSms = true;

      let updateUrl;
      updateUrl = "/sms/message/send";
      this.loading = true;
      this.$http
        .put(updateUrl, {
          phone: this.sendSmsPhone,
          text: this.sendSmsText,
        })
        .then((response) => {
          $this.sendingSms = false;
          this.$emit("update-request-info", this.request);
          $this.$toast.open({
            message: this.$t("messages.message_sent"),
            type: "success",
          });
          $this.showSuccessSmsMessage = true;
          setTimeout(function () {
            $this.showSuccessSmsMessage = false;
          }, 2000);
        })
        .catch((error) => {
          $this.sendingSms = false;
          $this.showErrorSmsMessage = true;
          $this.errorSmsMessage = this.$t("messages.message_is_not_sent");

          setTimeout(function () {
            $this.showErrorSmsMessage = false;
          }, 2000);
          if (!this.processRequestError(error)) {
            $this.$toast.open({
              message: this.$t("messages.message_is_not_sent"),
              type: "success",
            });
          }
        });
    },

    // communications methods

    selectClientForCommunication({ value }, communication) {
      if (value.new) {
        let $this = this;
        this.$eventHub.$emit("modal-client-popup", {
          client: {
            requestId: this.request.id,
            contractId: this.request.contractId,
            phones: [
              {
                phone: "",
                type: "mobile",
              },
            ],
          },
          success: function ({ client }) {
            communication.clientName = getClientName(client);
            communication.clientId = client.id;
            communication.phones = [];
            for (let phone of client.phones) {
              communication.phones.push(phone.phone);
            }
            $this.$forceUpdate();
          },
        });
      } else {
        communication.clientName = getClientName(value);
        communication.clientId = value.id;
      }
    },
    getClientsForRequest(search, resolve) {
      let newRecord = { new: true, firstName: this.$t("common.add_new") };
      if (this.request.contractId) {
        let clientsUrl = "/crm/clients";
        let params = {
          contractId: this.request.contractId,
        };
        this.$http
          .get(clientsUrl, { params: params })
          .then((response) => {
            let clients = response.data;
            clients.push(newRecord);
            resolve(clients);
          })
          .catch((error) => {
            resolve([]);
          });
      } else {
        resolve([newRecord]);
      }
    },
    getClientPhoneFromContact(item) {
      return item.phone;
    },
    getClientCardPhones(clientId) {
      return (search, resolve) => {
        if (!clientId) {
          resolve([]);
          return;
        }
        let clientsUrl = "/crm/clients/" + clientId;
        this.$http
          .get(clientsUrl)
          .then((response) => {
            let phones = response.data.phones;
            phones.push({ new: true, phone: "" });
            resolve(phones);
          })
          .catch((error) => {
            resolve([]);
          });
      };
    },
    selectPhoneForCommunication({ value }, clientId, communication, index) {
      if (value.new) {
        let $this = this;
        this.$eventHub.$emit("modal-contract-phone-popup", {
          clientId: clientId,
          success: function ({ phone }) {
            communication.phones[index] = phone;
            $this.$forceUpdate();
          },
        });
      } else {
        communication.phones[index] = value.phone;
      }
    },
    addPhoneToCommunication(communication) {
      communication.phones.push("");
    },
    deletePhoneFromCommunication(communication, phoneIndex) {
      if (!this.request.contractId) {
        return;
      }
      communication.phones.splice(phoneIndex, 1);
    },
    deleteCommunication(index) {
      this.request.communications.splice(index, 1);
    },
    addCommunication() {
      if (!this.request.communications) {
        this.request.communications = [];
      }
      this.request.communications.push({
        clientId: null,
        clientName: "",
        blocked: false,
        phones: [""],
        hideButtons: true,
      });
    },
    getRejectCauses(search, resolve) {
      let itemsUrl = "/pest/reject-causes";
      this.$http
        .get(itemsUrl)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectRejectCauses({ value }) {
      if (value.id) {
        this.request.rejectionCause = value;
        this.request.rejectionCauseId = value.id;
        this.$forceUpdate();
      } else {
        this.request.rejectionCause = {};
        this.request.rejectionCauseId = null;
      }
    },
    createReturnVisit(request) {
      this.$emit("add-new-request", request);
    },

    makeCallInternal(communication, phone) {
      if (this.calling) {
        return;
      }
      let callUrl = "/pest/request/manager-call/" + this.request.id;
      this.calling = true;
      this.$http
        .get(callUrl, {
          params: {
            phone: phone,
            clientId: communication.clientId,
          },
        })
        .then((response) => {
          this.$bvToast.toast(this.$t("calls.calling"), {
            title: this.$t("calls.please_wait"),
            variant: "success",
          });
          this.calling = false;
          // if (successCallback) {
          //   successCallback();
          // }
        })
        .catch((error) => {
          let message = this.$t("calls.cant_call_msg");
          if (error.response.data.error) {
            message = error.response.data.error;
          }
          this.$bvToast.toast(message, {
            title: this.$t("common.error"),
            variant: "danger",
          });
          this.calling = false;
          Sentry.captureException(error);
        });
    },
    /**
     * получение расписания мастера
     */
    fetchAssigneeCalendar() {
      if (!this.request.hasPredefinedAssignee) {
        return;
      }
      let url = "/pest/schedule/user";
      this.$http
        .get(url, {
          params: {
            userId: this.request.assigneeId,
            dateFrom: dayjs().format("DD.MM.YYYY"),
            dateTo: dayjs().add(1, "month").format("DD.MM.YYYY"),
          },
        })
        .then((response) => {
          let schedule = response.data.dates;
          let dates = [];
          for (let day in schedule) {
            if (schedule[day].active) {
              dates.push(day);
            }
          }
          this.assigneeSchedule = dates;
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    /**
     * отображение возможных дат у мастера в календаре
     * @param date
     * @returns {boolean}
     */
    disabledAssigneeDates(date) {
      if (
        !this.request.hasPredefinedAssignee ||
        this.assigneeSchedule === null
      ) {
        return false;
      }
      return !this.assigneeSchedule.includes(dayjs(date).format("YYYY-MM-DD"));
    },
    addAdditionalPest() {
      this.request.additionalPests.push({});
    },
    addExtraTechnician() {
      this.request.extraTechnicians.push({});
    },
  }, // methods
};
</script>

<style lang="scss" scoped>
.contact-card {
  border: 1px solid #e5e6e7;
  width: 340px;
  min-width: 250px;
}

a.hover-link {
  color: $inspinia-text;
}

a.hover-link:hover {
  color: $link-color;
}

.ibox-title {
  height: 74px;
}

/**/

.pb-16 {
  padding-bottom: 16px;
}

.addPestButton {
  height: 35.56px;
}

@media (max-width: 640px) {
  .ibox-title {
    height: auto;
  }
  .phones {
    display: block !important;
  }
  .phones button {
    margin-top: 0.5rem;
  }
  .toptitle {
    display: block !important;
  }
}

.contact-text-input {
  width: 200px;
}

.form-checkboxes {
  display: flex;
  min-height: 33.5px;
  align-items: center;
}
</style>

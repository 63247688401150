<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <div class="d-flex align-items-center" style="overflow-x: auto">
          <div class="mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
          </div>

          <div class="mr-2">
            <date-picker
              v-model="search.date"
              @change="fetchData"
            ></date-picker>
          </div>

          <div class="align-self-center mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;{{ $t("search.find") }}
            </b-button>
          </div>
          <!--@click="fetchManagers"-->
          <button
            v-if="!assigneeLoading"
            class="mr-2 btn btn-success btn-sm disabled"
          >
            <font-awesome-icon icon="check" />
            Менеджеры загружены
          </button>
          <button v-else class="mr-2 btn btn-success btn-sm disabled">
            <font-awesome-icon icon="rotate" spin />
            Менеджеры загружаются
          </button>
          <!--@click="fetchRequests"-->
          <button
            v-if="!requestsLoading"
            class="mr-2 btn btn-success btn-sm disabled"
          >
            <font-awesome-icon icon="check" />
            Заявки загружены
          </button>
          <button v-else class="mr-2 btn btn-success btn-sm disabled">
            <font-awesome-icon icon="rotate" spin />
            Заявки загружаются
          </button>

          <div v-if="false">
            <button
              v-if="!assigneeLoading && !requestsLoading"
              class="mr-2 btn btn-success btn-sm"
              @click.prevent="addRequestMarkers"
            >
              <font-awesome-icon icon="check" />
              Разместить точки
            </button>
            <button
              v-else
              class="mr-2 btn btn-success btn-sm disabled"
              disabled
            >
              <font-awesome-icon icon="rotate" spin />
              подождите
            </button>
          </div>

          <button
            v-if="!assigneeLoading && !requestsLoading"
            class="mr-2 btn btn-success btn-sm"
            @click.prevent="addRequestPaths"
          >
            <font-awesome-icon icon="check" />
            Разместить маршруты
          </button>
          <button v-else class="mr-2 btn btn-success btn-sm disabled" disabled>
            <font-awesome-icon icon="rotate" spin />
            подождите
          </button>
        </div>
      </div>
    </div>

    <div class="ibox-content gray-bg pl-0 pr-0 d-flex">
      <div class="cards pr-2">
        <h2>заявки</h2>
        <b-form-select
          v-model="selectedAssignee"
          :options="assigneesOptions"
          @change="toggleMapItems"
        ></b-form-select>
        <request-map-card
          v-for="(request, rIndex) in filteredRequests"
          :key="rIndex"
          :request="request"
        />
      </div>

      <div id="map" style=""></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import {
  getAssigneeName,
  getAssigneeNumber,
  getUserName,
} from "@/modules/crm/common.js";
import { TYPE_WORKER } from "@/modules/users/decorators/users.js";
import RequestMapCard from "@/modules/pest/views/requests/map/RequestMapCard.vue";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import {
  getRequestDatetime,
  getRequestStatus,
} from "@/modules/pest/decorators/requests.js";
import DatePicker from "@/components/shared/DatePicker.vue";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns.js";
import { faMapMarker } from "@beaversoft/pro-solid-svg-icons";
import loadjs from "loadjs";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "RequestList",
  components: {
    TownFilter,
    RequestMapCard,
    DatePicker,
  },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      secondTown: {
        [MSK]: SPB,
        [SPB]: MSK,
      },
      allRequestsByTowns: {
        [MSK]: [],
        [SPB]: [],
      },
      processedRequests: {
        [MSK]: [],
        [SPB]: [],
      },
      assigneeListByTown: {
        [MSK]: [],
        [SPB]: [],
      },
      map: false,
      geocoder: false,
      selectedAssignee: -1,
      directionsService: false,
      search: {
        title: "",
        date: "",
        townId: SPB,
        managerId: "",
      },
      assigneeLoading: false,
      requestsLoading: false,
      townList: [],
      assigneeList: [],
      loading: true,
      archived: false,
      coords: [59.939095, 30.315868],
      dateFormat: "DD.MM.YYYY",
      users: [],
      icons: [],
      stopGmapsRequest: false,
      retriesCount: 5,
      timeout: 500,
      timeoutDirection: 500,
      timeoutInterval: 2000,
      timeoutDirectionInterval: 1000,
    };
  },
  computed: {
    gmapsKey() {
      return import.meta.env.VITE_GOOGLE_MAP_EMBED_KEY;
    },
    getMapUrl() {
      return (
        "https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=&v=weekly&key=" +
        this.gmapsKey
      );
    },
    assigneesOptions() {
      let list = [];
      list.push({ value: -1, text: this.$t("common.all") });
      list.push({ value: 0, text: "Не прикрепленные" });
      for (let assignee of this.assigneeListByTown[this.search.townId]) {
        list.push({ value: assignee.id, text: getAssigneeName(assignee) });
      }
      return list;
    },
    filteredRequests() {
      if (this.selectedAssignee === -1) {
        return this.allRequestsByTowns[this.search.townId];
      }
      for (let info of this.processedRequests[this.search.townId]) {
        if (info.assignee.id === this.selectedAssignee) {
          return info.items;
        }
      }
      return [];
      // return this.requests;
    },
  },
  beforeDestroy() {
    loadjs.reset();
  },
  mounted: function () {
    this.fetchData();
  },
  created() {
    window.initMap = this.mapCallback;
    this.search.date = "24.09.2021"; // dayjs().format('DD.MM.YYYY');
    loadjs.reset();
    loadjs([this.getMapUrl], "gmaps");
    this.fetchTowns();
  },
  methods: {
    mapCallback() {
      this.showMap();
    },
    showMap() {
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: this.getTownCoords(this.search.townId),
        zoom: 8,
      });
      this.geocoder = new window.google.maps.Geocoder();
      this.directionsService = new window.google.maps.DirectionsService();
    },

    getGoogleLatLng(str) {
      if (!str) {
        return false;
      }
      if (typeof str === "string") {
        let coords = JSON.parse(str);
        return new window.google.maps.LatLng(coords.lat, coords.lng);
      }
      if (typeof str === "object") {
        return new window.google.maps.LatLng(str.lat, str.lng);
      }
      return false;
    },

    // Store snapped polyline returned by the snap-to-road service.
    processSnapToRoadResponse(data, info) {
      info.snappedCoordinates = [];
      info.placeIdArray = [];
      for (let i = 0; i < data.snappedPoints.length; i++) {
        let latlng = new window.google.maps.LatLng(
          data.snappedPoints[i].location.latitude,
          data.snappedPoints[i].location.longitude
        );
        info.snappedCoordinates.push(latlng);
        info.placeIdArray.push(data.snappedPoints[i].placeId);
      }
    },

    // Draws the snapped polyline (after processing snap-to-road response).
    drawSnappedPolyline(info) {
      let snappedPolyline = new window.google.maps.Polyline({
        path: info.snappedCoordinates,
        strokeColor: info.color,
        strokeWeight: 4,
        strokeOpacity: 1,
      });
      snappedPolyline.setMap(this.map);
      info.polyline = snappedPolyline;
    },

    showDirection(path, info, refresh) {
      let origin = path.shift();
      let destination = path.pop();
      if (info.directionsRoute && !refresh) {
        info.directionsRenderer.setDirections(info.directionsRoute);
        return;
      }
      this.directionsService
        .route({
          origin: origin.location,
          destination: destination.location,
          waypoints: path,
          optimizeWaypoints: false,
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          info.directionsRoute = response;
          info.directionsRenderer.setDirections(response);
        })
        .catch((e) =>
          console.log(["Directions request failed due to " + status, e])
        );
    },
    // Snap a user-created polyline to roads and draw the snapped path
    runSnapToRoad(path, info) {
      // let pathValues = [];
      // for (let i = 0; i < path.getLength(); i++) {
      //   pathValues.push(path.getAt(i).toUrlValue());
      // }
      console.log("start snap");
      axios
        .get("https://roads.googleapis.com/v1/snapToRoads", {
          params: {
            interpolate: true,
            key: this.gmapsKey,
            path: path.join("|"),
          },
        })
        .then((response) => {
          if (response.data) {
            this.processSnapToRoadResponse(response.data, info);
            this.drawSnappedPolyline(info);
          }
        });
    },

    removeRequestMarkers(town) {
      if (!town) {
        town = this.search.townId;
      }
      for (let info of this.processedRequests[town]) {
        for (let request of info.items) {
          if (request.marker) {
            request.marker.setMap(null);
          }
        }
      }
    },
    addRequestMarkers(town) {
      this.removeRequestMarkers(this.secondTown[town]);
      console.log("start placing markers");
      this.stopGmapsRequest = false;
      if (!town) {
        town = this.search.townId;
      }
      for (let info of this.processedRequests[town]) {
        // if (info.assignee.id === this.selectedAssignee) {
        this.addRequestMarkersForAssignee(info);
        // }
      }
    },

    addRequestPaths() {
      let town = this.search.townId;
      console.log("start placing paths");
      this.stopGmapsRequest = false;
      this.timeoutDirection = 500;
      for (let info of this.processedRequests[town]) {
        // if (info.assignee.id === 9) {// this.selectedAssignee){
        if (info.assignee && info.assignee.id && info.items.length) {
          this.addRequestPathsForAssignee(info);
        }
        // }
      }
    },
    getRequestIcon(deal, color) {
      return {
        path: faMapMarker.icon[4],
        fillColor: color ? color : "#ffffff",
        fillOpacity: 1,
        anchor: new window.google.maps.Point(
          faMapMarker.icon[0] / 2, // width
          faMapMarker.icon[1] // height
        ),
        strokeWeight: 1,
        strokeColor: "#ffffff",
        scale: 0.09,
        labelOrigin: new window.google.maps.Point(190, 170),
      };
    },

    addRequestMarkersForAssignee(info) {
      let $this = this;
      let requests = info.items;
      this.timeout = 500;
      for (let request of requests) {
        if (request.marker) {
          continue;
        }
        if (request.addressCoords) {
          // console.log('has coords');
          $this.addRequestMarkerWithTitle(request, info);
        } else {
          this.timeout += this.timeoutInterval;
          console.log("set timeout " + this.timeout);
          setTimeout(function () {
            if ($this.stopGmapsRequest) {
              return;
            }
            let address = request.town.title + ", " + request.address;
            console.log([
              "will geocode address",
              address,
              request.id,
              request.externalId,
              request,
            ]);
            // $this.geocodeAddress(address)
            //     .then(coords => {
            //       request.addressCoords = coords;
            //       console.log(coords);
            //       $this.addRequestMarkerWithTitle(request)
            //       $this.saveCoords(request);
            //     })
            //     .catch(() => console.log(['can\'t geocode address', address, request.addressCoords, request]));
          }, 0);
        }
      }
    },
    toggleMapItems() {
      // for
      for (let info of this.processedRequests[this.search.townId]) {
        let map = this.map;
        if (
          this.selectedAssignee !== -1 &&
          info.assignee.id !== this.selectedAssignee
        ) {
          map = null;
        }

        // direction routes
        if (info.directionsRenderer) {
          info.directionsRenderer.setMap(map);
        }

        // requests
        for (let request of info.items) {
          if (request.marker) {
            request.marker.setMap(map);
          }
        }
      }
    },
    addRequestPathsForAssignee(info) {
      let $this = this;
      let requests = info.items.sort((a, b) => {
        (a, b) => a.time - b.time;
      });
      // console.log(requests);
      let path = [];

      for (let request of requests) {
        let location = this.getGoogleLatLng(request.addressCoords);
        if (!location) {
          continue;
        }
        // path.push(request.addressCoords);
        path.push({
          location: location,
          stopover: true,
        });
      }
      if (path.length >= 2) {
        // если есть откуда и куда
        this.timeoutDirection += this.timeoutDirectionInterval;
        setTimeout(function () {
          $this.showDirection(path, info, true);
          //     // $this.runSnapToRoad(path, info);
        }, this.timeoutDirection);
      }
    },
    filterAddress(addr) {
      return addr ? addr.split("|")[0] : "";
    },
    saveCoords(request) {
      let usersUrl = "/pest/request/rcoords/" + request.id;
      let coords = request.addressCoords;
      this.$http
        .post(usersUrl, {
          coords: coords,
        })
        .then((response) => {
          // console.log(['coords saved', request]);
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    getInfoContent(request) {
      let info = [
        "Номер: " + request.externalId,
        "Адрес: " + this.filterAddress(request.address),
        "Исполнитель: " + request.assigneeId
          ? getAssigneeName(request.assignee)
          : "Без исполнителя",
        "Дата и время: " + getRequestDatetime(request),
        "Статус: " + getRequestStatus(request),
        "Объект: " + getFacilityTitle(request.facility),
        "Вредитель: " + getPestTitle(request.pest),
      ];
      return info.join("<br>\n");
    },
    addRequestMarkerWithTitle(request, info) {
      if (!request.addressCoords) {
        return;
      }
      const map = this.map;
      const address = this.filterAddress(request.address);
      let location = request.addressCoords;
      if (typeof location === "string") {
        let coords = JSON.parse(location);
        location = new window.google.maps.LatLng(coords.lat, coords.lng);
      }
      // console.log([request.id, request.address, location]);
      let label;
      if (!request.assigneeId) {
        label = "?";
      } else {
        label = getAssigneeNumber(request.assignee);
      }
      const marker = new window.google.maps.Marker({
        map: map,
        icon: this.getRequestIcon(request, info.color),
        position: location,
        label: label,
      });
      request.marker = marker;
      const infoWindow = new window.google.maps.InfoWindow({
        content: this.getInfoContent(request),
      });
      marker.addListener("mouseover", function () {
        infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });
      marker.addListener("mouseout", function () {
        infoWindow.close();
      });

      // marker.addListener("click", () => {
      //   infoWindow.open({
      //     anchor: marker,
      //     map,
      //     shouldFocus: false,
      //   });
      // });
    },
    geocodeAddress(addr) {
      let $this = this;
      let address = this.filterAddress(addr);
      console.log(["starting geocoding", addr]);
      // return this.geocoder.geocode({address: address});
      return new Promise((resolve, reject) => {
        this.geocoder
          .geocode({ address: address })
          .then(({ results }) => {
            // console.log(results);
            resolve(results[0].geometry.location);
          })
          .catch((e) => {
            reject();
            $this.stopGmapsRequest = true;
            console.log(
              "Geocode was not successful for the following reason: " + e
            );
            if ($this.retriesCount) {
              $this.retriesCount--;
              $this.addRequestMarkers();
            }
          });
      });
    },
    addUserMarker(user) {
      let map = this.map;
      let label = getAssigneeNumber(user);
      const marker = new window.google.maps.Marker({
        position: this.getUserCoord(user),
        icon: "https://maps.google.com/mapfiles/ms/micons/blue.png",
        map,
        label: label,
      });
      const infowindow = new window.google.maps.InfoWindow({
        content: getUserName(user),
      });
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });
    },
    addRequestMarker(request, user) {
      let address = request.address;
      this.geocodeAddress(address); //.then();
    },

    fetchManagers: function () {
      if (this.assigneeLoading) {
        return;
      }
      this.assigneeLoading = true;
      this.assigneeListByTown[this.search.townId] = [];
      let usersUrl = "/pest/schedule/assignees";
      let params = {
        townId: this.search.townId,
        date: this.search.date,
        type: TYPE_WORKER,
        // limit: 5,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          this.assigneeLoading = false;
          this.assigneeListByTown[this.search.townId] = response.data;
          this.processManagerList(this.search.townId);
        })
        .catch((error) => {
          this.assigneeLoading = false;
          this.processRequestError(error);
        });
    },
    processManagerList(town) {
      this.processedRequests[town] = [];
      let map = this.map;
      let color = "#" + Math.floor(Math.random() * 16777215).toString(16);
      let polylineOptionsActual = new window.google.maps.Polyline({
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      let directionsRenderer = new window.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        preserveViewport: true,
        polylineOptions: polylineOptionsActual,
      });
      if (this.selectedAssignee != -1 && this.selectedAssignee !== 0) {
        map = null;
      }
      directionsRenderer.setMap(map);
      this.processedRequests[town].push({
        assignee: { id: 0 },
        items: [],
        polylines: [],
        snappedCoordinates: [],
        placeIdArray: [],
        directionsRoute: false,
        polyline: false,
        color: color,
        directionsRenderer: directionsRenderer,
      });

      for (let assignee of this.assigneeListByTown[town]) {
        map = this.map;
        if (
          this.selectedAssignee != -1 &&
          assignee.id !== this.selectedAssignee
        ) {
          map = null;
        }
        let id = assignee.id ? assignee.id : 0;
        let color = "#" + Math.floor(Math.random() * 16777215).toString(16);
        let polylineOptionsActual = {
          strokeColor: color,
          strokeOpacity: 1.0,
          strokeWeight: 5,
        };
        let directionsRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          preserveViewport: true,
          polylineOptions: polylineOptionsActual,
        });
        directionsRenderer.setMap(this.map);
        this.processedRequests[town].push({
          assignee: assignee,
          items: [],
          polylines: [],
          snappedCoordinates: [],
          placeIdArray: [],
          polyline: false,
          color: color,
          directionsRenderer: directionsRenderer,
        });
      }
    },
    filterRequests(town) {
      let i = 0;
      let count = 0;
      console.log("filtering by managers");
      for (let info of this.processedRequests[town]) {
        let groupAssigneeId = info.assignee.id;
        info.items = this.allRequestsByTowns[town]
          .filter((request) => {
            let requestAssigneeId = request.assigneeId ? request.assigneeId : 0;
            let sameAssignee = requestAssigneeId === groupAssigneeId;
            let filterCheck = true;
            if (sameAssignee && filterCheck) {
              count++;
            }
            return sameAssignee && filterCheck;
          })
          .sort((a, b) => a.time - b.time);
        this.filteredCount = count;
      }
    },
    fetchRequests: function () {
      if (this.requestsLoading) {
        return;
      }
      this.requestsLoading = true;
      this.loading = true;
      let requestsUrl = "/pest/requests";
      let params = {
        searchNames: this.search.title,
        townId: this.search.townId,
        date: this.search.date,
        all: 1,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          //clear
          // this.requests = response.data;
          this.removeRequestMarkers(this.search.townId);
          this.allRequestsByTowns[this.search.townId] = response.data;
          this.filterRequests(this.search.townId);
          this.addRequestMarkers(this.search.townId);
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          this.loading = false;
          this.requestsLoading = false;
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.requestsLoading = false;
          this.processRequestError(error);
        });
    },
    fetchTowns: function () {
      this.townList = [];
      this.townList.push({ value: "", text: this.$t("common.all") });
      let townUrl = "/pest/towns";
      let params = {};
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.townList.push({ value: item.id, text: item.title });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    getTownCoords(townId) {
      //todo index
      if (townId === 1) {
        return { lat: 55.753237, lng: 37.62252 };
      } else if (townId === 2) {
        return { lat: 59.939095, lng: 30.315868 };
      } else {
        return { lat: 55.753237, lng: 37.62252 };
      }
    },
    fetchData: function () {
      this.fetchUserData();
      this.fetchManagers();
      this.fetchRequests();
      // получение координат города
      // this.coords = this.getTownCoords(townId);
      // this.marker = '<ymap-marker marker-type="placemark" markerId="1" :coords="[54.7, 39.7]" hint-content="Hint content 1" :balloon="{header: "header", body: "body", footer: "footer"}" ></ymap-marker>';
    },
    fetchUserData: function () {
      this.users = [{}];
      this.loading = true;

      let usersUrl = "/users/admin/";
      let params = {
        "per-page": this.pagination ? this.pagination.count : 50,
        withCoords: true,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.users = response.data;
          console.log("loaded");
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },

    getName(user) {
      return getUserName(user);
    },

    getLinkToUser(user) {
      return (
        `
        <h1 class="red">` +
        this.getName(user) +
        `</h1>
        <p>Телефон: ` +
        user.phone +
        `</p>
         <a href="users/` +
        user.id +
        ` " class="">
                  ссылка
         </a>
      `
      );
    },

    getUserCoord(user) {
      let coords = JSON.parse(user.coordsJ);
      if (coords) {
        let lat = coords.lat;
        let lng = coords.lng;
        return { lat: lat, lng: lng };
      }
      return [];
    },

    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
      this.map.setCenter(this.getTownCoords(this.search.townId));
    },
  },
};
</script>

<style lang="scss" scoped>
.cards {
  min-width: 250px;
  width: auto;
  max-height: 700px;
  overflow-y: scroll;
}

#map {
  position: relative;
  overflow: hidden;
  display: block;
  height: 700px;
  width: 100%;
}
</style>

import LogsList from "@/modules/pest/views/logs/LogsList.vue";

export default [
  {
    path: "/history/all/list:page?",
    name: "request-log-all-list",
    components: {
      default: LogsList,
    },
  },
];

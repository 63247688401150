<template>
  <div>{{ $t("users.user") }}: {{ getUserName(log.targetUser) }}</div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "UserChangePassword",
  components: {},
  props: ["log"],
  data() {
    return {
      show: false,
      employee: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {},
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>

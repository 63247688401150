<template>
  <div class="ibox mb-1">
    <div class="ibox-content">
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <h2>{{ $t("settings.employees") }}</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'users-user-list' }" class="">
                {{ $t("users.users") }}
                <span class="hint">{{ $t("users.user_list") }}</span>
              </router-link>
            </li>
            <li v-if="false">
              <router-link :to="{ name: 'workgroup-list' }" class="">
                Группы пользователей
                <span class="hint"
                  >Группы используются при распределении дел</span
                >
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'pest-assignee-day-schedule' }"
                class=""
              >
                {{ $t("menu.logistics_daily_schedule") }}
                <span class="hint">
                  {{ $t("menu.logistics_assignee_daily_schedule") }}</span
                >
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-assignee-schedule' }" class="">
                {{ $t("menu.logistics_weekly_schedule") }}
                <span class="hint">{{
                  $t("menu.logistics_assignee_weekly_schedule")
                }}</span>
              </router-link>
            </li>
          </ul>

          <h2>{{ $t("settings.requests") }}</h2>

          <ul>
            <li v-if="canSeeRequestTableList">
              <router-link :to="{ name: 'pest-request-list' }" class="">
                {{ $t("settings.requests") }}
                <span class="hint"> {{ $t("settings.requests_msg") }}</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'pest-settings' }" class="">
                {{ $t("settings.request_settings") }}
                <span class="hint">{{
                  $t("settings.request_settings_desc")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'crm-status-list' }" class="">
                {{ $t("statuses.statuses") }}
                <span class="hint">{{ $t("statuses.status_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                v-if="false"
                :to="{ name: 'pest-price-list' }"
                class=""
              >
                {{ $t("settings.price_suggestions") }}
                <span class="hint">{{
                  $t("settings.price_suggestions_desc")
                }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-3">
          <h2>{{ $t("settings.requests_indices") }}</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'pest-project-list' }" class="">
                {{ $t("projects.projects") }}
                <span class="hint"> {{ $t("projects.project_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-town-list' }" class="">
                {{ $t("towns.towns") }}
                <span class="hint">{{ $t("towns.town_list") }}</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'pest-region-list' }" class="">
                {{ $t("regions.regions") }}
                <span class="hint">{{ $t("regions.region_list") }}</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'pest-place-list' }" class="">
                {{ $t("places.places") }}
                <span class="hint">{{ $t("places.place_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-service-list' }" class="">
                {{ $t("services.services") }}
                <span class="hint">{{ $t("services.service_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-product-list' }" class="">
                {{ $t("products.products") }}
                <span class="hint">{{ $t("products.product_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-resource-list' }" class="">
                {{ $t("resources.resources") }}
                <span class="hint">{{ $t("resources.resource_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-equipment-list' }" class="">
                {{ $t("equipment.equipment") }}
                <span class="hint">{{
                  $t("equipment.equipment_settings_hint")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-facility-list' }" class="">
                {{ $t("facilities.facilities") }}
                <span class="hint">{{ $t("facilities.facility_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-pest-list' }" class="">
                {{ $t("pests.pests") }}
                <span class="hint">{{ $t("pests.pest_list") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-call-cause-list' }" class="">
                {{ $t("callCauses.callCauses") }}
                <span class="hint">{{ $t("callCauses.setting_hint") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-reject-cause-list' }" class="">
                {{ $t("rejectCauses.rejectCauses") }}
                <span class="hint">{{ $t("rejectCauses.setting_hint") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-source-list' }" class="">
                {{ $t("sources.sources") }}
                <span class="hint">{{ $t("sources.settings_hint") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-aftercall-fail-list' }" class="">
                {{ $t("aftercallFails.aftercallFails") }}
                <span class="hint">{{
                  $t("aftercallFails.setting_hint")
                }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div v-if="true" class="col-sm-12 col-md-3">
          <div v-if="canSeeClosedReports">
            <h2>{{ $t("settings.yandex_direct") }}</h2>
            <ul>
              <li>
                <router-link :to="{ name: 'direct-dashboard' }" class="">
                  Консоль
                  <span class="hint">Сводная информация и команды</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'direct-account-list' }" class="">
                  Учетные записи
                  <span class="hint">Токены доступа</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'direct-campaign-list' }" class="">
                  Рекламные кампании
                  <span class="hint">Справочник кампаний</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'direct-ad-report' }" class="">
                  Отчет по рекламе
                  <span class="hint">Данны затрат по рекламе из Яндекса</span>
                </router-link>
              </li>

              <li v-if="isShadow">
                <router-link :to="{ name: 'direct-report-list' }" class="">
                  Отчеты
                  <span class="hint">Справочник отчетов из яндекса</span>
                </router-link>
              </li>
              <li v-if="isShadow">
                <router-link :to="{ name: 'direct-record-list' }" class="">
                  Данные выгрузок из яндекса
                  <span class="hint">Голые данные с яндекса</span>
                </router-link>
              </li>
              <li v-if="isShadow">
                <router-link
                  :to="{ name: 'direct-money-result-list' }"
                  class=""
                >
                  Отчет по суммам затрат кампаний
                  <span class="hint">Данные по кампаниям</span>
                </router-link>
              </li>
              <li v-if="isShadow">
                <router-link :to="{ name: 'direct-result-list' }" class="">
                  Итоговый отчет
                  <span class="hint">Консолидированные итоговые данные</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="true" class="col-sm-12 col-md-3">
          <h2>Другие</h2>
          <ul>
            <li v-if="false">
              <router-link
                v-if="canSeeAllNotifications"
                :to="{ name: 'notifications-notification-all-list' }"
                class=""
              >
                Уведомления
                <span class="hint">Все уведомления пользователям</span>
              </router-link>
            </li>
            <li>
              <router-link
                v-if="canSeeRequestHistory"
                :to="{ name: 'request-log-all-list' }"
                class=""
              >
                {{ $t("settings.logs_history") }}
                <span class="hint">{{ $t("settings.logs_history_desc") }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'pest-shop-resource-list' }" class="">
                {{ $t("shopResources.shopResource_list") }}
                <span class="hint">{{
                  $t("shopResources.shopResource_list")
                }}</span>
              </router-link>
            </li>
            <li v-if="false">
              <router-link
                v-if="canSeeRequestHistory"
                :to="{ name: 'sms-message-list' }"
                class=""
              >
                Смс сообщения
                <span class="hint">Смс сообщения </span>
              </router-link>
            </li>

            <li v-if="isAdmin">
              <router-link :to="{ name: 'service-menu' }" class="">
                {{ $t("settings.maintenance") }}
                <span class="hint"> {{ $t("settings.maintenance_desc") }}</span>
              </router-link>
            </li>

            <li><system-version /></li>
          </ul>
          <ul></ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { canViewLeads } from "@/modules/users/rbac";
import {
  canSeeAllNotifications,
  canSeeRequestHistory,
  canSeeRequestTableList,
} from "@/modules/users/access/common.js";
import { mapGetters } from "vuex";
import SystemVersion from "@/modules/crm/views/app/SystemVersion.vue";

export default {
  name: "Settings",
  components: { SystemVersion },
  methods: {
    canViewLeads,
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      isShadow: "auth/isShadow",
      canSeeClosedReports: "auth/canSeeClosedReports",
    }),
    canSeeRequestHistory() {
      return canSeeRequestHistory(this.$store.state.user);
    },
    canSeeAllNotifications() {
      return canSeeAllNotifications(this.$store.state.user);
    },
    canSeeRequestTableList() {
      return canSeeRequestTableList(this.$store.state.user);
    },
  },
};
</script>

<style scoped>
h2 {
  padding-left: 2px;
}

ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

ul li a {
  display: block;
  margin-left: 0;
  margin-bottom: 10px;
  padding: 5px;
}

ul li:hover {
  background: #f5f4f4;
}

span.hint {
  display: block;
  color: #929191;
  font-size: smaller;
}
</style>

import t from "@/i18n";

const getCallCauseTitle = function (callCause) {
  if (!callCause || !callCause.title) {
    return t("common.not_set");
  }
  return callCause.title;
};

export { getCallCauseTitle };

import t from "@/i18n";

export const getAccountTitle = function (account) {
  if (!account || !account.title) {
    return t("common.not_set");
  }
  return account.title;
};

export const CURRENCY_RUB = "RUB"; // RUB
export const CURRENCY_USD = "USD"; // USD
export const CURRENCY_EUR = "EUR"; // EUR

export const currencyTitles = {
  [CURRENCY_RUB]: "Рубли",
  [CURRENCY_USD]: "Доллары",
  [CURRENCY_EUR]: "Евро",
};

export const currencySigns = {
  [CURRENCY_RUB]: "₽",
  [CURRENCY_USD]: "$",
  [CURRENCY_EUR]: "€",
};

export const currencyOptions = Object.entries(currencyTitles).map(([k, v]) => {
  return { value: k, text: v };
});

export const getAccountCurrencyTitle = function (account) {
  if (!account.currency) {
    return t("common.not_set");
  }
  return currencyTitles[account.currency];
};

export const getAccountCurrencySign = function (account) {
  if (!account.currency) {
    return "";
  }
  return currencySigns[account.currency];
};

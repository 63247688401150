import PaymentList from "@/modules/pest/views/payments/PaymentList";
import PaymentCreate from "@/modules/pest/views/payments/PaymentCreate";
import PaymentUpdate from "@/modules/pest/views/payments/PaymentUpdate";
import PaymentView from "@/modules/pest/views/payments/PaymentView";
import Settlements from "@/modules/pest/views/payments/Settlements";

export default [
  {
    path: "/payments/settlements/:townId?",
    name: "pest-settlements",
    components: {
      default: Settlements,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/payments/page:page?",
    name: "pest-payment-list",
    components: {
      default: PaymentList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/payments/new/:userId?",
    name: "pest-payment-create",
    components: {
      default: PaymentCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/payments/:id/update",
    name: "pest-payment-update",
    components: {
      default: PaymentUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/payments/:id",
    name: "pest-payment-view",
    components: {
      default: PaymentView,
    },
    meta: { requiresAuth: true },
  },
];

<template>
  <div class="ibox">
    <div class="">
      <div class="ibox-title">
        <div class="d-flex align-items-center">
          <h1>{{ $t("statuses.status_list") }}</h1>
          <div class="ml-4">
            <router-link
              :to="{ name: 'crm-status-create' }"
              class="btn btn-primary"
            >
              <font-awesome-icon fixed-width icon="plus" />
              {{ $t("statuses.add_status") }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="ibox-content table-responsive">
        <div class="search">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <b-form-group :label="$t('statuses.title')">
                <b-form-input v-model="search.name" tabindex="1"></b-form-input>
              </b-form-group>
            </div>

            <div class="align-self-center mt-2 mr-2">
              <b-button class="btn btn-primary ml-1" @click="fetchData">
                <font-awesome-icon fixed-width icon="search" />&nbsp;{{
                  $t("search.find")
                }}
              </b-button>
            </div>

            <div v-if="!loading" class="mt-2 mr-2">
              {{ $t("board_common.pagination_total") }}:
              {{ pagination.totalCount }}
            </div>
          </div>
        </div>

        <table class="table table-condensed table-striped">
          <thead>
            <tr>
              <th class="check-column">
                <b-form-checkbox
                  v-model="checkAll"
                  :value="true"
                  @change="checkAllCheckboxes"
                ></b-form-checkbox>
                <div v-if="hasCheckedItems" class="">
                  <b-dropdown text="" variant="default btn-xs">
                    <b-dropdown-item
                      href="#"
                      @click.prevent="deleteSelectedStatuses"
                      >Удалить выбранное
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </th>
              <th>{{ $t("common.title") }}</th>
              <th>{{ $t("common.sort_order") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr v-for="(status, index) in statuses" :key="'p' + index" class="">
              <td class="check-column">
                <b-form-checkbox
                  v-model="status.checked"
                  :value="true"
                  @change="checkCheckboxStatus"
                ></b-form-checkbox>
              </td>
              <td>
                <router-link
                  :to="{ name: 'crm-status-update', params: { id: status.id } }"
                >
                  {{ getStatusName(status) }}
                </router-link>
              </td>
              <td>
                {{ status.order }}
              </td>

              <td>
                <div v-if="status.newStatus">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_newStatus") }}
                </div>
                <div v-if="status.startWork">
                  <font-awesome-icon fixed-width icon="arrow-right" />
                  {{ $t("statuses.st_startWork") }}
                </div>
                <div v-if="status.duplicate">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_duplicate") }}
                </div>
                <div v-if="status.trash">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_trash") }}
                </div>
                <div v-if="status.closeStatus">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_closeStatus") }}
                </div>
                <div v-if="status.termination && false">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_termination") }}
                </div>
                <div v-if="status.moveToDeal">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_moveToDeal") }}
                </div>
                <div v-if="status.autoInitialCall">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_autoInitialCall") }}
                </div>
                <div v-if="status.autoRepeatedCall">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_autoRepeatedCall") }}
                </div>
                <div v-if="status.withoutAnswer">
                  <font-awesome-icon fixed-width icon="check" />
                  {{ $t("statuses.st_withoutAnswer") }}
                </div>
                <div v-if="status.hidden">
                  <font-awesome-icon fixed-width icon="eye-slash" />
                  {{ $t("statuses.st_hidden") }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="loading">{{ $t("common.loading") }}</div>

        <b-pagination-nav
          v-if="pagination.pageCount > 1"
          :link-gen="linkGen"
          :value="pagination.pageNumber"
          :number-of-pages="pagination.pageCount"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusName } from "@/modules/crm/decorators/statusDecorator";
import dayjs from "dayjs";

export default {
  name: "Crm",
  components: {},
  data() {
    return {
      checkAll: false,
      hasCheckedItems: false,
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
      },
      search: {
        name: "",
      },
      error: null,
      loading: true,
      statuses: [],
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.pagination.pageNumber =
        this.$route.params.page !== undefined ? this.$route.params.page : 1;
      this.fetchData();
    },
  },
  created: function () {
    this.checkUrl();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    dayjs,
    getStatusName,
    fetchData() {
      this.loading = true;
      let statusesUrl = "/crm/statuses";
      let params = {
        page: this.pageNumber,
        searchNames: this.search.name,
        // searchNames: this.search.title,
        // townId: this.search.townId,
        // status: this.search.status,
      };
      this.$http
        .get(statusesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.statuses = response.data;
          this.pagination.pageSize = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.totalCount = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.pageSize = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
    linkGen(pageNum) {
      return {
        name: "crm-status-list",
        params: { page: pageNum },
      };
    },

    deleteSelectedStatuses() {
      if (!confirm(this.$t("выбранные.delete_выбранные_question"))) {
        return;
      }
      let ids = [];
      for (let status of this.statuses) {
        if (status.checked) {
          ids.push(status.id);
        }
      }

      if (!ids) {
        this.$bvToast.toast(this.$t("common.error"), {
          title: this.$t("common.choose_at_least_one_record"),
          status: "danger",
        });
        return;
      }

      let deleteUrl;
      deleteUrl = "/crm/statuses/delete-many";
      this.loading = true;
      this.$http
        .post(deleteUrl, {
          ids: ids,
        })
        .then((response) => {
          this.loading = false;
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("statuses.status_deleted"),
            status: "success",
          });
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("statuses.cant_delete_status"),
              status: "danger",
            });
          }
        });
    },
    deleteStatus: function (status) {
      if (!confirm(this.$t("statuses.delete_statusы_question"))) {
        return;
      }

      let deleteUrl;
      deleteUrl = "/crm/statuses/" + status.id;
      this.loading = true;
      this.$http
        .delete(deleteUrl)
        .then((response) => {
          this.loading = false;
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("statuses.status_deleted"),
            status: "success",
          });
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("statuses.cant_delete_status"),
              status: "danger",
            });
          }
        });
    },

    checkCheckboxStatus() {
      this.$nextTick(() => {
        for (let status of this.statuses) {
          if (status.checked) {
            this.hasCheckedItems = true;
            return;
          }
        }
        this.hasCheckedItems = false;
      });
    },
    checkAllCheckboxes() {
      this.$nextTick(() => {
        let checked = this.checkAll;
        this.hasCheckedItems = checked;
        for (let status of this.statuses) {
          status.checked = checked;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-column {
  width: 1rem;
}
</style>

import AftercallFailList from "@/modules/pest/views/aftercall-fails/AftercallFailList";
import AftercallFailCreate from "@/modules/pest/views/aftercall-fails/AftercallFailCreate";
import AftercallFailUpdate from "@/modules/pest/views/aftercall-fails/AftercallFailUpdate";
import AftercallFailView from "@/modules/pest/views/aftercall-fails/AftercallFailView";

export default [
  {
    path: "/aftercall-fails/page:page?",
    name: "pest-aftercall-fail-list",
    components: {
      default: AftercallFailList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/aftercall-fails/new",
    name: "pest-aftercall-fail-create",
    components: {
      default: AftercallFailCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/aftercall-fails/:id/update",
    name: "pest-aftercall-fail-update",
    components: {
      default: AftercallFailUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/aftercall-fails/:id",
    name: "pest-aftercall-fail-view",
    components: {
      default: AftercallFailView,
    },
    meta: { requiresAuth: true },
  },
];

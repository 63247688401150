<template>
  <div>
    <div class="ibox">
      <div class="ibox-content">
        <div class="d-flex align-items-center">
          <h1>{{ $t("payments.payment_history") }}</h1>
          <router-link
            v-if="canAddPayment()"
            :to="{ name: 'pest-payment-create' }"
            class="btn btn-primary ml-2"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("payments.add_payment") }}
          </router-link>

          <div class="ml-auto">
            <router-link
              :to="{ name: 'pest-settlements' }"
              class="btn btn-outline-primary ml-2"
            >
              <font-awesome-icon icon="list" />
              {{ $t("payments.settlements") }}
            </router-link>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <b-form-group :label="$t('common.town')" prop="townId">
            <div class="mr-2">
              <town-filter
                :value="search.townId"
                @change="selectTown"
              ></town-filter>
            </div>
          </b-form-group>

          <b-form-group :label="$t('requests.assignee')" prop="employeeId">
            <user-autocomplete
              v-model="search.employeeId"
              :default-value="getEmployeeName(search.employee)"
              @selected="selectManager"
            />
          </b-form-group>
        </div>

        <b-table
          v-if="payments.length"
          responsive
          striped
          hover
          condensed
          :items="payments"
          :fields="fields"
          :busy="loading"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle table-spinner"></b-spinner>
              <strong> {{ $t("common.loading") }}</strong>
            </div>
          </template>

          <template #cell(actions)="data">
            <router-link
              :to="{
                name: 'pest-payment-update',
                params: { id: data.item.id },
              }"
              class=""
            >
              <b-button class="btn btn-xs btn-primary ml-1">
                <font-awesome-icon icon="eye" />&nbsp;
              </b-button>
            </router-link>
          </template>

          <template #cell(time)="data">
            {{ getPaymentTime(data.item) }}
          </template>

          <template #cell(sum)="data">
            {{ data.item.sum }}
          </template>
          <template #cell(employee)="data">
            {{ getUserName(data.item.employee) }}
          </template>
          <template #cell(manager)="data">
            {{ getUserName(data.item.manager) }}
          </template>

          <template #cell(color)="data">
            <span class="p-1" :style="{ background: data.item.color }"
              >{{ data.item.color }}
            </span>
          </template>
        </b-table>

        <b-pagination-nav
          v-if="pagination.count > 1"
          :link-gen="linkGen"
          :value="pagination.page"
          :number-of-pages="pagination.count"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaymentTime,
  getPaymentTownTitle,
} from "@/modules/pest/decorators/payments";
import { getUserName } from "@/modules/crm/common";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "PestPaymentList",
  components: { TownFilter, UserAutocomplete },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      payments: [],
      search: {
        employeeId: null,
        employee: {},
        townId: MSK,
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        // {
        //   key: 'actions',
        //   label: '',
        //   sortable: false,
        // },
        {
          key: "time",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "sum",
          label: this.$t("payments.sum"),
          sortable: false,
        },
        {
          key: "employee",
          label: this.$t("requests.assignee"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("common.comment"),
          sortable: false,
        },
        {
          key: "manager",
          label: this.$t("requests.manager"),
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getPaymentTime,
    getUserName,
    getPaymentTownTitle,
    fetchData: function () {
      this.loading = true;

      let paymentsUrl = "/pest/payments";
      let params = {
        page: this.page,
        townId: this.search.townId,
        employeeId: this.search.employeeId,
      };
      this.$http
        .get(paymentsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.payments = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-payment-list",
        params: { page: pageNum },
      };
    },

    canAddPayment() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },
    getEmployeeName(user) {
      return user ? getUserName(user) : "Выберите";
    },
    selectManager(data) {
      if (data && data.value) {
        this.search.employeeId = data.value.id;
        this.search.employee = data.value;
        this.fetchData();
      }
    },
    getTownClass(town) {
      if (this.search.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

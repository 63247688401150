import RejectCauseList from "@/modules/pest/views/reject-causes/RejectCauseList";
import RejectCauseCreate from "@/modules/pest/views/reject-causes/RejectCauseCreate";
import RejectCauseUpdate from "@/modules/pest/views/reject-causes/RejectCauseUpdate";
import RejectCauseView from "@/modules/pest/views/reject-causes/RejectCauseView";

export default [
  {
    path: "/reject-causes/page:page?",
    name: "pest-reject-cause-list",
    components: {
      default: RejectCauseList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/reject-causes/new",
    name: "pest-reject-cause-create",
    components: {
      default: RejectCauseCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/reject-causes/:id/update",
    name: "pest-reject-cause-update",
    components: {
      default: RejectCauseUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/reject-causes/:id",
    name: "pest-reject-cause-view",
    components: {
      default: RejectCauseView,
    },
    meta: { requiresAuth: true },
  },
];

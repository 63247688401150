<template>
  <b-modal
    id="modal-client-popup"
    ref="modal"
    title="Новый клиент"
    size="lg"
    class="contract-popup"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @hide="handleHide"
    @shown="onShown"
    @ok.prevent="handleTaskModalOk"
  >
    <template #default>
      <b-form
        id="phone-form"
        ref="phone"
        class="client-form"
        @submit.prevent="createOrUpdate"
      >
        <div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <b-form-group :label="$t('clients.lastName')" prop="lastName">
                <b-form-input
                  v-model="client.lastName"
                  :placeholder="$t('clients.lastName')"
                  :state="checkFioHasNumbers"
                />
              </b-form-group>
            </div>

            <div class="col-md-4 col-sm-12">
              <b-form-group :label="$t('clients.firstName')" prop="firstName">
                <b-form-input
                  v-model="client.firstName"
                  required
                  :placeholder="$t('clients.firstName')"
                  :state="checkNameHasNumbers"
                />
              </b-form-group>
            </div>

            <div class="col-md-4 col-sm-12">
              <b-form-group :label="$t('clients.patronymic')" prop="patronymic">
                <b-form-input
                  v-model="client.patronymic"
                  :placeholder="$t('clients.patronymic')"
                  :state="checkPatronymicHasNumbers"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <b-form-group :label="$t('clients.firm')" prop="firm">
                <b-form-input
                  v-model="client.firm"
                  :placeholder="$t('clients.firm')"
                />
              </b-form-group>
            </div>
            <div class="col-md-4 col-sm-12">
              <b-form-group :label="$t('clients.post')" prop="post">
                <b-form-input
                  v-model="client.post"
                  :placeholder="$t('clients.post')"
                />
              </b-form-group>
            </div>
            <div class="col-md-4 col-sm-12">
              <b-form-group
                :label="$t('clients.responsible_person')"
                prop="responsiblePerson"
              >
                <b-form-input
                  v-model="client.responsiblePerson"
                  :placeholder="$t('clients.responsible_person')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div
            v-for="(phone, phoneIndex) in client.phones"
            :key="'p' + phoneIndex"
            class="row"
          >
            <div class="col-md-4 col-sm-12">
              <b-form-group :label="$t('clients.phone')" prop="phone">
                <b-form-input
                  v-model="phone.phone"
                  v-mask="'+7 (###) ###-####'"
                ></b-form-input>
                <small class="form-text text-muted">
                  <a
                    v-if="phoneIndex === 0"
                    href="#"
                    @click.prevent="addPhone(phoneIndex)"
                    >{{ $t("common.add") }}</a
                  >
                  <a
                    v-if="phoneIndex > 0 && client.phones.length > 0"
                    href="#"
                    @click.prevent="removePhone(phoneIndex)"
                    >{{ $t("common.remove") }}</a
                  >
                </small>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-12">
            <b-form-group :label="$t('clients.address')" prop="address">
              <b-form-input v-model="client.address"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </template>

    <template #footer>
      <b-button variant="primary" @click="createOrUpdate">
        <font-awesome-icon icon="check" />
        {{ $t("common.add") }}
      </b-button>

      <b-button variant="outline-default" class="ml-2" @click="cancelUpdate">
        <font-awesome-icon icon="times" />
        {{ $t("common.cancel") }}
      </b-button>

      <b-button
        v-if="client.id > 0"
        variant="danger"
        class="ml-2"
        @click="deleteClient"
      >
        <font-awesome-icon icon="trash" />
        {{ $t("common.remove") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  TYPE_ADMIN,
  TYPE_FINE_MANAGER,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_WORKER,
} from "@/modules/users/decorators/users";
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";

export default {
  name: "ContactPopup",
  directives: { mask: VueMaskDirective },
  components: {},
  props: {},
  data() {
    return {
      TYPE_ADMIN,
      TYPE_WORKER,
      TYPE_LOGIST,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,

      client: {},
      loading: false,
      clientId: false,
      successHandler: false,
      cancelHandler: false,
    };
  },
  computed: {
    checkFioHasNumbers() {
      return this.checkHasNumbers(this.client.lastName);
    },
    checkNameHasNumbers() {
      if (!this.client.firstName) {
        return false;
      }
      return this.checkHasNumbers(this.client.firstName);
    },
    checkPatronymicHasNumbers() {
      return this.checkHasNumbers(this.client.patronymic);
    },
  },
  watch: {},
  created: function () {
    this.$eventHub.$on("modal-client-popup", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub(modal-client-popup);
  },
  methods: {
    checkHasNumbers(str) {
      let numberRegex = /\d/;
      return !numberRegex.test(str);
    },
    getUserName,
    handleSuccess() {
      this.$bvModal.hide("modal-client-popup");
      if (this.successHandler) {
        this.successHandler({ client: this.client });
      }
      this.successHandler = false;
      this.client = {};
    },
    handleHide() {
      this.loading = false;
      this.$bvModal.hide("modal-client-popup");
      if (this.hideHandler) {
        this.hideHandler();
      }
    },

    create: function () {
      let updateUrl;
      updateUrl = "/crm/clients";
      this.loading = true;
      this.$http
        .post(updateUrl, this.client)
        .then((response) => {
          this.client = response.data;
          this.handleSuccess();
          // this.$router.push({name: 'crm-client-view', params: {id: this.client.id}});
          this.$bvToast.toast(this.$t("clients.client_created"), {
            title: this.$t("clients.client_created"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("clients.cant_create_client"),
              variant: "danger",
            });
          }
        });
    },

    createOrUpdate: function () {
      let form = document.getElementById("phone-form");
      let valid = form.checkValidity() && this.validationNameFields();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.create();
        form.classList.remove("was-validated");
      } else {
        form.classList.remove("was-validated");
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },

    handleTaskModalOk() {
      this.createOrUpdate(this.handleSuccess);
    },

    validationNameFields() {
      return (
        this.checkNameHasNumbers &&
        this.checkFioHasNumbers &&
        this.checkPatronymicHasNumbers
      );
    },
    selectManager(data) {
      if (data && data.value) {
        this.client.managerId = data.value.id;
        this.client.manager = data.value;
      } else {
        this.client.managerId = null;
        this.client.manager = {};
      }
      this.$forceUpdate();
    },

    openPopup(info) {
      this.client = info.client;

      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.hideHandler = info.hide;
      this.$bvModal.show("modal-client-popup", { size: "sm" });
    },
    onShown() {},
    addPhone() {
      this.client.phones.push({
        phone: "",
        type: "mobile",
      });
    },
    removePhone(index) {
      this.client.phones.splice(index, 1);
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>

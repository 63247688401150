import dayjs from "dayjs";

export const isTimeSlotUserBusy = function (requests, hours, minutes) {
  if (!requests.assignee.timeSlots) {
    return false;
  }
  for (let slot of requests.assignee.timeSlots) {
    if (slot.hours === hours && slot.minutes === minutes) {
      return slot.busy;
    }
  }
  return false;
};
export const isTimeSlotBusy = function (requests, hours, minutes) {
  if (!requests.length) {
    return false;
  }
  for (let request of requests) {
    let start =
      dayjs.unix(request.time).utcOffset("+03:00").format("HH").toString() * 60;
    let end = start + request.duration - 1;

    let minutesInt = parseInt(minutes);
    let nowStart = parseInt(hours) * 60 + minutesInt;
    let nowEnd = (parseInt(hours) + 2) * 60 + minutesInt;

    if (start <= nowStart && nowStart <= end) {
      return true;
    }
    if (start <= nowEnd && nowEnd <= end) {
      return true;
    }
  }
  return false;
};

<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>{{ $t("products.products") }}</h1>
      <div class="mb-2">
        <router-link
          :to="{ name: 'pest-product-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          {{ $t("products.add_product") }}
        </router-link>
      </div>

      <b-table
        v-if="products.length"
        responsive
        striped
        hover
        condensed
        :items="products"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> {{ $t("common.loading") }}</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{ name: 'pest-product-update', params: { id: data.item.id } }"
            class=""
          >
            {{ data.item.title ? data.item.title : $t("common.without_title") }}
          </router-link>
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
export default {
  name: "PestProductList",
  components: {},
  data() {
    return {
      products: [{}],
      search: {
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "unit",
          label: this.$t("common.unit"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("common.description"),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    fetchData: function () {
      this.loading = true;

      let productsUrl = "/pest/products";
      let params = {
        page: this.page,
      };
      this.$http
        .get(productsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.products = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-product-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

import AssigneeSchedule from "@/modules/pest/views/schedule/AssigneeSchedule";
import AssigneeDaySchedule from "@/modules/pest/views/schedule/AssigneeDaySchedule";
import Stats from "@/modules/pest/views/stats/Stats.vue";
import UserSchedule from "@/modules/pest/views/schedule/UserSchedule.vue";
import TimeBusyness from "@/modules/pest/views/schedule/TimeBusyness";
import AssigneeStats from "@/modules/pest/views/stats/AssigneeStats.vue";
import PestSettings from "@/modules/pest/views/settings/PestSettings.vue";
import ServiceMenu from "@/modules/crm/views/app/ServiceMenu";

export default [
  {
    path: "/pest-settings",
    name: "pest-settings",
    components: {
      default: PestSettings,
      // 'client-header': ClientHeader,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/service-menu",
    name: "service-menu",
    components: {
      default: ServiceMenu,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/stats",
    name: "pest-stats",
    components: {
      default: Stats,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/my-stats",
    name: "pest-own-stats",
    components: {
      default: AssigneeStats,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "stats.stats",
    },
  },
];

<template>
  <!-- :ok-disabled="true"-->
  <b-modal
    id="modal-assignee"
    ref="modal"
    :title="title"
    size="lg"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @ok="handleTaskModalOk"
    @cancel="handleTaskModalCancel"
  >
    <div class="row mb-2 btn-row">
      <div class="col-md-3">
        <button
          class="btn btn-block"
          :class="{
            'btn-primary': isActive(false),
            'btn-outline-primary': !isActive(false),
          }"
          @click.prevent="resetAllTechnicians"
        >
          {{ $t("requests.assignee_not_set") }}
        </button>
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
    </div>
    <hr />
    <div class="row mb-2 btn-row">
      <div class="col-md-3">
        <h3>{{ $t("requests.assignee") }}</h3>
      </div>

      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button
          v-if="!showOther"
          class="btn btn-block"
          :class="{
            'btn-primary': showOther,
            'btn-outline-primary': !showOther,
          }"
          @click.prevent="openSearch"
        >
          {{ $t("requests.choose_another") }}
        </button>
        <div :class="{ 'd-none': !showOther }">
          <user-autocomplete
            ref="assigneeSearch"
            default-value=""
            :type="TYPE_WORKER"
            :town-id="townId"
            :hide-reset="true"
            @selected="selectAssigneeFromAutocomplete"
          ></user-autocomplete>
        </div>
      </div>
    </div>

    <hr />
    <div class="row">
      <div
        v-for="(group, gIndex) in processedAssignees"
        :key="'col' + gIndex"
        class="col-md-3"
      >
        <ul>
          <li
            v-for="(assignee, index) in group"
            :key="index"
            :class="{ active: isActive(assignee) }"
            @click.prevent="selectAssignee(assignee)"
          >
            {{ getAssigneeName(assignee) }}
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div class="row mb-2 btn-row">
      <div class="col-md-3">
        <h3>{{ $t("requests.extra_technicians") }}</h3>
      </div>

      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button
          v-if="!showOtherExtraTechnicians"
          class="btn btn-block"
          :class="{
            'btn-primary': showOtherExtraTechnicians,
            'btn-outline-primary': !showOtherExtraTechnicians,
          }"
          @click.prevent="openSearchExtraTechnicians"
        >
          {{ $t("requests.choose_another") }}
        </button>
        <div :class="{ 'd-none': !showOtherExtraTechnicians }">
          <user-autocomplete
            ref="assigneeSearchExtraTechnicians"
            default-value=""
            :type="TYPE_WORKER"
            :town-id="townId"
            :hide-reset="true"
            @selected="selectExtraTechniciansFromAutocomplete"
          ></user-autocomplete>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div
        v-for="(group, gIndex) in processedExtraTechnicians"
        :key="'col' + gIndex"
        class="col-md-3"
      >
        <ul>
          <li
            v-for="(assignee, index) in group"
            :key="index"
            :class="{ active: isActiveExtraTechnicians(assignee) }"
            class="d-flex extra-technician-li align-items-center"
          >
            <div v-if="assignee.disabled" class="disabled">
              {{ getAssigneeName(assignee) }}
            </div>
            <div v-else @click.prevent="selectExtraTechnician(assignee)">
              {{ getAssigneeName(assignee) }}
            </div>
            <span v-if="isActiveExtraTechnicians(assignee)">
              <button
                class="btn btn-danger"
                type="button"
                @click="deleteItem(assignee.id)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { TYPE_WORKER } from "@/modules/users/decorators/users";
import { getAssigneeName } from "@/modules/crm/common";
import {
  confirmationAssigneeWhenInspection,
  getRequestDatetime,
  getRequestTitle,
} from "@/modules/pest/decorators/requests.js";
import UserAutocomplete from "../../../../../users/views/user/UserAutocomplete.vue";
import { isTimeSlotUserBusy } from "@/modules/pest/helpers/dispatching/timeslots";

export default {
  name: "RequestAssigneePopup",
  components: { UserAutocomplete },
  props: {
    assigneesList: {
      type: Array,
      default: () => [],
    },
    townId: {
      type: Number,
      default: null,
    },
    requests: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TYPE_WORKER,
      showOther: false,
      showOtherExtraTechnicians: false,
      request: {},
      activeAssignee: undefined,
      assignees: this.assigneesList,
      extraTechnicians: this.assigneesList,
    };
  },
  computed: {
    processedAssignees() {
      return this.getProcessedAssignees(this.assignees);
    },
    processedExtraTechnicians() {
      this.extraTechnicians.map((technician) => {
        technician.disabled = technician.id === this.request.assigneeId;
      });
      return this.getProcessedAssignees(this.extraTechnicians);
    },
    title() {
      if (!this.request) {
        return "Смена исполнителя";
      }
      return (
        this.getRequestTitle(this.request) +
        " – " +
        this.getRequestDatetime(this.request)
      );
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("assignee-popup-show", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('assignee-popup-show');
  },
  methods: {
    deleteItem(assigneeId) {
      this.request.extraTechnicians = this.request.extraTechnicians.filter(
        (extraTechnician) => extraTechnician.id !== assigneeId
      );
    },
    getProcessedAssignees(assignee) {
      let all = [];
      let chunkSize = Math.floor(assignee.length / 4);
      let remainder = assignee.length - chunkSize * 4;
      if (remainder > 0) {
        chunkSize++;
      }
      for (let i = 0; i < assignee.length; i += chunkSize) {
        const chunk = assignee.slice(i, i + chunkSize);
        all.push(chunk);
      }
      return all;
    },
    getRequestTitle,
    getRequestDatetime,
    getAssigneeName,
    isTimeSlotUserBusy,
    handleSuccess(assignee) {
      //this.$bvModal.hide("modal-assignee");
      if (this.successHandler) {
        this.successHandler(assignee);
      }
      this.successHandler = false;
      this.data = {};
    },
    handleTaskModalOk(bvModalEvt) {
      this.update();
      //bvModalEvt.preventDefault();
      // this.$refs.requestForm.createOrUpdate(bvModalEvt, this.handleSuccess);
    },
    handleTaskModalCancel(bvModalEvt) {
      this.request.assigneeId = this.assigneesStart;
      this.request.extraTechnicians = this.extraTechniciansStart;
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/requests/" + this.request.id;
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$emit("start-creating");
      this.$http
        .patch(updateUrl, {
          assigneeId: this.request.assigneeId ? this.request.assigneeId : null,
          extraTechnicians: this.request.extraTechnicians,
        })
        .then((response) => {
          this.loading = false;

          this.$emit("end-creating");
          this.$eventHub.$emit("update-request-info", this.request);
          this.$emit("update-request-info", this.request);
          // this.$router.push({name: 'request-view', params: {id: this.request.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("requests.request_updated"),
            request: "success",
          });
        })
        .catch((error) => {
          this.$emit("end-creating");
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("requests.cant_update_request"),
              request: "danger",
            });
          }
        });
    },

    selectAssigneeFromAutocomplete(newTechnician) {
      newTechnician = newTechnician.value;

      const isAssigneeNew = this.assignees.every((assignee) => {
        return assignee.id !== newTechnician.id;
      });

      if (isAssigneeNew) {
        this.assignees.push(newTechnician);
      }

      this.selectAssignee(newTechnician);
    },
    selectExtraTechniciansFromAutocomplete(newTechnician) {
      newTechnician = newTechnician.value;

      const isAssigneeNew = this.extraTechnicians.every((assignee) => {
        return assignee.id !== newTechnician.id;
      });

      if (isAssigneeNew) {
        this.extraTechnicians.push(newTechnician);
      }

      if (this.request.assigneeId !== newTechnician.id) {
        this.selectExtraTechnician(newTechnician);
      }
    },
    resetAllTechnicians() {
      this.request.assigneeId = undefined;
      this.request.extraTechnicians = [];
    },
    selectAssignee(assignee) {
      if (
        !confirmationAssigneeWhenInspection(
          this.request.previousNotInspection,
          this.request.previousTechnician,
          assignee
        )
      ) {
        return;
      }
      this.request.assigneeId = assignee.id;
      this.request.extraTechnicians = this.request.extraTechnicians.filter(
        (extraTechnician) => {
          return extraTechnician.id !== assignee.id;
        }
      );
      //this.handleSuccess(assignee);
    },
    selectExtraTechnician(assignee) {
      let newExtraTechnician = true;
      //this.request.extraTechnicians.assigneeId = assignee.id;
      for (const extraTechnician of this.request.extraTechnicians) {
        if (extraTechnician.id === assignee.id) {
          newExtraTechnician = false;
          break;
        }
      }

      if (newExtraTechnician) {
        this.request.extraTechnicians.push(assignee);
      }
    },

    openPopup(info) {
      this.assignees = this.assigneesList;
      this.extraTechnicians = [...this.assigneesList];
      this.activeAssignee =
        this.showOther =
        this.showOtherExtraTechnicians =
          false;
      this.request = info.request;

      if (this.request.assigneeId) {
        this.assigneesStart = JSON.parse(
          JSON.stringify(this.request.assigneeId)
        );
      }
      if (this.request.extraTechnicians) {
        this.extraTechniciansStart = JSON.parse(
          JSON.stringify(this.request.extraTechnicians)
        );
      }

      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-assignee");
    },
    isActive(assignee) {
      if (!assignee) {
        return !this.request.assigneeId;
      }
      return assignee.id === this.request.assigneeId;
    },
    isActiveExtraTechnicians(assignee) {
      if (!assignee) {
        return !this.request.assigneeId;
      }

      let i = 0;
      let result = false;
      do {
        if (
          this.request.extraTechnicians === undefined ||
          this.request.extraTechnicians[i] === undefined
        ) {
          break;
        }
        result = this.request.extraTechnicians[i].id === assignee.id;
        i++;
      } while (!result);

      return result;
    },
    openSearch() {
      this.showOther = true;
      this.$nextTick(() => {
        this.$refs.assigneeSearch.focus();
      });
    },
    openSearchExtraTechnicians() {
      this.showOtherExtraTechnicians = true;
      this.$nextTick(() => {
        this.$refs.assigneeSearchExtraTechnicians.focus();
      });
    },
  },
};
</script>

<script setup></script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  cursor: pointer;
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}

li.active {
  background: lightblue;
}

.extra-technician-li {
  padding: 0 !important;
}

ul .extra-technician-li:hover {
  background: none;
}

.extra-technician-li div {
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
}

.extra-technician-li div.active {
  background: lightblue;
}

.extra-technician-li div:hover {
  background: #ccc;
}

li.busy {
  opacity: 0.3;
  cursor: not-allowed;
}

.btn-row {
  height: 36px !important; // fix different size of button and autocomplete
}

div.disabled {
  opacity: 0.5;
  background: none;
  cursor: default;
}
.extra-technician-li div.disabled:hover {
  background: none;
}

ul .extra-technician-li {
  cursor: default;
}
</style>

<template>
  <div class="request-page">
    <div class="ibox mb-1">
      <div class="ibox-title">
        <h1>
          <router-link
            :to="{ name: 'request-view', id: request.id }"
            class="h4 text-muted"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>
          Изменение заявки
        </h1>
      </div>
      <div class="ibox-content">
        <request-form
          :request-model="request"
          :data-loaded="!loading"
        ></request-form>
      </div>
    </div>
  </div>
</template>

<script>
import RequestForm from "./view/RequestForm.vue";

export default {
  name: "Request",
  components: {
    "request-form": RequestForm,
  },
  data() {
    return {
      request: {
        id: "new",
        title: "",
        parentId: "",
        groupId: "",
        managerId: "",
      },
      loading: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData: function () {
      this.request.phone = "";
      let requestsUrl = "/pest/requests/" + this.id;
      this.loading = true;
      this.$http
        .get(requestsUrl)
        .then((response) => {
          this.loading = false;
          this.request = response.data;
          this.request.managerName = this.request.manager.username;
          this.header = this.request.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

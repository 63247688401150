import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import auth from "./modules/auth";
import data from "./modules/data";
import socket from "./socket/store.js";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "pest-crm-new",
    }),
  ],
  modules: {
    user,
    auth,
    socket,
    data,
  },
});

export default store;

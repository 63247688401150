export const ru = {
  projects: {
    projects: "Проекты",
    project_list: "Перечень проектов",
    project: "Проект",
    project_number: "Номер проекта roistat",
    add_project: "Добавить проект",
    new_project: "Новый проект",
    project_update: "Изменение проекта",
    project_updated: "Проект отредактирован",
    cant_create_project: "Не удалось добавить проект",
    project_created: "Проект добавлен",
    enabled: "Активен",
  },
};
export const en = {
  projects: {
    projects: "Projects",
    project_list: "Project list",
    project: "Project",
    project_number: "Roistat project number",
    add_project: "Add project",
    new_project: "New project",
    change_project: "Change project",
    project_update: "Update project",
    project_updated: "Project changed",
    cant_create_project: "Can't add project",
    project_created: "Project added",
    enabled: "Enabled",
  },
};

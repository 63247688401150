<template>
  <div class="ibox mb-1">
    <div class="ibox-content pb-1">
      <table v-if="contacts.length > 0" class="table table-striped">
        <tbody>
          <tr v-for="(contact, index) in contacts" :key="index">
            <td>
              <router-link
                :to="{ name: 'crm-client-view', params: { id: contact.id } }"
                class=""
              >
                {{ getClientName(contact) }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="no-employees">У организации нет сотрудников</div>

      <router-link
        :to="{
          name: 'crm-client-create',
          params: { organizationId: client.id },
        }"
        class="btn btn-primary mt-2 mb-2"
      >
        <font-awesome-icon icon="plus" />
        {{ $t("requests.add_contact") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { getClientName } from "@/modules/crm/decorators/client";

export default {
  name: "OrganizationContacts",
  props: ["client"],
  data() {
    return {
      loading: false,
      contacts: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  methods: {
    checkUrl: function () {
      if (this.id !== "new") {
        this.fetchData();
      }
    },
    getClientName(client) {
      return getClientName(client);
    },
    fetchData: function () {
      let contactsUrl = "/crm/client/contacts/" + this.id;
      this.loading = true;
      this.$http
        .get(contactsUrl)
        .then((response) => {
          this.loading = false;
          this.contacts = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style scoped>
.no-employees {
}
</style>

import ResourceList from "@/modules/pest/views/resources/ResourceList";
import ResourceCreate from "@/modules/pest/views/resources/ResourceCreate";
import ResourceUpdate from "@/modules/pest/views/resources/ResourceUpdate";
import ResourceView from "@/modules/pest/views/resources/ResourceView";

export default [
  {
    path: "/resources/page:page?",
    name: "pest-resource-list",
    components: {
      default: ResourceList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/resources/new",
    name: "pest-resource-create",
    components: {
      default: ResourceCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/resources/:id/update",
    name: "pest-resource-update",
    components: {
      default: ResourceUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/resources/:id",
    name: "pest-resource-view",
    components: {
      default: ResourceView,
    },
    meta: { requiresAuth: true },
  },
];

export const ru = {
  products: {
    products: "Товары",
    product_list: "Перечень товаров",
    product: "Товар",
    add_product: "Добавить товар",
    new_product: "Новый товар",
    product_update: "Изменение товара",
    product_updated: "Товар отредактирован",
    cant_create_product: "Не удалось добавить товар",
    cant_delete_product: "Не удалось удалить товар",
    product_created: "Товар добавлен",
    product_deleted: "Товар удален",
    delete_product_question: "Вы действительно хотите удалить товар?",
  },
};
export const en = {
  products: {
    products: "Products",
    product_list: "Product list",
    product: "Product",
    add_product: "Add product",
    new_product: "New product",
    change_product: "Change product",
    product_update: "Update product",
    product_updated: "Product changed",
    cant_create_product: "Can't add product",
    cant_delete_product: "Can't delete product",
    product_created: "Product added",
    product_deleted: "Product deleted",
    delete_product_question: "Do you want to delete the product?",
  },
};

import ResultList from "@/modules/direct/views/results/ResultList";

export default [
  {
    path: "/results/page:page?",
    name: "direct-result-list",
    components: {
      default: ResultList,
    },
    meta: { requiresAuth: true },
  },
];

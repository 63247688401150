<template>
  <div class="ibox">
    <div class="ibox-title d-flex pr-2">
      <h4>Конвертация заявки</h4>
    </div>
    <div class="ibox-content">
      <b-form
        id="convert-request-form"
        ref="request"
        class="convert-request-form"
        novalidate
        @submit.prevent="createOrUpdate('roistat')"
        @reset="onReset"
      >
        <div class="div">
          <b-form-group label="Номер" prop="title">
            <template #label> Номер заявки</template>
            <b-form-input v-model="number" tabindex="1" required></b-form-input>
          </b-form-group>
          <b-form-group label="Номер" prop="title">
            <template #label> Номер контракта</template>
            <b-form-input
              v-model="contractId"
              tabindex="1"
              placeholder="Оставьте пустым для нового"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-form>
    </div>

    <div class="ibox-footer d-flex">
      <div class=" ">
        <b-button v-if="!saving" variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="paper-plane" />
          Сконвертировать
        </b-button>
      </div>

      <b-button v-if="saving" variant="primary" disabled @click.prevent>
        <font-awesome-icon icon="sync" spin="" />
        Отправляется
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConvertRequest",
  data() {
    return {
      number: "",
      saving: false,
      contractId: null,
    };
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.refreshForm();
  },
  methods: {
    refreshForm() {
      this.number = "";
      this.contractId = null;
      this.$forceUpdate();
    },
    create: function () {
      let updateUrl;
      if (this.saving) {
        this.$bvToast.toast(this.$t("common.data_is_saving"), {
          title: this.$t("common.please_wait"),
          variant: "info",
        });
        return;
      }
      this.saving = true;
      updateUrl =
        "/pest/request/convert-contract?id=" +
        this.number +
        "&contractId=" +
        this.contractId;
      this.$http
        .post(updateUrl, this.request)
        .then((response) => {
          this.saving = false;
          this.$bvToast.toast(this.$t("common.loading"), {
            title: this.$t("common.success"),
            variant: "success",
          });

          this.refreshForm();
        })
        .catch((error) => {
          this.saving = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("common.error"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      let form = document.getElementById("convert-request-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        this.create();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>

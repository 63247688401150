import dayjs from "dayjs";
import { formatNumber } from "@/modules/pest/decorators/common.js";
import { getUserName } from "@/modules/crm/common.js";
import t from "@/i18n";

export const getDepositAssigneeName = function (deposit) {
  return getUserName(deposit.employee);
};

export const getDepositDate = function (deposit) {
  return dayjs.unix(deposit.time).utcOffset("+03:00").format("DD.MM.YYYY");
};

export const getDepositDateTime = function (deposit) {
  return dayjs
    .unix(deposit.time)
    .utcOffset("+03:00")
    .format("DD.MM.YYYY HH:mm");
};

export const getDepositSumFormatted = function (deposit) {
  return formatNumber(getDepositSum(deposit));
};

export const getDepositSum = function (deposit) {
  // if (!deposit || !deposit.totalSum) {
  //     return '0';
  // }
  return deposit.totalSum;
};

export const STATUS_NEW = 1;
export const STATUS_CONFIRMED = 2;
export const STATUS_ERROR = -1;

export const statusTitles = {
  [STATUS_NEW]: "deposit.status_new_label",
  [STATUS_CONFIRMED]: "deposit.status_confirmed_label",
  [STATUS_ERROR]: "deposit.status_error_label",
};
export const getDepositStatusTitle = function (deposit) {
  if (!deposit.status) {
    return t("common.not_set");
  }
  return t(statusTitles[deposit.status]);
};

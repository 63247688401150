<template>
  <b-form
    v-if="dataLoaded"
    id="request-result-form"
    ref="request"
    :model="request"
    class="result-request-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div mb-2">
      <b-form-group :label="$t('requests.duration_min')" prop="title">
        <b-form-input
          v-model="request.duration"
          placeholder=""
          tabindex="1"
        ></b-form-input>
      </b-form-group>

      <!--div class="row">
        <div class="col-6">
          <b-form-group :label="$t('requests.trip_sum')" prop="title">
            <b-form-input
              v-model="request.tripSum"
              placeholder="0"
              tabindex="1 required"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group :label="$t('requests.trip_distance')" prop="title">
            <b-form-input
              v-model="request.tripDistance"
              placeholder=""
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div-->

      <!--div class="row">
        <div class="col-6">
          <b-form-group
            :label="$t('requests.trip_after_last_request_distance')"
            prop="title"
          >
            <b-form-input
              v-model="request.tripBackSum"
              placeholder="0"
              tabindex="1 required"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            :label="$t('requests.trip_after_last_request_sum')"
            prop="title"
          >
            <b-form-input
              v-model="request.tripBackDistance"
              placeholder=""
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div-->

      <hr />

      <!--b-form-group
        :label="$t('requests.equipment_sum')"
        prop="equipmentUserSum"
        disabled
      >
        <b-form-input
          v-model="equipmentSum"
          placeholder=""
          tabindex="1"
        ></b-form-input>
        <div v-if="request.equipmentInfo" class="mt-1 font-bold">
          {{ request.equipmentInfo.title }}

          <span
            v-if="
              request.equipmentInfo.paymentType === EQUIPMENT_PAYMENT_PERCENT
            "
          >
            {{ request.equipmentInfo.percent
            }}{{ $t("requests.equipment_percent_desc") }}
          </span>
          <span v-else>
            {{ request.equipmentInfo.price
            }}{{ $t("requests.equipment_fixed_desc") }}
          </span>
        </div>
      </b-form-group>

      <hr /-->

      <h4>{{ $t("requests.resources") }}</h4>

      <div v-if="request.resources.length > 0">
        <div
          v-for="(resource, rIndex) in request.resources"
          :key="'r' + rIndex"
          class="d-flex align-items-top"
        >
          <div class="mr-2">
            <b-form-group :label="$t('requests.resource')">
              <b-form-select
                v-model="resource.resourceId"
                :disabled="request.freeInspection"
                :options="resourcesList"
                placeholder=""
                class="resource-select"
                tabindex="1"
                required
                onfocus="this.size=5;"
                onblur="this.size=5;"
                onchange="this.size=5; this.blur();"
                onfocusout="this.size=null;"
                @change="setResourceTitle(resource, rIndex)"
              ></b-form-select>
            </b-form-group>
            <b-form-group :label="$t('requests.dosage_1l_ml')" prop="title">
              <b-form-input
                v-model="resource.dosage"
                :disabled="request.freeInspection"
                placeholder=""
                type="number"
                tabindex="3"
                required
                step="0.01"
                min="0"
                @change="revertNegative('dosage', rIndex)"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="">
            <b-form-group :label="$t('requests.solution_volume_l')">
              <b-form-input
                v-model="resource.solutionVolume"
                placeholder=""
                type="number"
                tabindex="2"
                step="0.01"
                min="0"
                :disabled="request.freeInspection"
                required
                @change="revertNegative('solutionVolume', rIndex)"
              ></b-form-input>
            </b-form-group>

            <button
              v-if="rIndex > 0"
              class="btn btn-danger btn-outline mtfr"
              @click.prevent="removeResource(rIndex)"
            >
              <font-awesome-icon icon="trash" fixed-width />
            </button>
            <button
              v-else
              class="btn btn-primary btn-outline mtfr"
              @click.prevent="addResource"
            >
              <font-awesome-icon icon="plus" fixed-width />
            </button>
          </div>
        </div>
      </div>

      <h4>{{ $t("requests.services") }}</h4>
      <div v-if="request.services.length > 0">
        <div
          v-for="(service, rIndex) in request.services"
          :key="'r' + rIndex"
          class="d-flex align-items-center"
        >
          <div class="">
            <b-form-group :label="$t('requests.service')" prop="title">
              <b-form-select
                v-model="service.id"
                :options="servicesList"
                placeholder=""
                class="service-select"
                tabindex="1"
                required
                onfocus="this.size=5;"
                onblur="this.size=5;"
                onchange="this.size=5; this.blur();"
                onfocusout="this.size=null;"
                @change="setServiceTitle(service)"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="ml-2">
            <b-form-group :label="$t('requests.price')" prop="title">
              <b-form-input
                v-model="service.price"
                placeholder=""
                class="service-count"
                tabindex="1"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="ml-2 pt-2">
            <button
              v-if="rIndex > 0"
              class="btn btn-danger btn-outline"
              @click.prevent="removeService(rIndex)"
            >
              <font-awesome-icon icon="trash" fixed-width />
            </button>
            <button
              v-else
              class="btn btn-primary btn-outline"
              @click.prevent="addService"
            >
              <font-awesome-icon icon="plus" fixed-width />
            </button>
          </div>
        </div>
      </div>

      <h4>{{ $t("requests.products") }}</h4>
      <div v-if="request.products.length > 0">
        <div
          v-for="(product, rIndex) in request.products"
          :key="'r' + rIndex"
          class="d-flex align-items-top product-row"
        >
          <div class="">
            <b-form-group :label="$t('requests.product')" prop="title">
              <b-form-select
                v-model="product.id"
                :options="productList"
                placeholder=""
                class="service-select"
                tabindex="1"
                onfocus="this.size=5;"
                onblur="this.size=5;"
                onchange="this.size=5; this.blur();"
                onfocusout="this.size=null;"
                @change="setProductTitle(product)"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="ml-2">
            <b-form-group :label="$t('requests.price')" prop="title">
              <b-form-input
                v-model="product.price"
                placeholder=""
                class="service-count"
                tabindex="1"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('requests.net_price')" prop="title">
              <b-form-input
                v-model="product.cost"
                placeholder=""
                class="service-count"
                tabindex="1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="ml-2 pt-2">
            <button
              v-if="rIndex > 0"
              class="btn btn-danger btn-outline"
              @click.prevent="removeProduct(rIndex)"
            >
              <font-awesome-icon icon="trash" fixed-width />
            </button>
            <button
              v-else
              class="btn btn-primary btn-outline"
              @click.prevent="addProduct"
            >
              <font-awesome-icon icon="plus" fixed-width />
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <!--b-form-group :label="$t('requests.follow_up_treatment')" prop="title">
          <div class="mr-2 mb-1">
            <b-button-group>
              <button
                class="btn"
                :class="
                  !request.repeatAppointed
                    ? 'btn-danger'
                    : 'btn-outline-primary'
                "
                @click.prevent="setRepeat(0)"
              >
                <font-awesome-icon icon="times" />
                {{ $t("requests.follow_up_not_appointed") }}
              </button>
              <button
                class="btn"
                :class="
                  request.repeatAppointed
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                "
                @click.prevent="setRepeat(1)"
              >
                <font-awesome-icon icon="check" />
                {{ $t("requests.follow_up_appointed") }}
              </button>
            </b-button-group>
          </div>
        </b-form-group-->

        <div v-if="request.repeatAppointed" class="mr-2">
          <b-form-group :label="$t('requests.date')" prop="title">
            <date-picker v-model="request.repeatDate"></date-picker>
          </b-form-group>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="mr-2">
          <b-form-group :label="$t('requests.documents')">
            <file-upload-field
              :label="$t('requests.documents')"
              :upload-url="fileUploadUrl"
              @file-uploaded="fileUploaded"
            />
          </b-form-group>
        </div>
      </div>
      <ul class="files-block">
        <li v-for="(file, fIndex) in request.resultFiles" :key="'i' + fIndex">
          <div class="d-flex">
            <a
              v-b-tooltip.hover
              :href="file.url"
              :title="file.name"
              target="_blank"
            >
              {{ file.name + "." + file.extension }}
            </a>

            <div v-if="canDeleteResultFiles" class="ml-auto">
              <font-awesome-icon
                icon="trash"
                @click="deleteResultFile(fIndex, true)"
              />
            </div>
          </div>
        </li>
        <li v-for="(file, dIndex) in newDocuments" :key="'n' + dIndex">
          <div class="d-flex">
            <a
              v-b-tooltip.hover
              :href="file.url"
              :title="file.name"
              target="_blank"
            >
              {{ file.name + "." + file.extension }}
            </a>

            <div class="ml-auto">
              <font-awesome-icon icon="trash" @click="deleteDocument(dIndex)" />
            </div>
          </div>
        </li>
      </ul>
      <hr />

      <div>
        <b-button-group class="mb-2">
          <button
            class="btn"
            :class="getClientPaymentTypeClass(PT_CASH)"
            :disabled="getStatusType"
            @click.prevent="selectClientPaymentType(PT_CASH)"
          >
            {{ $t("requests.payment_cash") }}
          </button>
          <button
            class="btn"
            :class="getClientPaymentTypeClass(PT_BANK_TRANS)"
            :disabled="getStatusType"
            @click.prevent="selectClientPaymentType(PT_BANK_TRANS)"
          >
            {{ $t("requests.payment_bank_trans") }}
          </button>
          <button
            class="btn"
            :class="getClientPaymentTypeClass(PT_PAYMENT_LINK)"
            :disabled="getStatusType"
            @click.prevent="selectClientPaymentType(PT_PAYMENT_LINK)"
          >
            {{ $t("requests.payment_payment_link") }}
          </button>
        </b-button-group>

        <!-- VAT -->

        <div class="ml-2 vatFee">
          <b-form-group :label="$t('requests.vat_fee')" prop="title">
            <b-form-input
              ref="vatFee2"
              :value="request.vatFee"
              placeholder=""
              required
              @change.native="onChange(request.vatFee, $event)"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <hr />

      <h3>
        {{ $t("requests.service_price") }}: {{ servicesSum(request) }}
        {{ $t("common.cur") }} | Tips: ... {{ $t("common.cur") }} |
        {{ $t("requests.vat_fee") }}: {{ request.vatFee }}
        {{ $t("common.cur") }} | Commission: ... {{ $t("common.cur") }} |
        {{ $t("requests.request_sum") }}: {{ totalSum(request) }}
        {{ $t("common.cur") }} |

        <!--{{ $t("requests.assignee_earn") }}:
        {{ earnTotalSum(request) }} | {{ $t("requests.assignee_in_hand") }}:
        {{ calcTotalProfitSum(request) }} |

        <span v-if="request.fromExchange">{{
          $t("requests.from_exchange_without_record_msg")
        }}</span>
        <span v-else-if="recordDiffSum > 0"
          >{{ $t("requests.before_record") }}: {{ recordDiffSum }}</span
        >
        <span v-else
          >{{ $t("requests.record") }}:<font-awesome-icon icon="star"
        /></span>-->
      </h3>
      <hr />

      <b-form-group :label="$t('requests.assignee_comment')" prop="title">
        <b-form-textarea
          v-model="request.assigneeComment"
          tabindex="1"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-button
        variant="primary"
        :disabled="loading"
        @click="createOrUpdate('ok')"
      >
        <font-awesome-icon icon="check" />
        {{ $t("requests.close_request") }}
      </b-button>

      <b-button
        v-if="canDecline"
        variant="danger"
        class="ml-2"
        @click="createOrUpdate('decline')"
      >
        <font-awesome-icon icon="exclamation-triangle" />
        {{ $t("requests.request_declined") }}
      </b-button>

      <button
        v-if="id !== 'new'"
        class="ml-2 btn btn-default"
        @click="cancelUpdate"
      >
        <font-awesome-icon icon="times" />
        {{ $t("common.cancel") }}
      </button>

      <div v-if="false">
        <router-link
          :to="{ name: 'request-list' }"
          class="btn btn-default ml-1 mr-1"
        >
          <font-awesome-icon icon="arrow-left" />
          {{ $t("common.back_to_list") }}
        </router-link>

        <b-button v-if="!request.archived" variant="danger" @click="archive">
          <font-awesome-icon icon="trash" />
          {{ $t("common.archive") }}
        </b-button>
        <b-button v-else variant="danger" @click="unarchive">
          <!--                    <font-awesome-icon icon="archive"/>-->
          {{ $t("common.unarchive") }}
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { getUserName } from "../../../../crm/common.js";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import {
  calcEquipmentSum,
  calcProfitSum,
  calcTotalProfitSum,
  calcNetProfit,
  earnSum,
  earnTotalSum,
  recordDiff,
  recordTotalDiff,
  recordString,
  servicesSum,
  totalSum,
  STATUS_DECLINE,
  STATUS_DONE,
  PT_CASH,
  PT_BANK_TRANS,
  PT_PAYMENT_LINK,
  getRequestStatusTitle,
  getRequestStatus,
} from "@/modules/pest/decorators/requests.js";
import { EQUIPMENT_PAYMENT_PERCENT } from "@/modules/pest/decorators/equipments.js";
import DatePicker from "@/components/shared/DatePicker.vue";

import {
  canDeclineRequests,
  canDeleteResultFiles,
  canSaveResultWithoutDocuments,
} from "@/modules/users/access/common";
import FileUploadField from "@/components/shared/FileUploadField.vue";
import clone from "just-clone";

export default {
  name: "RequestResultForm",
  directives: { mask: VueMaskDirective },
  components: {
    DatePicker,
    FileUploadField,
  },
  props: ["requestModel", "dataLoaded"],
  data() {
    return {
      EQUIPMENT_PAYMENT_PERCENT,
      dateFormat: "DD.MM.YYYY",
      timeFormat: "HH:MM",
      labelWidth: "150px",
      loading: false,
      statusesList: [],
      resourcesList: [],
      servicesList: [],
      productList: [],
      resources: [],
      request: {},
      newDocuments: [],
      PT_CASH,
      PT_BANK_TRANS,
      PT_PAYMENT_LINK,
    };
  },
  computed: {
    getStatusType() {
      let status = getRequestStatus(this.request);
      let doneStatuses = ["Success", "Failed"];
      return doneStatuses.includes(status);
    },
    clientName() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    managerName() {
      return this.request && this.request.managerId && this.request.manager
        ? getUserName(this.request.manager)
        : "";
    },
    assigneeName() {
      return this.request && this.request.assigneeId && this.request.assignee
        ? getUserName(this.request.assignee)
        : "";
    },
    id() {
      return this.request.id;
    },
    equipmentSum() {
      return this.calcEquipmentSum(this.request);
    },
    name() {
      let name = this.request.name
        ? this.request.name
        : this.$t("requests.new_request");
      return name ? name : "Дело";
    },

    recordDiffSum() {
      return recordTotalDiff(this.request, this.request.recordSum);
    },
    canDecline() {
      return canDeclineRequests(this.$store.state.user);
    },
    canDeleteResultFiles() {
      return canDeleteResultFiles(this.$store.state.user);
    },
    canSaveResultWithoutDocuments() {
      return canSaveResultWithoutDocuments(this.$store.state.user);
    },
    fileUploadUrl() {
      return "/pest/request/result-file?requestId=" + this.request.id;
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = clone(obj);
      },
    },
  },
  created() {
    this.fetchStatuses();
    this.fetchServices(this.requestModel.isFirstTime);
    this.fetchProducts();
    this.fetchResources();
  },
  methods: {
    onChange(oldValue, event) {
      let isNumber = Number(event.target.value);
      if (isNaN(isNumber) || event.target.value === "" || isNumber < 0) {
        event.target.value = "";
        this.request.vatFee = "";
        event.target.classList.add("active-validate");
      } else {
        event.target.classList.remove("active-validate");
        this.request.vatFee = event.target.value;
      }
    },
    earnSum,
    earnTotalSum,
    calcEquipmentSum,
    calcProfitSum,
    calcTotalProfitSum,
    calcNetProfit,
    servicesSum,
    totalSum,
    getClientName,
    recordString,
    recordDiff,
    getUserName,
    getStatusTitle,
    getClientNameWithContacts,
    fileUploaded({ file }) {
      this.newDocuments.push(file);
    },
    revertNegative(value, index) {
      if (this.request.resources[index][value] < 0)
        this.request.resources[index][value] =
          this.request.resources[index][value] * -1;
    },
    validateForm(form) {
      let documentsCount = this.request.resultFiles.concat(
        this.newDocuments
      ).length;
      if (!documentsCount && !this.canSaveResultWithoutDocuments) {
        this.$bvToast.toast(this.$t("files.upload_agreement_file"), {
          variant: "danger",
        });
      }
      return (
        (documentsCount || this.canSaveResultWithoutDocuments) &&
        form.checkValidity()
      );
    },
    deleteResultFile(fileIndex) {
      this.request.resultFiles.splice(fileIndex, 1);
    },
    deleteDocument(index) {
      this.newDocuments.splice(index, 1);
    },
    clientNameF() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/request/result/" + this.id;
      if (this.loading) {
        return;
      }

      this.request.resultFilesIds = this.request.resultFiles
        .concat(this.newDocuments)
        .map((f) => f["id"]);

      this.loading = true;
      this.request.equipmentSum = this.equipmentSum;

      this.$http
        .put(updateUrl, this.request)
        .then((response) => {
          this.request = response.data;
          this.newDocuments = [];
          this.loading = false;
          this.$emit("update-request-info", this.request);
          // this.$router.push({name: 'request-view', params: {id: this.request.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("requests.request_closed"),
            request: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("results.error_during_saving_results"),
              request: "danger",
            });
          }
        });
    },
    fetchResources: function () {
      this.resourcesList = [];
      let resourcesUrl = "/pest/resources";
      let params = {
        all: true,
        onlyEnabled: true,
      };
      this.$http
        .get(resourcesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.resourcesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
          this.resourcesList.unshift({
            value: "new",
            text: this.$t("resources.add_new_resource"),
          });
          for (let item of this.request.resources) {
            this.resourcesList.push({
              value: item.resource.id,
              text: this.getStatusTitle(item.resource),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchServices: function (isFirstTime) {
      this.servicesList = [];
      let servicesUrl = "/pest/services";
      let params = {
        all: true,
        inspection: isFirstTime === -1,
      };
      this.$http
        .get(servicesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.servicesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchProducts: function () {
      this.productList = [];
      let productUrl = "/pest/products";
      let params = {
        all: true,
      };
      this.$http
        .get(productUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.productList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchStatuses: function () {
      this.statusesList = [];
      // this.statusesList.push({value: '', text: 'Все'});
      let statusesUrl = "/crm/statuses";
      let params = {
        page: this.page,
      };
      this.$http
        .get(statusesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.statusesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    createOrUpdate: function (status) {
      if (status === "decline") {
        this.request.status = STATUS_DECLINE;
      } else {
        this.request.status = STATUS_DONE;
      }
      let form = document.getElementById("request-result-form");
      let valid = this.validateForm(form);
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.update();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },

    handleClientSubmit(result) {
      this.request.clientId = result.id;
    },
    getResultClientAutocompleteValue(client) {
      return this.getClientNameWithContacts(client);
    },
    searchClient(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/crm/clients/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    handleGroupSubmit(result) {
      this.request.groupId = result.id;
    },
    getResultGroupAutocompleteValue(group) {
      return this.getGroupTitle(group);
    },
    searchGroup(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let groupsUrl = "/law/group/search-groups";
        this.loading = true;
        this.$http
          .get(groupsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    handleManagerSubmit(result) {
      this.request.managerId = result.id;
    },
    getResultManagerAutocompleteValue(manager) {
      return this.getUserName(manager);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    handleAssigneeSubmit(result) {
      this.request.assigneeId = result.id;
    },
    getResultAssigneeAutocompleteValue(assignee) {
      return this.getUserName(assignee);
    },
    searchAssignee(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    setResourceTitle(resource, rIndex) {
      if (resource.resourceId === "new") {
        this.$emit("open-resource-popup-form", rIndex);
        return false;
      }
    },
    removeResource(index) {
      this.request.resources.splice(index, 1);
    },
    addResource() {
      let blankResource = {
        resourceId: "",
        title: "",
        count: "",
      };
      this.request.resources.push(blankResource);
    },
    setServiceTitle(service) {
      let i = this.servicesList
        .map(function (e) {
          return e.value;
        })
        .indexOf(service.id);
      service.title = this.servicesList[i].text;
    },
    setProductTitle(product) {
      let i = this.productList
        .map(function (e) {
          return e.value;
        })
        .indexOf(product.id);
      product.title = this.productList[i].text;
    },
    removeService(index) {
      this.request.services.splice(index, 1);
    },
    removeProduct(index) {
      this.request.products.splice(index, 1);
    },
    addProduct() {
      let blankProduct = {
        id: "",
        title: "",
        count: "",
      };
      this.request.products.push(blankProduct);
    },
    addService() {
      let blankService = {
        serviceId: "",
        title: "",
        count: "",
      };
      this.request.services.push(blankService);
    },
    setRepeat(status) {
      this.request.repeatAppointed = status;
    },
    getClientPaymentTypeClass(type) {
      if (this.request.paymentType === type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientPaymentType(type) {
      this.request.paymentType = type;
      //this.loadPrices();
    },
  },
};
</script>

<style lang="scss">
.vatFee {
  max-width: 200px;
}

tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.resource-select {
  min-width: 150px;
}

.resource-count {
  width: 50px;
}

.service-select {
  width: 180px !important;
}

.service-count {
  width: 50px;
}
.product-row button {
  margin-top: 19px;
}
.mtfr {
  margin-top: 28px;
  float: right;
}

.active-validate.form-control:invalid,
.active-validate.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>

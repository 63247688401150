<template>
  <div>
    <strong>{{ $t("requests.comment") }}:</strong> {{ request.comment }} <br />
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogDeclined",
  props: ["log"],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>

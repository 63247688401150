<template>
  <div class="ibox">
    <div class="ibox-title search-ibox-wrapper" :class="{ editing: editing }">
      <div class="search">
        <div class="d-flex align-items-center" style="overflow-x: auto">
          <div class="mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
          </div>

          <div class="mr-2">
            <date-picker v-model="search.date" @change="fetchData" />
          </div>

          <div v-if="!loading" class="mr-2">
            {{ $t("board_common.pagination_total") }}:
            <span v-if="!filter.search"> {{ pagination.total }} </span>
            <span v-else> {{ filteredCount }} из {{ pagination.total }}</span>
          </div>

          <div v-if="canChangeAssignee" class="align-self-center ml-auto">
            <button
              v-if="editing"
              class="btn btn-danger ml-1"
              @click="toggleEdit"
            >
              <font-awesome-icon icon="exclamation-triangle" />&nbsp;{{
                $t("common.updating_enabled")
              }}
            </button>
            <button
              v-if="!editing"
              class="btn btn-outline-primary ml-1"
              @click="toggleEdit"
            >
              <font-awesome-icon icon="check" />&nbsp;
              {{ $t("common.updating_disabled") }}
            </button>
          </div>

          <div class="ml-auto">{{ $t("search.filter") }}:</div>

          <div class="ml-2">
            <b-form-input
              v-model="filter.search"
              :placeholder="$t('search.filter_prompt')"
              tabindex="2"
              @keyup="filterRequests"
            >
            </b-form-input>
          </div>
        </div>
        <dispatching-stats-by-pest :requests="requests" />
      </div>
    </div>

    <div class="ibox-content gray-bg pl-0 pr-0 mb-0 pb-0 requests-box">
      <div class="cont">
        <vuescroll :style="{ width: '100%', height: '100%' }">
          <div class="d-flex align-items-stretch">
            <div
              v-for="(requestsColumn, r2Index) in allRequests"
              :key="r2Index + 'col'"
              class="ibox ml-1 mr-1 mb-0"
              :class="assigneeColClass(requestsColumn.assignee)"
            >
              <div
                class="ibox-title pl-2 pr-2 pt-1 pb-1 assigneeHeader"
                :class="assigneeHeaderClass(requestsColumn.assignee)"
              >
                <!--                 :style="{'border-bottom': '3px solid ' + (requests.assignee.color?requests.assignee.color:'')}"-->
                <div class="man-col-header-wrapper">
                  <div class="man-col-header h6 pb-0 pt-1">
                    <div v-if="requestsColumn.assignee.id === STATUS_DECLINE">
                      <div class="user-header-name">
                        {{ $t("statuses.declined") }}&nbsp;<small
                          >({{ requestsColumn.items.length }})</small
                        >
                      </div>
                    </div>
                    <div v-else-if="!requestsColumn.assignee.id">
                      <div class="user-header-name">
                        {{ $t("statuses.undispatched") }}&nbsp;<small
                          >({{ requestsColumn.items.length }})</small
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="user-header-name d-flex">
                        <div>
                          {{
                            getAssigneeName(requestsColumn.assignee)
                          }}&nbsp;<small
                            >({{ requestsColumn.items.length }})</small
                          >
                        </div>

                        <button
                          v-if="requestsColumn.assignee.blocked === BLOCKED"
                          v-b-tooltip.hover.right
                          class="btn btn-xs btn-danger ml-auto"
                          :title="$t('deposit.assignee_blocked')"
                          @click.prevent="
                            unblockAssignee(requestsColumn.assignee)
                          "
                        >
                          <font-awesome-icon
                            v-if="unblocking === requestsColumn.assignee.id"
                            icon="rotate"
                            spin
                          />
                          <font-awesome-icon v-else icon="lock" fixed-width />
                        </button>
                        <button
                          v-else-if="
                            requestsColumn.assignee.blocked === UNBLOCKED
                          "
                          v-b-tooltip.hover.right
                          class="btn btn-xs btn-danger ml-auto"
                          :title="$t('deposit.assignee_unblocked')"
                          @click.prevent
                        >
                          <font-awesome-icon icon="lock-open" fixed-width />
                        </button>
                        <button
                          v-else-if="
                            requestsColumn.assignee.initialPledgeRequired
                          "
                          v-b-tooltip.hover.right
                          class="btn btn-xs btn-info ml-auto"
                          :title="$t('deposit.new')"
                          @click.prevent
                        >
                          <font-awesome-icon icon="money-bill" fixed-width />
                        </button>

                        <button
                          v-else-if="requestsColumn.assignee.isHalfPledgeUsed"
                          v-b-tooltip.hover.right
                          class="btn btn-xs btn-warning ml-auto btn-no-hover cursor-default"
                          :title="$t('deposit.used_50_deposit')"
                          @click.prevent
                        >
                          <font-awesome-icon icon="dollar-sign" fixed-width />
                        </button>
                        <button
                          v-else
                          v-b-tooltip.hover.right
                          class="btn btn-xs btn-green ml-auto btn-no-hover cursor-default"
                          :title="$t('deposit.no_issues')"
                          @click.prevent
                        >
                          <font-awesome-icon icon="dollar-sign" fixed-width />
                        </button>
                      </div>
                      <div
                        v-if="requestsColumn.assignee.timeSlots"
                        class="user-time-slot-wrapper mt-1"
                      >
                        <div
                          v-for="(timeSlot, tIndex) in requestsColumn.assignee
                            .timeSlots"
                          :key="'t' + tIndex"
                          :class="{
                            'user-time-slot': true,
                            half: timeSlot.minutes === '30',
                            busy: isTimeSlotBusy(
                              requestsColumn.items,
                              timeSlot.hours,
                              timeSlot.minutes
                            ),
                            'user-busy': isTimeSlotUserBusy(
                              requestsColumn,
                              timeSlot.hours,
                              timeSlot.minutes
                            ),
                          }"
                          @click.prevent="
                            toggleTimeSlot(requestsColumn.assignee, timeSlot)
                          "
                        >
                          {{ timeSlot.hours }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <vuescroll class="foo" :style="{ width: '100%', height: '72vh' }">
                <div class="ibox-content pl-2 pr-2">
                  <draggable
                    class="list-group"
                    :list="requestsColumn.items"
                    group="people"
                    :disabled="!editing"
                    handle=".handle"
                    @add="updateAssignee(requestsColumn.assignee)"
                  >
                    <request-card
                      v-for="(request, rIndex) in requestsColumn.items"
                      :key="rIndex + '-r'"
                      :request="request"
                      :dispatching-board="true"
                    ></request-card>
                  </draggable>
                </div>
              </vuescroll>
            </div>
          </div>
        </vuescroll>
      </div>
    </div>
    <request-time-popup></request-time-popup>
    <request-assignee-popup
      :town-id="search.townId"
      :assignees-list="assigneeList"
      :requests="requests"
    ></request-assignee-popup>
  </div>
</template>

<script>
import {
  checkFilter,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestDeadline,
  getRequestManagerName,
  getRequestStatus,
  getRequestTitle,
  getRequestUpdateTime,
  STAGE_DEAL,
  STATUS_DECLINE,
} from "@/modules/pest/decorators/requests.js";
import { getAssigneeName, getUserName } from "@/modules/crm/common.js";
import { TYPE_WORKER } from "@/modules/users/decorators/users.js";
import RequestCard from "@/modules/pest/views/requests/card/RequestCard.vue";
import draggable from "vuedraggable";
import dayjs from "dayjs";
import {
  SCHEDULE_ACTIVE_DAY_OFF,
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
} from "@/modules/pest/decorators/schedule.js";
import RequestTimePopup from "@/modules/pest/views/requests/dispatching/_request/RequestTimePopup.vue";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns.js";
import RequestAssigneePopup from "@/modules/pest/views/requests/dispatching/_request/RequestAssigneePopup.vue";
import { canChangeAssignee } from "@/modules/users/access/common.js";
import {
  BLOCKED,
  REGULAR,
  UNBLOCKED,
} from "@/modules/users/decorators/userDecorator.js";
import DispatchingStatsByPest from "@/modules/pest/views/requests/dispatching/DispatchingStatsByPest";
import DatePicker from "@/components/shared/DatePicker";
import vuescroll from "vuescroll";
import {
  isTimeSlotBusy,
  isTimeSlotUserBusy,
} from "@/modules/pest/helpers/dispatching/timeslots";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "RequestDispatchingBoard",
  components: {
    TownFilter,
    DispatchingStatsByPest,
    RequestAssigneePopup,
    RequestTimePopup,
    DatePicker,
    RequestCard,
    draggable: draggable,
    vuescroll,
  },
  data() {
    return {
      BLOCKED,
      UNBLOCKED,
      REGULAR,
      STATUS_DECLINE,
      MSK,
      SPB,
      DXB,
      allRequests: [],
      requests: [],
      search: {
        title: "",
        date: "",
        townId: 1,
        managerId: "",
      },
      filteredCount: 0,
      filter: {
        search: "",
      },
      townList: [],
      assigneeList: [],
      loading: true,
      unblocking: false,
      loadingManagers: false,
      loadingRequests: false,
      savingAssignee: false,
      editing: false,
      archived: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      dateFormat: "DD.MM.YYYY",
    };
  },
  computed: {
    canChangeAssignee() {
      return canChangeAssignee(this.$store.state.user);
    },
    assigneeHeaderColor(assignee) {
      // if(assig)
      return assignee.color ? assignee.color : "";
    },
    unassignedRequest() {
      return this.requests.filter((item) => {
        return !item.assigneeId;
      });
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
    document.getElementById("page-wrapper").classList.add("board");
    setInterval(() => {
      this.fetchData();
    }, 60 * 1 * 1000); // обновляем раз в 5 минут
  },
  destroyed() {
    document.getElementById("page-wrapper").classList.remove("board");
  },
  created() {
    this.search.date = dayjs().format("YYYY-MM-DD");
    this.$eventHub.$on("assignee-changed", this.requestAssigneeChanged);
  },
  beforeDestroy() {
    this.$eventHub.$off("assignee-changed");
  },
  methods: {
    getUserName,
    getAssigneeName,
    getRequestTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    checkFilter,
    getRequestDeadline,
    getRequestUpdateTime,
    assigneeColClass(assignee) {
      let classes = [];
      if (assignee.blocked > 0) {
        classes.push("blocked");
      }
      return classes;
    },
    unblockAssignee(assignee) {
      this.unblocking = assignee.id;
      let usersUrl = "/pest/pledge/unblock";
      let params = {
        assigneeId: assignee.id,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          this.unblocking = false;
          assignee.blocked = UNBLOCKED;
        })
        .catch((error) => {
          this.unblocking = false;
        });
    },
    assigneeHeaderClass(assignee) {
      let classes = [];
      if (assignee.scheduleActivity === SCHEDULE_ACTIVE_DUTY_1) {
        classes.push("assigneeDuty1");
      }
      if (assignee.scheduleActivity === SCHEDULE_ACTIVE_DUTY_2) {
        classes.push("assigneeDuty2");
      }
      if (assignee.scheduleActivity === SCHEDULE_ACTIVE_DAY_OFF) {
        classes.push("assigneeDayOff");
      }
      if (assignee.blocked > 0) {
        classes.push("blocked");
      }
      return classes;
    },
    assignedRequest(assigneeId) {
      return this.requests.filter((item) => {
        return item.assigneeId === assigneeId;
      });
    },
    fetchData: function () {
      this.fetchManagers();
      this.fetchRequests();
    },
    processManagersAndRequests() {
      if (!this.loadingManagers && !this.loadingRequests) {
        this.processManagerList();
        this.filterRequests();
      }
    },
    fetchManagers: function () {
      if (this.loadingManagers) {
        return;
      }
      this.loadingManagers = true;
      this.assigneeList = [];
      let usersUrl = "/pest/schedule/assignees";
      let params = {
        townId: this.search.townId,
        date: this.search.date,
        type: TYPE_WORKER,
        // limit: 5,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          this.assigneeList = response.data;
          this.loadingManagers = false;
          this.processManagersAndRequests();
          // this.processManagerList();
        })
        .catch((error) => {
          this.loadingManagers = false;
          this.processRequestError(error);
        });
    },
    processManagerList() {
      this.allRequests = [];
      this.allRequests.push({
        assignee: { id: 0 },
        items: [],
      });
      this.allRequests.push({
        assignee: { id: STATUS_DECLINE },
        items: [],
      });
      for (let assignee of this.assigneeList) {
        let id = assignee.id ? assignee.id : 0;
        this.allRequests.push({
          assignee: assignee,
          items: [],
        });
      }
    },
    filterRequests() {
      let i = 0;
      let count = 0;
      for (let info of this.allRequests) {
        let groupAssigneeId = info.assignee.id;
        info.items = this.requests
          .filter((request) => {
            let requestAssigneeId = request.assigneeId ? request.assigneeId : 0;
            if (request.status === STATUS_DECLINE) {
              requestAssigneeId = STATUS_DECLINE;
            }
            let allTechnicians = [];
            allTechnicians.push(...request.extraTechnicians);
            allTechnicians.push({ id: requestAssigneeId });
            let sameAssignee = !allTechnicians.every((element) => {
              return element.id !== groupAssigneeId;
            });
            let filterCheck = true;
            if (this.filter.search) {
              filterCheck = this.checkFilter(request, this.filter.search);
            }
            if (sameAssignee && filterCheck) {
              count++;
            }
            return sameAssignee && filterCheck;
          })
          .sort((a, b) => a.time - b.time);
        this.filteredCount = count;
      }
    },
    fetchRequests: function () {
      if (this.loadingRequests) {
        return;
      }
      this.loading = true;
      this.loadingRequests = true;
      let requestsUrl = "/pest/request/dispatching";

      let params = {
        stage: STAGE_DEAL,
        searchNames: this.search.title,
        townId: this.search.townId,
        date: this.search.date,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          //clear
          this.requests = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          this.loadingRequests = false;
          this.processManagersAndRequests();
          // this.filterRequests();
          this.loading = false;
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.loadingRequests = false;
          this.processRequestError(error);
        });
    },
    isRequestInScheduleTime(request, assignee) {
      let hours = dayjs.unix(request.time).format("HH");
      if (!assignee.timeSlots) {
        return false;
      }
      // let isInSchedule = assignee.timeSlots && assignee.timeSlots.includes(hours);
      let isInSchedule = false;
      for (let i = 0; i < assignee.timeSlots.length; i++) {
        // noinspection EqualityComparisonWithCoercionJS
        if (assignee.timeSlots[i] == hours) {
          isInSchedule = true;
        }
      }
      return isInSchedule;
    },
    updateAssignee(newAssignee) {
      let targetGroup = false;
      for (let group of this.allRequests) {
        if (group.assignee.id === newAssignee.id) {
          targetGroup = group;
          // break;
        }
      }
      let changedRequest = false;
      if (targetGroup) {
        for (let request of targetGroup.items) {
          let rAId = request.assigneeId ? request.assigneeId : 0;
          let nAId = newAssignee.id ? newAssignee.id : 0;
          if (rAId !== nAId) {
            changedRequest = request;
            request.assignee = newAssignee;
            request.assigneeId = newAssignee.id;
          }
        }
      }
      if (changedRequest) {
        this.saveAssigneeId(changedRequest, true);
        if (!this.isRequestInScheduleTime(changedRequest, newAssignee)) {
          this.$bvToast.toast(this.$t("logistics.msg_no_working_at_time"), {
            title: this.$t("logistics.msg_time_is_not_scheduled"),
            variant: "info",
          });
        }
        this.$bvToast.toast(this.$t("logistics.msg_assignee_without_skill"), {
          title: this.$t("logistics.msg_no_skill"),
          variant: "info",
        });
      }
    },

    saveAssigneeId(request, force) {
      let updateUrl;
      updateUrl = "/pest/request/assignee/" + request.id;
      if (this.savingAssignee && !force) {
        return;
      }
      this.savingAssignee = true;
      let params = {
        id: request.id,
        assigneeId: request.assigneeId,
      };
      this.$http
        .put(updateUrl, params)
        .then((response) => {
          this.request = response.data;
          this.savingAssignee = false;
          this.$emit("update-request-info", this.request);
          // this.$router.push({name: 'request-view', params: {id: this.request.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("logistics.msg_assignee_updated"),
            request: "success",
          });
        })
        .catch((error) => {
          this.savingAssignee = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("logistics.msg_assignee_not_updated"),
              request: "danger",
            });
          }
        });
    },

    isTimeSlotUserBusy(requests, hours, minutes) {
      if (!requests.assignee.timeSlots) {
        return false;
      }
      for (let slot of requests.assignee.timeSlots) {
        if (slot.hours === hours && slot.minutes === minutes) {
          return slot.busy;
        }
      }
      return false;
    },
    isTimeSlotBusy(requests, hours, minutes) {
      if (!requests.length) {
        return false;
      }
      for (let request of requests) {
        let start =
          dayjs.unix(request.time).utcOffset("+03:00").format("HH").toString() *
          60;
        let end = start + request.duration - 1;

        let minutesInt = parseInt(minutes);
        let nowStart = parseInt(hours) * 60 + minutesInt;
        let nowEnd = (parseInt(hours) + 2) * 60 + minutesInt;

        if (start <= nowStart && nowStart <= end) {
          return true;
        }
        if (start <= nowEnd && nowEnd <= end) {
          return true;
        }
      }
      return false;
    },
    toggleTimeSlot(assignee, timeSlot) {
      if (!this.editing) {
        return;
      }
      if (!assignee.timeSlots) {
        return;
      }
      for (let slot of assignee.timeSlots) {
        if (
          slot.hours === timeSlot.hours &&
          slot.minutes === timeSlot.minutes
        ) {
          slot.busy = slot.busy ? 0 : 2;
          this.saveUserTimeSlot(
            assignee.id,
            slot.hours,
            slot.minutes,
            slot.busy
          );
          return;
        }
      }
    },
    saveUserTimeSlot(assigneeId, hours, minutes, status) {
      let updateUrl = "/pest/schedule/set-time-slot";
      this.loading = true;
      let params = {
        assigneeId: assigneeId,
        date: this.search.date,
        slot: hours + ":" + minutes,
        active: status,
      };
      this.$http
        .post(updateUrl, params)
        .then((response) => {})
        .catch((error) => {
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("logistics.msg_time_cannot_be_updated"),
              request: "danger",
            });
          }
        });
    },

    log(e, list) {
      console.log(1);
      console.log(e);
      console.log(list);
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },

    requestAssigneeChanged(info) {
      //changing from modal window
      let request = info.request;
      let assignee = info.assignee;
      let oldAssigneeId = info.oldAssigneeId ? info.oldAssigneeId : 0;
      let newAssigneeId = request.assigneeId;
      for (let block of this.allRequests) {
        let compareAssigneeId = block.assignee.id ? block.assignee.id : 0;

        //add to new list
        if (newAssigneeId === compareAssigneeId) {
          block.items.push(request);
          block.items.sort((a, b) => a.time - b.time);
        }

        //remove from old list
        if (oldAssigneeId === compareAssigneeId) {
          for (let id in block.items) {
            if (block.items[id].id === request.id) {
              block.items.splice(id, 1);
              break;
            }
          }
        }
      }

      //saving data on server
      this.saveAssigneeId(request, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
}

.request-organization {
  font-size: smaller;
  color: gray;
}

.man-col {
  border: 1px solid #cecece;
  //border-right: 1px solid #cecece;
  //padding-left: 10px;
  //padding-right: 10px;
}

.man-col-header {
  padding-bottom: 10px;
  //border-bottom: 3px solid #d09e23;
  max-width: 250px;
  text-overflow: ellipsis;
}

.man-col-header-wrapper {
  //position: -webkit-sticky;
  //position: sticky;
  //padding-top: 10px;
  //top: 0;
  background: #ffffff;
  //border: 1px gray;

  height: 60px;
}

.list-group {
  min-width: 180px;
  max-width: 250px;
  min-height: 250px;
  padding-bottom: 100px;
}

.ibox > .ibox-content {
  //height: 77vh;
  //max-height: 77vh;
}

.ibox > .ibox-content > .cont {
  //height: 77vh;
  //max-height: 77vh;
}

.ibox-content {
  min-height: 72vh;
}

.cont {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 81vh;
  max-height: 81vh;
}

.requests-box {
  overflow-y: hidden !important;
}

.search-ibox-wrapper {
  padding-right: 15px !important;
}

.man-col-header-wrapper {
  width: 240px;
}

.user-time-slot-wrapper {
  width: 250px;
  font-size: smaller;
}

.user-time-slot {
  font-size: smaller;
  float: left;
  margin-right: 2px;
}

.time-slot-half {
  color: #c5c5c5;
}

.time-slot-half:hover {
  color: inherit;
}

.user-time-slot.busy .time-slot-half {
  color: lightgray;
}

.user-time-slot.busy {
  text-decoration: line-through;
  color: lightgray;
}

.user-time-slot.user-busy,
.user-time-slot.user-busy .time-slot-half {
  text-decoration: line-through;
  color: #ee7981;
}

.user-time-slot {
  cursor: pointer;
}

.user-header-name {
  height: 25px;
  overflow-y: hidden;
}

.assigneeHeader {
  border-bottom: 3px solid gray;
}

.assigneeHeader.assigneeDuty1 {
  border-bottom: 3px solid #f5be25;
}

.assigneeHeader.assigneeDuty2 {
  border-bottom: 3px solid #ee7981;
}

.assigneeHeader.assigneeDayOff {
  border-bottom: 3px solid #17a2b8;
}

.ibox-title.editing {
  background: rgba(245, 150, 155, 0.85);
}

.ibox.blocked .ibox-title,
.ibox.blocked .man-col-header-wrapper {
  background: #ee7981;
}

.cursor-default {
  cursor: default !important;
}
</style>
<style>
.cont > .__vuescroll > .__rail-is-vertical {
  display: block !important;
}
</style>

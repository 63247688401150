<template>
  <div class="ibox mb-1">
    <div class="ibox-content">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <h2>Системное</h2>
          <ul>
            <li>
              <a class="" @click.prevent="testErrorRequest">
                Test Error Sentry Call
                <span class="hint">/app/site/e500</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServiceMenu",
  methods: {
    testErrorRequest() {
      let errorUrl = "/app/site/e500";
      this.$http.get(errorUrl, {});
    },
  },
};
</script>

<style scoped>
h2 {
  padding-left: 2px;
}

ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

ul li a {
  display: block;
  margin-left: 0;
  margin-bottom: 10px;
  padding: 5px;
}

ul li:hover {
  background: #f5f4f4;
}

span.hint {
  display: block;
  color: #929191;
  font-size: smaller;
}
</style>

<template>
  <b-form
    id="pest-penalty-form"
    ref="penalty"
    :model="penalty"
    class="pest-penalty-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-textarea v-model="penalty.title" rows="3" tabindex="1" />
          </b-form-group>
          <b-form-group :label="$t('penalties.sum')" prop="sum">
            <b-form-input
              v-model="penalty.sum"
              placeholder=""
              tabindex="2"
            ></b-form-input>
          </b-form-group>
          <b-form-checkbox v-model="penalty.dismissal" value="1" tabindex="3"
            >{{ $t("penalties.dismissal") }}
          </b-form-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.comment')" prop="title">
            <b-form-textarea
              v-model="penalty.comment"
              rows="3"
              :placeholder="$t('common.description')"
              tabindex="3"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="penalty.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deletePenalty"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";

export default {
  name: "PenaltyForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["penalty"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    id() {
      return this.penalty.id;
    },
    name() {
      let name = this.penalty.name
        ? this.penalty.name
        : this.$t("penalties.new_penalty");
      return name ? name : "penalty";
    },
    managerName() {
      return this.penalty.managerId && this.penalty.manager
        ? getUserName(this.client.manager)
        : "";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/penalties/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.penalty)
        .then((response) => {
          this.penalty = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-penalty-info", this.penalty);
          // this.$router.push({name: 'pest-penalty-view', params: {id: this.penalty.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("penalties.penalty_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/penalties";
      this.loading = true;
      this.$http
        .post(updateUrl, this.penalty)
        .then((response) => {
          this.penalty = response.data;
          this.$eventHub.$emit("update-pest-penalty-info", this.penalty);
          this.$router.push({
            name: "pest-penalty-update",
            params: { id: this.penalty.id },
          });
          this.$bvToast.toast(this.$t("penalties.penalty_added"), {
            title: this.$t("penalties.penalty_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("penalties.cant_create_penalty"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-penalty-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deletePenalty() {
      if (confirm(this.$t("penalties.delete_penalty_question"))) {
        let deleteUrl = "/pest/penalties/" + this.penalty.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-penalty-list" });
            this.$bvToast.toast(this.$t("penalties.penalty_deleted"), {
              title: this.$t("penalties.penalty_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("penalties.cant_delete_penalty"),
                variant: "danger",
              });
            }
          });
      }
    },

    getManagerName(user) {
      return getUserName(user);
    },
    handleManagerSubmit(result) {
      this.penalty.managerId = result.id;
    },
    getResultManagerAutocompleteValue(user) {
      return this.getManagerName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>

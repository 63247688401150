import t from "@/i18n";

const getStatusTitle = function (status) {
  if (!status || !status.title) {
    return t("common.not_set");
  }
  return status.title.trim();
};

export { getStatusTitle };

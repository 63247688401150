<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>{{ $t("shopResources.shopResource_list") }}</h1>
      <div class="mb-2 d-flex">
        <router-link
          :to="{ name: 'pest-shop-resource-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          {{ $t("shopResources.add_shopResource") }}
        </router-link>

        <shop-resource-import
          class="ml-2"
          @updated="fetchData"
        ></shop-resource-import>
      </div>

      <div class="search">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-form-group :label="$t('common.title')">
              <b-form-input
                v-model="search.title"
                tabindex="3"
                @change="fetchData"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;{{ $t("search.find") }}
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            {{ $t("board_common.pagination_total") }}:
            {{ pagination.totalCount }}
          </div>
        </div>
      </div>

      <b-table
        v-if="shopResources.length"
        responsive
        striped
        hover
        condensed
        :items="shopResources"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> {{ $t("common.loading") }}</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{
              name: 'pest-shop-resource-update',
              params: { id: data.item.id },
            }"
            class=""
          >
            {{ data.item.title ? data.item.title : $t("common.without_title") }}
          </router-link>
        </template>

        <template #cell(resource)="data">
          {{ data.item.resource ? data.item.resource.title : "" }}
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import ShopResourceImport from "@/modules/pest/views/shop-resource/ShopResourceImport.vue";

export default {
  name: "PestShopResourceList",
  components: { ShopResourceImport },
  data() {
    return {
      shopResources: [],
      search: {
        title: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "article",
          label: this.$t("shopResources.article"),
          sortable: false,
        },
        {
          key: "unit",
          label: this.$t("common.unit"),
          sortable: false,
        },
        {
          key: "resource",
          label: this.$t("shopResources.resource_in_crm"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("common.description"),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    fetchData: function () {
      this.loading = true;

      let shopResourcesUrl = "/pest/shop-resources";
      let params = {
        page: this.page,
        search: this.search.title,
      };
      this.$http
        .get(shopResourcesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.shopResources = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-shop-resource-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

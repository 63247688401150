<template>
  <div :data-aftercall-id="log.logId" :data-fail-id="log.failId">
    <strong>$t("requests.status") }}:</strong> {{ statusTitles[log.status] }}
    <br />
    <strong>clientIdYandex:</strong>
    {{ log.clientIdYandex }} <br /><strong>counterYandex:</strong>
    {{ log.counterYandex }} <br /><strong>clientIdGoogle:</strong>
    {{ log.clientIdGoogle }} <br /><strong>counterGoogle:</strong>
    {{ log.counterGoogle }}
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getAftercallStatusTitle } from "@/modules/pest/decorators/aftercall";
import { getUserName } from "@/modules/crm/common";
import { statusTitles } from "@/modules/pest/decorators/requests";

export default {
  name: "RequestYandexConversionLog",
  components: {},
  props: ["log"],
  data() {
    return {
      statusTitles,
      show: false,
      request: {},
    };
  },
  computed: {
    callTime() {
      return dayjs.unix(this.log.time).format("DD.MM.YYYY в HH:mm");
    },
  },
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    getAftercallStatusTitle,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>

export const T_MENU_KANBAN = "menu.kanban";
export const ru = {
  menu: {
    kanban: "Канбан",

    logistics: "Логистика",
    logistics_distribution: "Распределение",
    logistics_map: "Карта",
    logistics_load: "Нагрузка",
    logistics_daily_schedule: "Расписание на день",
    logistics_weekly_schedule: "Расписание на неделю",
    logistics_assignee_daily_schedule: "Расписание исполнителей на день",
    logistics_assignee_weekly_schedule: "Расписание исполнителей на неделю",

    schedule: "График работ",
    schedule_assignees: "График мастеров",

    finances: "Финансы",
    finances_fines: "Штрафы",
    finances_settlements: "Взаиморасчеты",
    finances_penalty_list: "Перечень штрафов",
    finances_deposit_list: "Инкассации",

    analytics: "Аналитика",
    analytics_stats: "Статистика",
    analytics_report_cpo: "Отчет cpo",
    analytics_ad_report: "Отчет по рекламе",
    analytics_report_managers: "Отчет по менеджерам",

    messages: "Сообщения",
    messages_notifications: "Уведомления",
    messages_messages: "Сообщения",

    settings: "Настройки",

    maintenance: "Служебное",
    maintenance_requests: "Заявки",
    users: "Пользователи",
    requests_in_work: "Заявки в работе",

    my_schedule: "Мое расписание",
    my_closed_request: "Закрытые заявки",
    my_stats: "Статистика",
    my_fines: "Мои штрафы",
    my_fine_list: "Перечень штрафов",

    my_notifications: "Уведомления",
    my_pledge: "Мой депозит",

    deposits: "Инкассация",
    deposits_title: "Инкассация",
    deposits_hand_over: "Сдать кассу",
    deposits_history: "История",
  },
};
export const en = {
  menu: {
    kanban: "Kanban",

    logistics: "Logistics",
    logistics_distribution: "Distribution",
    logistics_map: "Map",
    logistics_load: "Load",
    logistics_daily_schedule: "Daily schedule",
    logistics_weekly_schedule: "Weekly schedule",
    logistics_assignee_daily_schedule: "Masters daily schedule",
    logistics_assignee_weekly_schedule: "Assignee weekly schedule",

    schedule: "Schedule",
    schedule_assignees: "Assignee schedule",

    finances: "Finances",
    finances_fines: "Fines",
    finances_settlements: "Settlements",
    finances_penalty_list: "Penalty list",
    finances_deposit_list: "Deposit list",

    analytics: "Analytics",
    analytics_stats: "Stats",
    analytics_report_cpo: "CPO report",
    analytics_ad_report: "Ad report",
    analytics_report_managers: "Managers report",

    messages: "Messages",
    messages_notifications: "Notifications",
    messages_messages: "Messages",

    settings: "Settings",

    maintenance: "Maintenance",
    maintenance_requests: "Requests",
    users: "Users",
    requests_in_work: "Requests in work",

    my_notifications: "Notifications",
    my_pledge: "Pledge",

    deposits: "Deposits",
    deposits_title: "Deposits",
    deposits_hand_over: "Hand over the cash",
    deposits_history: "History",

    my_schedule: "My schedule",
    my_closed_request: "My closed request",
    my_stats: "My stats",
    my_fines: "My fines",
    my_fine_list: "Fine list",
  },
};

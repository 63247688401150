<template xmlns:comment="http://www.w3.org/1999/html">
  <div class="feed-activity-list">
    <div v-if="comments.length">
      <comment
        v-for="(comment, index) in comments"
        :key="index"
        :comment="comment"
      ></comment>
    </div>
    <div v-else>
      <p>{{ $t("comments.no_comments_yet") }}</p>
    </div>
  </div>
</template>

<script>
import Comment from "@/modules/crm/views/comment/Comment";

export default {
  name: "CommentList",
  components: {
    comment: Comment,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
    };
  },
  props: ["comments"],
  computed: {},
  watch: {},
  created: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    {{ $t("notifications.you_ve_got_the_fine_msg") }}:
    <strong>{{ sum }}{{ $t("common.cur") }}</strong> <br />
    <strong>{{ $t("requests.comment") }}:</strong>
    {{ notification.fine.comment }} <br />
    {{ notification.fine.review }}
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "NotificationUserFineApproved",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {
    sum() {
      return this.formatNumber(this.notification.fine.sum);
    },
  },
  watch: {},
  methods: {
    getUserName,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>

import ReportCPO from "../views/reports/cpo/ReportCPO.vue";
import ReportManagers from "@/modules/pest/views/reports/managers/ReportManagers";

export default [
  {
    path: "/reports/cpo",
    name: "pest-report-cpo",
    components: {
      default: ReportCPO,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/reports/managers",
    name: "pest-report-managers",
    components: {
      default: ReportManagers,
    },
    meta: { requiresAuth: true },
  },
];

export const ru = {
  services: {
    services: "Услуги",
    service_list: "Перечень услуг",
    service: "Услуга",
    add_service: "Добавить услугу",
    new_service: "Новый услугу",
    service_update: "Изменение услуги",
    service_updated: "Услуга отредактирована",
    cant_create_service: "Не удалось добавить услугу",
    cant_delete_service: "Не удалось удалить услугу",
    service_created: "Услуга добавлен",
    service_deleted: "Услуга удалена",
    delete_service_question: "Вы действительно хотите удалить услугу?",
    unit: "Единица измерения",
  },
};
export const en = {
  services: {
    services: "Services",
    service_list: "Service list",
    service: "Service",
    add_service: "Add service",
    new_service: "New service",
    change_service: "Change service",
    service_update: "Update service",
    service_updated: "Service changed",
    cant_create_service: "Can't add service",
    cant_delete_service: "Can't delete service",
    service_created: "Service added",
    service_deleted: "Service deleted",
    delete_service_question: "Do you want to delete the service?",
    unit: "Unit",
    availableInInspection: "Show in inspection",
  },
};

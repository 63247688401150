<template>
  <div :data-counter="counter">
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="list-ol" fixed-width class="mr-1" />
        <!--              Описание-->
        <a
          v-if="!blocked || blocked !== BLOCKED"
          href="#"
          class=""
          @click.prevent="openRequest"
        >
          {{ getRequestNumber(request) }}
        </a>
        <span v-else> {{ getRequestNumber(request) }}</span>
      </div>
      <div v-if="!hideHandle" class="ml-auto">
        <div class="handle">
          <font-awesome-icon icon="arrows-alt" fixed-width />
        </div>
      </div>
      <div v-if="showDuplicate" class="ml-auto">
        <div class="handle">
          <span class="label label-primary mr-2">
            {{ request.indexNumber }}</span
          >
          <a
            v-b-popover.html.hover
            href="#"
            title="Дублировать"
            @click.prevent="duplicateRequest"
          >
            <font-awesome-icon v-if="!duplicating" icon="copy" fixed-width />
            <font-awesome-icon v-else icon="rotate" spin fixed-width />
          </a>
        </div>
      </div>
    </div>
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="paperclip" fixed-width class="mr-2" />
        <span>{{ getRequestProjectTitle(request) }}</span>
      </div>
    </div>
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="arrow-up-1-9" fixed-width class="mr-2" />
        <!--              Очередность -->
        <span v-if="request.isFirstTime === 1">{{
          $t("requests.first_request_sh")
        }}</span>
        <span v-else-if="request.isFirstTime === -1">{{
          $t("requests.inspection_request_sh")
        }}</span>
        <span v-else>{{ $t("requests.follow_up_request_sh") }}</span>
      </div>

      <div
        v-if="request.callNotifications && request.callNotifications.length"
        class="ml-auto"
      >
        <a
          v-b-popover.html.hover
          href="#"
          :title="getRequestCallNotification(request)"
          @click.prevent
        >
          <font-awesome-icon icon="bell" fixed-width />
        </a>
      </div>
    </div>

    <div class="mb-1" :style="getKanbanCardTextColor(status)">
      <font-awesome-icon icon="tag" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ getRequestStatus(request) }}
    </div>

    <div>
      <!-- Телефоны -->
      <div
        v-for="(pInfo, pIndex) in phoneList"
        :key="'ph' + pIndex"
        class="mb-1"
      >
        <font-awesome-icon icon="phone" fixed-width class="mr-1" />
        {{ pInfo.phone }}
      </div>
    </div>

    <div v-if="leadBoard" class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="user-tie" fixed-width class="mr-1" />
        <!-- Исполнитель-->
        {{ getRequestManagerName(request) }}
      </div>
    </div>

    <div class="mb-1" :style="'color: ' + getRequestColor(request)">
      <font-awesome-icon icon="bug" fixed-width class="mr-1" />
      <!--              Вредитель -->
      {{ getPestTitle(request.pest) }}
    </div>

    <!-- Доп вредитель -->
    <div v-if="Object.keys(request.additionalPests).length > 0" class="mb-1">
      <font-awesome-icon icon="spider" fixed-width class="mr-1" />
      <span
        v-for="(additionalPest, index) in request.additionalPests"
        :key="'adp' + index"
      >
        {{ getPestTitle(additionalPest.pest)
        }}<template
          v-if="index !== Object.keys(request.additionalPests).length - 1"
          >,
        </template>
      </span>
    </div>

    <div class="mb-1 d-flex">
      <div>
        <!--              Время -->
        <font-awesome-icon icon="clock" fixed-width class="mr-1" />
        {{ getRequestDatetime(request) }}
      </div>
      <div v-if="false" class="ml-auto">
        <font-awesome-icon icon="stopwatch" fixed-width class="" />
        <a href="#" class="" @click.prevent="openTimePopup">
          {{ getRequestDuration(request) }}
        </a>
      </div>
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
      <!--              Сумма -->
      {{ getRequestDisplayableSum(request) }}

      <span v-if="request.earned"> / {{ request.earned }} </span>
      <span v-if="request.hasRecord">
        <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
      </span>
    </div>

    <!-- v-if="request.isOrganization && request.paymentType" -->
    <div class="mb-1">
      <font-awesome-icon icon="wallet" fixed-width class="mr-1" />
      <!-- Тип оплаты -->
      <!-- v-if="request.isOrganization && request.paymentType == PT_BANK_TRANS" -->
      <span> ({{ getPaymentTypeTitle(request) }}) </span>
    </div>

    <div v-if="leadBoard" class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="user-cog" fixed-width class="mr-1" />
        <!-- Исполнитель-->
        {{ getRequestAssigneeName(request) }}
      </div>
      <div v-if="false" class="ml-auto">
        <a class="edit-assignee-link" @click.prevent="openAssigneePopup">
          <font-awesome-icon icon="edit" fixed-width />
        </a>
      </div>
    </div>

    <!-- Доп вредитель -->
    <div v-if="Object.keys(request.extraTechnicians).length > 0" class="mb-1">
      <font-awesome-icon icon="users-gear" fixed-width class="mr-1" />
      <span
        v-for="(extraTechnician, index) in request.extraTechnicians"
        :key="'adp' + index"
      >
        {{ getRequestTechnicianName(extraTechnician)
        }}<template
          v-if="index !== Object.keys(request.extraTechnicians).length - 1"
          >,
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import {
  getCommunicationPhoneList,
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAddressFormatted,
  getRequestAssigneeName,
  getRequestBorderType,
  getRequestCallNotification,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDuration,
  getRequestManagerName,
  getRequestNumber,
  getRequestSourceName,
  getRequestStatus,
  getRequestStatusColor,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  PT_BANK_TRANS,
  servicesSum,
  STAGE_DEAL,
  STATUS_DONE,
  getRequestDisplayableSum,
  getRequestTechnicianName,
} from "@/modules/pest/decorators/requests.js";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import { getUserName } from "@/modules/crm/common.js";
import {
  BLOCKED,
  REGULAR,
  UNBLOCKED,
} from "@/modules/users/decorators/userDecorator.js";
import { canChangeAssignee } from "@/modules/users/access/common.js";
import { getKanbanCardTextColor } from "@/modules/crm/decorators/statusDecorator";
import { getRequestProjectTitle } from "@/modules/pest/decorators/requests.js";
export default {
  name: "RequestCardKanbanFields",
  components: {},
  props: [
    "blocked",
    "counter",
    "request",
    "status",
    "leadBoard",
    "hideHandle",
    "showDuplicate",
  ],
  data() {
    return {
      BLOCKED,
      UNBLOCKED,
      REGULAR,
      STATUS_DONE,
      STAGE_DEAL,
      PT_BANK_TRANS,
      duplicating: false,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    phoneList() {
      return getCommunicationPhoneList(this.request, true);
    },
    canChangeAssignee() {
      return canChangeAssignee(this.$store.state.user);
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getRequestTechnicianName,
    getRequestDisplayableSum,
    getRequestNumber,
    getFacilityTitle,
    getRequestTitle,
    getPaymentTypeTitle,
    getRequestStatusColor,
    getRequestColor,
    getRequestBorderType,
    getPestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestSourceName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    servicesSum,
    getRequestTownTitle,
    getRequestAddressFormatted,
    getRequestCallNotification,
    getKanbanCardTextColor,
    getRequestProjectTitle,
    openRequest() {
      this.$emit("open-request");
    },
    duplicateRequest() {
      this.$emit("duplicate-request", this.request);
    },
    updateTime(duration) {
      let updateUrl;
      updateUrl = "/pest/request/duration/" + this.id;
      // this.loading = true;
      this.$http
        .put(updateUrl, {
          duration: duration,
        })
        .then((response) => {
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("requests.request_time_updated_mgs"),
            request: "success",
          });
        })
        .catch((error) => {
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("requests.request_time_not_updated_mgs"),
              request: "danger",
            });
          }
        });
    },
    openTimePopup() {
      let $this = this;
      this.$eventHub.$emit("time-popup-show", {
        request: this.request,
        success: function (duration) {
          $this.request.duration = duration;
          $this.updateTime(duration);
        },
        cancel: function () {},
      });
    },
    openAssigneePopup() {
      let $this = this;
      let request = this.request;
      this.$eventHub.$emit("assignee-popup-show", {
        request: this.request,
        success: function (assignee) {
          let changed = false;
          let oldAssigneeId = request.assigneeId;
          if (assignee) {
            if (assignee.id !== request.assigneeId) {
              changed = true;
              request.assigneeId = assignee.id;
              request.assignee = assignee;
            }
          } else {
            if (request.assigneeId) {
              request.assigneeId = 0;
              request.assignee = null;
              changed = true;
            }
          }
          if (changed) {
            $this.$eventHub.$emit("assignee-changed", {
              request: request,
              assignee: assignee,
              oldAssigneeId: oldAssigneeId,
            });
          }
        },
        cancel: function () {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.handle {
  //display: none;
  display: block !important;
}

.request-card:hover .handle {
  display: block !important;
  cursor: move;
}
</style>

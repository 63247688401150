export const ru = {
  messages: {
    messages: "Сообщения",
    message_list: "Перечень сообщений",
    message: "Сообщение",
    add_message: "Добавить сообщение",
    new_message: "Новый сообщение",
    message_update: "Изменение сообщения",
    message_updated: "Сообщение отредактирована",
    cant_create_message: "Не удалось добавить сообщение",
    cant_delete_message: "Не удалось удалить сообщение",
    enter_text: "Заполните текст",
    message_created: "Сообщение добавлено",
    message_deleted: "Сообщение удалено",
    message_sent: "Сообщение отправлено",
    message_is_not_sent: "Сообщение не отправлено",
    delete_message_question: "Вы действительно хотите удалить сообщение?",
    send_message: "Отправить сообщение",
    time: "Время",
    sender: "Отправитель",
    recipients: "Получатели",
    to_all_in: "Всем в",
    ppl: "чел.",
    no_sent_messages: "Нет отправленных сообщений",
    send_to_any_assignee_in_town_msg: "Отправить всем мастерам в городе",
    send_to_individual_recipients_msg: "Отправить индивидуальным получателям",

    send_to_all_assignees: "Отправить всем мастерам",
    confirm_send_to_all: "Да, я хочу отправить сообщение всем мастерам",
    select_recipients: "Выберите получателей",
    all_notifications_marked_as_read:
      "Все уведомления отмечены как просмотренные",
  },
};
export const en = {
  messages: {
    messages: "Messages",
    message_list: "Message list",
    message: "Message",
    add_message: "Add message",
    new_message: "New message",
    change_message: "Change message",
    message_update: "Update message",
    message_updated: "Message changed",
    cant_create_message: "Can't add message",
    cant_delete_message: "Can't delete message",
    enter_text: "Enter text",
    message_created: "Message added",
    message_deleted: "Message deleted",
    message_sent: "Message sent",
    message_is_not_sent: "Message is not sent",
    delete_message_question: "Do you want to delete the message?",
    unit: "Unit",
    send_message: "Send message",
    time: "Time",
    sender: "Sender",
    recipients: "Recipients",
    to_all_in: "To all in",
    ppl: "ppl.",
    no_sent_messages: "No sent messages",
    send_to_any_assignee_in_town_msg: "Send to any assignee in town",
    send_to_individual_recipients_msg: "Send to individual recipients",
    select_recipients: "Select recipients",
    all_notifications_marked_as_read: "All notifications marked as read",
  },
};

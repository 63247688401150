<template>
  <request-list tab="archiveEmployeeList"></request-list>
</template>

<script>
import {
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestManagerName,
  getRequestStatus,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  servicesSum,
} from "@/modules/pest/decorators/requests";
import { getAssigneeName } from "@/modules/crm/common";
import RequestList from "@/modules/pest/views/requests/RequestList.vue";

export default {
  name: "EmployeeArchiveRequestList",
  components: { RequestList },
  data() {
    return {
      loadingStats: false,
      totalSum: 0,
      profitSum: 0,
      sumToPay: 0,
      finesSum: 0,
      average: 0,
      count: 0,
      telegramUrl:
        "https://t.me/" + import.meta.env.VITE_ASSIGNEE_BOT + "?start=",
      telegramChatId: null,
    };
  },
  computed: {
    showTelegramSingupWindow() {
      return !this.canAddClient() && !this.loadingStats && !this.telegramChatId;
    },
  },
  watch: {},
  mounted: function () {
    if (!this.canAddClient()) {
      this.fetchSum();
    }
  },
  created() {},
  methods: {
    getAssigneeName,
    servicesSum,
    getRequestTitle,
    getRequestDatetime,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getRequestTownTitle,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
    canAddClient() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },
    fetchSum: function () {
      this.loadingStats = true;
      let requestsUrl = "/pest/requests/my-info";
      let params = {};
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;

          this.totalSum = response.data.totalSum;
          this.profitSum = response.data.profitSum;
          this.sumToPay = response.data.sumToPay;
          this.finesSum = response.data.finesSum;
          this.average = response.data.average;
          this.count = response.data.count;
          this.telegramChatId = response.data.telegramChatId;
          this.phone = response.data.phone;
          this.telegramUrl += response.data.phone;
        })
        .catch((error) => {
          this.loadingStats = false;

          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.request-organization {
  font-size: smaller;
  color: gray;
}

.actions-cell {
  max-width: 50px;
}

.text-success {
  color: #28a745 !important;
}
</style>

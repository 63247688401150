<template>
  <div>
    <div class="ibox">
      <div class="ibox-content">
        <div class="d-flex align-items-center">
          <h1>{{ $t("payments.settlements") }}</h1>

          <router-link
            v-if="canAddPayment()"
            :to="{ name: 'pest-payment-create' }"
            class="btn btn-primary ml-2"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("payments.add_payment") }}
          </router-link>

          <div class="ml-auto">
            <router-link
              :to="{ name: 'pest-payment-list' }"
              class="btn btn-outline-primary ml-2"
            >
              <font-awesome-icon icon="list" />
              {{ $t("payments.payments_history") }}
            </router-link>
          </div>
        </div>

        <div>
          <div class="mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
          </div>
        </div>

        <div>
          <table
            v-if="debtors.length"
            class="debtors-table table table-striped"
          >
            <thead>
              <tr>
                <th>{{ $t("requests.assignee") }}</th>
                <th>{{ $t("payments.sum_to_pay") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(info, index) in debtorsFromTown" :key="index">
                <td>{{ getUserName(info.user) }}</td>
                <td>{{ info.sum }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'pest-payment-create',
                      params: { userId: info.user.id },
                    }"
                    class="btn btn-primary btn-sm"
                  >
                    <font-awesome-icon icon="plus" />
                    {{ $t("payments.add_payment") }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaymentTime,
  getPaymentTownTitle,
} from "@/modules/pest/decorators/payments";
import { getUserName } from "@/modules/crm/common";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "PestSettlements",
  components: { TownFilter },
  data() {
    return {
      debtors: [],
      search: {
        townId: MSK,
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
    };
  },
  computed: {
    debtorsFromTown() {
      return this.debtors.filter(
        (d) => d.user && d.user.townId === this.search.townId
      );
    },
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      if (this.$route.params.townId) {
        this.search.townId = this.$route.params.townId;
      }
      this.fetchData();
    },
  },
  mounted: function () {
    if (this.$route.params.townId) {
      this.townId = parseInt(this.$route.params.townId);
    }
    this.fetchData();
  },
  created() {},
  methods: {
    getPaymentTime,
    getUserName,
    getPaymentTownTitle,
    fetchData: function () {
      this.loading = true;

      let paymentsUrl = "/pest/request/debtors";
      let params = {
        // page: this.page,
      };
      this.$http
        .get(paymentsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.debtors = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    linkGen(pageNum) {
      return {
        name: "pest-payment-list",
        params: { page: pageNum },
      };
    },
    canAddPayment() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },
    selectTown(town) {
      this.search.townId = town;
    },
  },
};
</script>

<style lang="scss" scoped>
.debtors-table tr td:first-child {
  width: 400px;
}

.debtors-table tr td:nth-child(2) {
  width: 200px;
}
</style>

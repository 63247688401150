<template>
  <b-overlay :show="loadingContacts" rounded="sm">
    <div class="d-flex flex-wrap mt-2">
      <div
        v-for="(contact, cIndex) in contacts"
        :key="cIndex"
        class="mr-4 mb-4"
      >
        <div class="ibox mb-0">
          <div class="ibox-content contact-card-wrapper pb-2">
            <contact-card
              :client="contact"
              :is-last="contacts.length < 2"
              @update-client-info="clientUpdated"
              @cancel-updating="cancelUpdate"
              @client-deleted="clientDeleted"
            />
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ContactCard from "@/modules/pest/views/requests/contract/contacts/ContactCard.vue";

export default {
  name: "ContractContacts",
  components: { ContactCard },
  props: ["contractId"],
  data() {
    return {
      groupedContacts: [],
      contacts: [],
      curContact: null,
      showContact: false,
      addingNew: false,
      loadingContacts: false,
    };
  },
  computed: {},
  watch: {
    contractId: {
      immediate: true,
      handler(contractId) {
        this.contractId = contractId;
        this.fetchContacts();
      },
    },
  },
  created: function () {},
  mounted() {},
  methods: {
    showContactPopup(info) {
      console.log("showContactPopup");
      console.log((this.curContact = info.contact));
      this.showContact = true;
      console.log(info);
    },
    showContractPopup(info) {
      console.log("showContractPopup");
      console.log((this.curContact = info.contact));
      this.showContact = true;
      console.log(info);
    },

    fetchContacts() {
      this.loadingContacts = true;

      let contactsUrl = "/crm/clients";
      let params = {
        contractId: this.contractId,
      };
      this.$http
        .get(contactsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingContacts = false;
          this.contacts = response.data;
        })
        .catch((error) => {
          this.loadingContacts = false;
        });
    },
    addNewClient(contact) {
      this.contacts.push(contact);
    },
    addClient() {
      this.contacts.push({
        id: "new",
        isNew: true,
        contractId: this.contractId,
        phones: [
          {
            phone: "",
            type: "mobile",
          },
        ],
      });
      this.addingNew = true;
    },
    clientUpdated(client) {
      this.contacts.splice(this.contacts.length - 1, 1);
      this.contacts.push(client);
      this.addingNew = false;
    },
    cancelUpdate(client) {
      if (client.id === "new") {
        this.contacts.splice(this.contacts.length - 1, 1);
      }
      this.addingNew = false;
    },
    clientDeleted(client) {
      this.fetchContacts();
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-card-wrapper {
  //border: 2px solid black !important;
}

.btn-make-primary {
  opacity: 0;
  //display: none;
}

.btn-delete-contact {
  //opacity: 0;
  display: none;
}

.contact-card-wrapper:hover .btn-make-primary {
  opacity: 1;
  //display: block;
}

.contact-card-wrapper:hover .btn-delete-contact {
  //opacity: 1;
  display: block;
}
</style>

<template>
  <b-form
    id="pest-place-form"
    ref="place"
    :model="place"
    class="pest-place-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input
              v-model="place.title"
              :placeholder="$t('common.title')"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group :label="$t('common.town')" prop="townId">
        <town-filter :value="place.townId" @change="selectTown"></town-filter>
      </b-form-group>

      <b-form-group :label="$t('places.search_by_address')" prop="color">
        <dadata-autocomplete
          v-model="searchAddress"
          @selected="addressSelected"
        ></dadata-autocomplete>
      </b-form-group>

      <b-form-group :label="$t('places.address')" prop="color">
        <b-form-input v-model="place.address"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('places.coords')" prop="color">
        <b-form-input v-model="place.coords"></b-form-input>
      </b-form-group>

      <!--      <div class="row">-->
      <!--        <div class="col-12">-->
      <!--            <b-form-group :label="$t('common.description')" prop="title">-->
      <!--            <b-form-textarea v-model="place.comment" :placeholder="$t('common.description')"-->
      <!--                             tabindex="1"></b-form-textarea>-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="place.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deletePlace"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getTownTitle, DXB, MSK, SPB } from "@/modules/pest/decorators/towns";
import DadataAutocomplete from "@/components/shared/DadataAutocomplete.vue";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "PestPlaceForm",
  directives: { mask: VueMaskDirective },
  components: { TownFilter, DadataAutocomplete },
  props: ["place"],
  data() {
    return {
      MSK,
      SPB,
      DXB,
      phoneMask: false,
      loading: false,
      searchAddress: "",
    };
  },
  computed: {
    id() {
      return this.place.id;
    },
    name() {
      let name = this.place.name
        ? this.place.name
        : this.$t("places.new_place");
      return name ? name : "place";
    },
  },
  watch: {
    place: {
      immediate: true,
      handler(obj) {
        this.searchAddress = obj.address;
      },
    },
  },
  created: function () {},
  methods: {
    getUserName,
    getTownTitle,

    getTownClass(town) {
      if (this.place.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectTown(town) {
      this.place.townId = town;
      this.fetchData();
    },

    update: function () {
      let updateUrl;
      updateUrl = "/pest/places/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.place)
        .then((response) => {
          this.place = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-place-info", this.place);
          // this.$router.push({name: 'pest-place-view', params: {id: this.place.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("places.place_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/places";
      this.loading = true;
      this.$http
        .post(updateUrl, this.place)
        .then((response) => {
          this.place = response.data;
          this.$eventHub.$emit("update-pest-place-info", this.place);
          this.$router.push({
            name: "pest-place-update",
            params: { id: this.place.id },
          });
          this.$bvToast.toast(this.$t("places.place_added"), {
            title: this.$t("places.place_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("places.cant_create_place"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-place-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    handleTownSubmit(result) {
      this.place.townId = result.id;
    },
    getResultTownAutocompleteValue(town) {
      return this.getTownTitle(town);
    },
    searchTown(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let categoriesUrl = "/pest/towns/search";
        this.loadingTownsList = true;
        this.$http
          .get(categoriesUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loadingTownsList = false;
            resolve(response.data);
            // this.task = response.data;
            // this.header = this.task.email;
          })
          .catch((error) => {
            this.loadingTownsList = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    deletePlace() {
      if (confirm(this.$t("places.delete_place_question"))) {
        let deleteUrl = "/pest/places/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast(this.$t("places.place_deleted"), {
              title: this.$t("places.place_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("places.cant_delete_place"),
                variant: "danger",
              });
            }
          });
      }
    },
    addressSelected(item) {
      if (!item.value || !item.value.data) {
        return;
      }
      this.place.address = item.value.value;
      let lat = item.value.data.geo_lat;
      let lon = item.value.data.geo_lon;
      this.place.coords = "[" + lat + "," + lon + "]";
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>

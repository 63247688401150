<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <div class="d-flex align-items-center">
          <h2 class="mr-2">{{ $t("stats.stats") }}</h2>
          <div class="mr-2">
            <date-range-picker
              v-model="dateRange"
              @change="fetchData"
            ></date-range-picker>
          </div>
          <div class="mr-2">
            <b-form-select
              v-model="search.townId"
              :options="townList"
              tabindex="1"
            ></b-form-select>
          </div>
          <!--          <div class="mr-2">-->
          <!--            <b-form-checkbox v-model="search.skipEmpty" tabindex="1">Скрыть пустые</b-form-checkbox>-->
          <!--          </div>-->
          <b-button class="btn btn-primary ml-1" @click="fetchData">
            <font-awesome-icon icon="search" />&nbsp;{{ $t("common.refresh") }}
          </b-button>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div v-if="loading">{{ $t("common.loading") }}</div>
      <div v-else-if="allStats" class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="title-cell"></th>
              <th
                v-for="(dayI, dIndex) in allStats.days"
                :key="'h' + dIndex"
                class="day-cell"
              >
                {{ dayI.day }}
              </th>
              <th>{{ $t("stats.total") }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(fieldI, fIndex) in fields" :key="'f' + fIndex">
              <th class="label-cell">{{ fieldI.label }}</th>

              <td v-for="(dayI, dIndex) in allStats.days" :key="'h' + dIndex">
                {{ formatNumber(dayI.total[fieldI.key]) }}
              </td>
              <th>
                {{ formatNumber(getTotalRowSum(fieldI.key)) }}
              </th>
            </tr>
          </tbody>

          <tbody
            v-for="(userI, uIndex) in allStats.total.users"
            :key="'u' + uIndex"
          >
            <tr class="delimeter-row">
              <td :colspan="allStats.days.length + 2"></td>
            </tr>

            <tr class="user-header-row">
              <th class="user-header">{{ userI.name }}</th>
              <th
                v-for="(dayI, dIndex) in allStats.days"
                :key="'h' + dIndex"
                :class="userDayClass(dayI.users[uIndex])"
              >
                {{ dayI.day }}
              </th>
              <th>{{ $t("stats.total") }}</th>
            </tr>

            <tr v-for="(fieldI, fIndex) in fields" :key="'f' + fIndex">
              <td>{{ fieldI.label }}</td>

              <td v-for="(dayI, dIndex) in allStats.days" :key="'h' + dIndex">
                <span v-if="dayI.users[uIndex][fieldI.key]">
                  {{ formatNumber(dayI.users[uIndex][fieldI.key]) }}
                </span>
              </td>
              <th>
                <span v-if="fieldI.avg">
                  {{ formatNumber(getUserAvgSum(uIndex, fieldI)) }}
                </span>
                <span v-else>
                  {{ formatNumber(getUserRowSum(uIndex, fieldI.key)) }}
                </span>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>{{ $t("common.no_data") }}</div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import DateRangePicker from "@/components/shared/DateRangePicker";
import { MSK, MSK_C, SPB, SPB_C } from "@/modules/pest/decorators/towns";

export default {
  name: "Stats",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      labelWidth: "150px",
      settings: {},
      loading: false,
      townList: [
        { value: 1, text: "Москва и область" },
        { value: 2, text: "Санкт-Петербург и область" },
      ],
      allStats: [],
      search: {
        skipEmpty: true,
        dateFrom: "",
        townId: 2,
        dateTo: "",
      },
      fields: [
        // {
        //   key: "name",
        //   avg: false,
        //   // formatter: this.formatNumber,
        //   label: this.$t("stats.name"),
        //   sortable: true,
        // },
        {
          key: "count",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.count"),
          sortable: true,
        },
        // {
        //   key: "countNew",
        //   avg: false,
        //   formatter: this.formatNumber,
        //   label: this.$t("stats.countNew"),
        //   sortable: true,
        // },
        {
          key: "countSuccess",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.countSuccess"),
          sortable: true,
        },
        {
          key: "countDeclined",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.countDeclined"),
          sortable: true,
        },
        {
          key: "firstTimeCount",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.firstTimeCount"),
          sortable: true,
        },
        {
          key: "firstTimeSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.firstTimeSum"),
          sortable: true,
        },
        {
          key: "averageFirstTimeSum",
          formatter: this.formatNumber,
          label: this.$t("stats.averageFirstTimeSum"),
          avg: true,
          f1: "firstTimeSum",
          f2: "firstTimeCount",
          sortable: true,
        },
        {
          key: "secondTimeCount",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.secondTimeCount"),
          sortable: true,
        },
        {
          key: "secondTimeSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.secondTimeSum"),
          sortable: true,
        },
        {
          key: "averageSecondTimeSum",
          formatter: this.formatNumber,
          label: this.$t("stats.averageSecondTimeSum"),
          avg: true,
          f1: "secondTimeSum",
          f2: "secondTimeCount",
          sortable: true,
        },
        {
          key: "recordCount",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.recordCount"),
          sortable: true,
        },
        {
          key: "recordSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.recordSum"),
          sortable: true,
        },
        {
          key: "paymentCashlessCount",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.paymentCashlessCount"),
          sortable: true,
        },
        {
          key: "paymentCashlessSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.paymentCashlessSum"),
          sortable: true,
        },
        {
          key: "tripSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.tripSum"),
          sortable: true,
        },

        {
          key: "averageTripSum",
          formatter: this.formatNumber,
          label: this.$t("stats.averageTripSum"),
          avg: true,
          f1: "tripSum",
          f2: "count",
          sortable: true,
        },
        {
          key: "tripDistance",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.tripDistance"),
          sortable: true,
        },
        {
          key: "equipmentSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.equipmentSum"),
          sortable: true,
        },
        {
          key: "fineSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.fineSum"),
          sortable: true,
        },

        {
          key: "profitSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.profitSum"),
          sortable: true,
        },

        {
          key: "teamPercentSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.teamPercentSum"),
          sortable: true,
        },

        {
          key: "toPaySum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.toPaySum"),
          sortable: true,
        },

        {
          key: "toPaySumLeft",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.toPaySumLeft"),
          sortable: true,
        },

        {
          key: "paymentSum",
          avg: false,
          formatter: this.formatNumber,
          label: this.$t("stats.paymentSum"),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
    userStatsFiltered() {
      let skipEmpty = this.search.skipEmpty;
      return this.userStats.filter((user) => {
        if (!skipEmpty) {
          return true;
        }
        return user.count > 0;
      });
    },
    ownServer() {
      // console.log(this.settings.emailSelectServer);
      return this.settings.emailSelectServer === "own";
    },
  },
  watch: {},
  mounted: function () {
    this.fetchData();
  },
  created: function () {
    this.fetchTowns();
    this.search.dateFrom = dayjs().subtract(7, "d").format("DD.MM.YYYY");
    this.search.dateTo = dayjs().format("DD.MM.YYYY");
    this.fetchData();
  },
  methods: {
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
    fetchTowns: function () {
      this.townList = [];
      this.townList.push({ value: "", text: this.$t("common.all") });
      let townUrl = "/pest/towns";
      let params = {};
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            if (item.id === MSK) {
              this.townList.push({ value: MSK, text: "Москва и область" });
            } else if (item.id === SPB) {
              this.townList.push({
                value: SPB,
                text: "Санкт-Петербург и область",
              });
            } else if ([MSK_C, SPB_C].includes(item.id)) {
              // do nothing
              // continue;
            } else this.townList.push({ value: item.id, text: item.title });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },

    userDayClass(user) {
      if (user.count) {
        return "active-user";
      }
      return "";
    },

    fetchData: function () {
      this.loading = true;
      let settingsUrl = "pest/requests/stats";
      this.$http
        .get(settingsUrl, {
          params: this.search,
        })
        .then((response) => {
          this.allStats = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    getTotalRowSum(field) {
      let sum = 0;
      for (let day of this.allStats.days) {
        sum += day.total[field];
      }
      return sum;
    },
    getUserRowSum(index, field) {
      let sum = 0;
      for (let day of this.allStats.days) {
        sum += day.users[index][field];
      }
      return sum;
    },
    getUserAvgSum(index, field) {
      let sum1 = this.getUserRowSum(index, field.f1);
      let sum2 = this.getUserRowSum(index, field.f2);
      return sum2 ? sum1 / sum2 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
thead th:not(:first-child) {
  width: 100px !important;
  max-width: 100px !important;
}

thead th,
tr.user-header-row > td,
tr.user-header-row > th {
  position: sticky;
  top: 0;
}

.delimeter-row td {
  border-top: 2px solid gray;
}

.user-header {
  background: #23c6c8;
}

.active-user {
  background: #d09e23;
}

th.label-cell {
  width: 260px;
  min-width: 260px;
}

table {
  width: auto;
}

.title-cell {
  width: 260px;
  min-width: 260px;
}

.day-cell {
  width: 100px;
  min-width: 100px;
}
</style>

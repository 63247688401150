<template>
  <div class="login">
    <h1>{{ title }}</h1>
    <div class="ibox">
      <div class="ibox-content">
        <login-form></login-form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/modules/users/components/form/LoginForm";

export default {
  name: "Login",
  components: {
    "login-form": LoginForm,
  },
  data() {
    return {
      title: this.$t("users.login_header"),
    };
  },
};
</script>

<style lang="scss">
.login {
  max-width: 450px;
  margin: 0 auto;

  h1 {
    text-align: center;
  }
}
</style>

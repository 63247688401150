import User from "@/modules/users/views/user/User.vue";
import UserList from "@/modules/users/views/user/UserList.vue";

export default [
  {
    path: "/users/:page?",
    name: "users-user-list",
    components: {
      default: UserList,
    },
  },
  {
    path: "/user/:id",
    name: "users-user-view",
    components: {
      default: User,
    },
  },
  {
    path: "/users/new",
    name: "user-create",
    components: {
      default: User,
    },
  },
];

import ShopResourceList from "@/modules/pest/views/shop-resource/ShopResourceList.vue";
import ShopResourceCreate from "@/modules/pest/views/shop-resource/ShopResourceCreate.vue";
import ShopResourceUpdate from "@/modules/pest/views/shop-resource/ShopResourceUpdate.vue";
import ShopResourceView from "@/modules/pest/views/shop-resource/ShopResourceView.vue";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/shop-resource/page:page?",
    name: "pest-shop-resource-list",
    components: {
      default: ShopResourceList,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/shop-resource/new",
    name: "pest-shop-resource-create",
    components: {
      default: ShopResourceCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/shop-resource/:id/update",
    name: "pest-shop-resource-update",
    components: {
      default: ShopResourceUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/shop-resource/:id",
    name: "pest-shop-resource-view",
    components: {
      default: ShopResourceView,
    },
    meta: { requiresAuth: true },
  },
];

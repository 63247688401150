import { getUserTitle } from "@/modules/users/decorators/userDecorator";
import t from "@/i18n";

export const getUserName = function (user, defaultValue) {
  if (!user) {
    return defaultValue ? defaultValue : t("common.not_set");
  }

  let firstName = user.name || "";
  let lastName = user.surname || "";
  let patronymic = user.patronymic || "";
  let name = lastName + " " + firstName + " " + patronymic;
  name = name.trim();
  if (name && name !== "") {
    return name;
  }
  if (!name && user.username) {
    return user.username;
  } else {
    return defaultValue ? defaultValue : t("requests.without_name");
  }
};

export const getAssigneeNumber = function (user) {
  let name = getUserTitle(user);
  let townLength = 3;
  if (name.indexOf("СПБ") !== -1) {
    name = name.substring(0, name.indexOf("СПБ") + townLength);
  }
  if (name.indexOf("МСК") !== -1) {
    name = name.substring(0, name.indexOf("МСК") + townLength);
  }
  let nameF = name.split(" ");
  return nameF[0];
};

export const getAssigneeName = function (user, includeTown) {
  let name = getUserTitle(user);
  let townLength = includeTown ? 3 : 0;

  if (name.indexOf("СПБ") !== -1) {
    return name.substring(0, name.indexOf("СПБ") + townLength);
  }
  if (name.indexOf("МСК") !== -1) {
    return name.substring(0, name.indexOf("МСК") + townLength);
  }
  if (name.indexOf("DXB") !== -1) {
    return name.substring(0, name.indexOf("DXB") + townLength);
  }
  return name;
};
const COMMENT_TYPE_ISSUE = "issue-comment";
const COMMENT_TYPE_LEAD = "lead-comment";
const COMMENT_TYPE_REQUEST = "request-comment";
const CLIENT_COMMENT_TYPE_ISSUE = "issue-client-comment";
export { CLIENT_COMMENT_TYPE_ISSUE };
export { COMMENT_TYPE_REQUEST };
export { COMMENT_TYPE_LEAD };
export { COMMENT_TYPE_ISSUE };

<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>{{ $t("resources.resource_list") }}</h1>
      <div class="mb-2">
        <router-link
          :to="{ name: 'pest-resource-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          {{ $t("resources.add_resource") }}
        </router-link>
      </div>
      <div class="search">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-form-group :label="$t('common.title')">
              <b-form-input
                v-model="search.title"
                tabindex="3"
                @change="fetchData"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="mr-2">
            <b-form-group :label="$t('resources.visibility')">
              <b-form-select
                v-model="search.enabled"
                tabindex="3"
                @change="fetchData"
              >
                <b-form-select-option value="null">{{
                  $t("common.all")
                }}</b-form-select-option>
                <b-form-select-option value="1">{{
                  $t("common.yes")
                }}</b-form-select-option>
                <b-form-select-option value="0">{{
                  $t("common.no")
                }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;{{ $t("search.find") }}
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            {{ $t("board_common.pagination_total") }}:
            {{ pagination.totalCount }}
          </div>
        </div>
      </div>
      <b-table
        v-if="resources.length"
        responsive
        striped
        hover
        condensed
        :items="resources"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> {{ $t("common.loading") }}</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{ name: 'pest-resource-update', params: { id: data.item.id } }"
            class=""
          >
            {{ data.item.title ? data.item.title : $t("common.without_title") }}
          </router-link>
        </template>

        <template #cell(shopResources)="data">
          <div v-if="data.item && data.item.shopResources" class="mb-2">
            <router-link
              v-for="(assignedResource, apI) in data.item.shopResources"
              :key="apI"
              :to="{
                name: 'pest-shop-resource-update',
                params: { id: assignedResource.id },
              }"
              class="badge badge-primary mr-2"
            >
              {{ assignedResource.title }}
            </router-link>
          </div>
        </template>

        <template #cell(enabled)="data">
          <span v-if="data.item.enabled">
            {{ $t("common.yes") }} <font-awesome-icon icon="eye" />
          </span>
          <span v-else>
            {{ $t("common.no") }} <font-awesome-icon icon="eye-slash"
          /></span>
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
export default {
  name: "PestResourceList",
  components: {},
  data() {
    return {
      resources: [],
      search: {
        title: "",
        enabled: null,
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "unit",
          label: this.$t("common.unit"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("common.description"),
          sortable: false,
        },
        {
          key: "shopResources",
          label: this.$t("resources.goods_from_shop"),
          sortable: false,
        },
        {
          key: "enabled",
          label: this.$t("resources.visibility"),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    fetchData: function () {
      this.loading = true;

      let resourcesUrl = "/pest/resources";
      let params = {
        page: this.page,
        title: this.search.title,
        enabled: this.search.enabled,
        withShop: true,
      };
      this.$http
        .get(resourcesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.resources = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-resource-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<template>
  <div class="row">
    <div class="col-md-4">
      <test-roistat-request />
    </div>
    <div class="col-md-4">
      <convert-request />
      <test-call />
    </div>
    <div class="col-md-4"><sync1-c-form /></div>
  </div>
</template>

<script>
import TestRoistatRequest from "./testing/TestRoistatRequest.vue";
import ConvertRequest from "@/modules/roistat/views/dashboard/testing/ConvertRequest";
import TestCall from "@/modules/roistat/views/dashboard/testing/TestCall";
import Sync1CForm from "@/modules/roistat/views/dashboard/testing/Sync1CForm.vue";

export default {
  name: "ServiceDashboard",
  components: { Sync1CForm, TestCall, ConvertRequest, TestRoistatRequest },

  data() {
    return {};
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);
      this.checkUrl();
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

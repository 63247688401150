// processRequestError
import * as Sentry from "@sentry/vue";

export default function (error) {
  Sentry.captureException(error);
  if (error.response) {
    if (error.response.status === 404) {
      // this.$router.push({name: 'not-found'})
    } else if (error.response.status === 403) {
      if (this.$store.state.user.isGuest === true) {
        this.$bvToast.toast("Log in ", {
          title: "Error",
          variant: "danger",
        });
        this.$router.push({ name: "users-login" });
        return true;
      } else {
        this.$bvToast.toast("You have no access to this page ", {
          title: this.$t("common.error"),
          variant: "danger",
        });
        return true;
      }
    }
  }
  return false;
}

import Login from "@/modules/users/views/login/Login.vue";

export default [
  {
    path: "/login",
    name: "users-login",
    components: {
      default: Login,
    },
  },
];

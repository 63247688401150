<template>
  <div class="ibox">
    <div class="">
      <div class="ibox-title">
        <div class="d-flex align-items-center">
          <h1>{{ $t("users.user_list") }}</h1>
          <div v-if="canUpdateUser" class="mr-2 ml-2">
            <router-link
              :to="{ name: 'users-user-view', params: { id: 'new' } }"
              class="btn btn-primary"
            >
              <font-awesome-icon icon="plus" />
              {{ $t("users.add_user") }}
            </router-link>
          </div>
          <users-cards-import v-if="isAdmin" />
        </div>
      </div>

      <div class="ibox-content">
        <div class="search">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <b-form-group :label="$t('users.role')">
                <b-form-select
                  v-model="search.type"
                  :options="userTypesOptions"
                  tabindex="1"
                  @change="fetchData"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="mr-2">
              <b-form-group :label="$t('users.town')">
                <b-form-select
                  v-model="search.townId"
                  :options="townOptions"
                  tabindex="2"
                  @change="fetchData"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="mr-2">
              <b-form-group :label="$t('users.search_term')">
                <b-form-input
                  v-model="search.term"
                  tabindex="3"
                  @change="fetchData"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="align-self-center mt-2 mr-2">
              <b-button class="btn btn-primary ml-1" @click="fetchData">
                <font-awesome-icon icon="search" />&nbsp;{{ $t("search.find") }}
              </b-button>
            </div>

            <div v-if="!loading" class="mt-2 mr-2">
              {{ $t("board_common.pagination_total") }}:
              {{ pagination.totalCount }}
            </div>
          </div>
        </div>

        <hr />
        <b-overlay :show="loading" rounded="sm">
          <table class="table table-condensed table-striped table-bordered">
            <thead>
              <tr>
                <th v-if="false" class="check-column">
                  <b-form-checkbox
                    v-model="checkAll"
                    :value="true"
                    @change="checkAllCheckboxes"
                  ></b-form-checkbox>
                </th>

                <th class="name-column">
                  <sort-column-title
                    v-model="search.sort"
                    :title="$t('users.fullname')"
                    param="surname"
                    @change="fetchData"
                  />
                </th>
                <th class="phone-column">
                  <sort-column-title
                    v-model="search.sort"
                    :title="$t('users.phone')"
                    param="phone"
                    @change="fetchData"
                  />
                </th>
                <th class="email-column">
                  <sort-column-title
                    v-model="search.sort"
                    :title="$t('users.email')"
                    param="email"
                    @change="fetchData"
                  />
                </th>
                <th class="telegram-column">
                  <sort-column-title
                    v-model="search.sort"
                    title="Telegram"
                    param="telegramChatId"
                    @change="fetchData"
                  />
                </th>
                <th class="town-column">
                  <sort-column-title
                    v-model="search.sort"
                    :title="$t('users.town')"
                    param="townId"
                    @change="fetchData"
                  />
                </th>
                <th class="type-column">
                  <sort-column-title
                    v-model="search.sort"
                    :title="$t('users.role')"
                    param="type"
                    @change="fetchData"
                  />
                </th>
                <th v-if="canUpdateUser" class="check-column">
                  <div>
                    <b-dropdown
                      text=""
                      variant="default btn-xs"
                      :disabled="!hasCheckedItems"
                    >
                      <b-dropdown-item
                        href="#"
                        @click.prevent="deleteSelectedUsers"
                        >Удалить выбранное
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </th>
                <th v-if="false" class="comment-column"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(user, index) in users" :key="'p' + index" class="">
                <td v-if="false" class="check-column">
                  <b-form-checkbox
                    v-model="user.checked"
                    :value="true"
                    @change="checkCheckboxStatus"
                  ></b-form-checkbox>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'users-user-view', params: { id: user.id } }"
                  >
                    {{ getUserName(user) }}
                  </router-link>
                </td>

                <td>
                  <router-link
                    v-if="canUpdateUser"
                    :to="{ name: 'users-user-view', params: { id: user.id } }"
                    class=""
                  >
                    {{ user.phone ? user.phone : "[Нет телефона]" }}
                  </router-link>
                  <span v-else>
                    {{ user.phone ? user.phone : "[Нет телефона]" }}
                  </span>
                </td>
                <td>
                  <router-link
                    v-if="canUpdateUser"
                    :to="{ name: 'users-user-view', params: { id: user.id } }"
                    class=""
                  >
                    {{ user.email ? user.email : "[Нет email]" }}
                  </router-link>
                  <span v-else>
                    {{ user.email ? user.email : "[Нет email]" }}
                  </span>
                </td>
                <td>
                  {{
                    user.telegramChatId ? $t("users.telegram_connected") : ""
                  }}
                </td>
                <td>
                  {{ getUserTown(user) }}
                </td>
                <td>
                  {{ getTypeTitle(user) }}
                </td>
                <td v-if="canUpdateUser" class="check-column">
                  <b-dropdown text="" variant="default btn-xs" right>
                    <b-dropdown-item
                      :to="{ name: 'users-user-view', params: { id: user.id } }"
                    >
                      Редактировать
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="deleteUser(user)"
                      >Удалить
                    </b-dropdown-item>
                  </b-dropdown>
                </td>

                <td v-if="false" class="comment-column">
                  <span v-if="user.isNovice">
                    Стажер с {{ user.noviceDateFrom }} <br />
                    Смен: {{ user.noviceShiftCount }}
                  </span>

                  <span v-if="user.teamHead">
                    В команде у {{ getUserTitle(user.teamHead) }} C:
                    {{ user.teamSince }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">{{ $t("common.loading") }}</td>
              </tr>
            </tbody>
          </table>
        </b-overlay>
        <b-pagination-nav
          v-if="pagination.pageCount > 1"
          :link-gen="linkGen"
          :value="pagination.pageNumber"
          :number-of-pages="pagination.pageCount"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserTitle } from "@/modules/users/decorators/userDecorator";
import dayjs from "dayjs";
import {
  getTypeTitle,
  typeSelectOptions,
} from "@/modules/users/decorators/users.js";
import { getClientName } from "@/modules/crm/decorators/client.js";
import { getEquipmentTitle } from "@/modules/pest/decorators/equipments.js";
import {
  MSK,
  SPB,
  DXB,
  MSK_C,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import { getUserName } from "@/modules/crm/common.js";
import { canUpdateUser } from "@/modules/users/access/common.js";
import SortColumnTitle from "@/components/shared/SortColumnTitle.vue";
import UsersCardsImport from "@/modules/users/views/user/UsersCardsImport.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserList",
  components: { UsersCardsImport, SortColumnTitle },
  data() {
    return {
      townOptions: [
        // { value: null, text: this.$t("common.all") },
        // { value: MSK, text: "Москва" },
        // { value: SPB, text: "Санкт-Петербург" },
      ],
      checkAll: false,
      hasCheckedItems: false,
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
      },
      search: {
        term: "",
        name: "",
        phone: "",
        email: "",
        type: null,
        townId: null,
        sort: null,
      },
      error: null,
      loading: true,
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
    }),
    userTypesOptions() {
      return [
        { value: null, text: this.$t("common.all") },
        ...typeSelectOptions(),
      ];
    },
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
    canUpdateUser() {
      return canUpdateUser(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      this.pagination.pageNumber =
        this.$route.params.page !== undefined ? this.$route.params.page : 1;
      this.fetchTowns();
      this.fetchData();
    },
  },

  created: function () {},

  mounted: function () {
    this.checkUrl();
  },

  methods: {
    dayjs,
    getUserName,
    getUserTitle,
    getTypeTitle,
    getClientName,
    getEquipmentTitle,
    getUserTown(user) {
      //todo index
      if (user.townId === MSK) {
        return "Москва";
      } else if (user.townId === SPB) {
        return "Санкт-Петербург";
      } else if (user.townId === DXB) {
        return "Dubai";
      } else {
        return this.$t("common.not_set");
      }
    },
    fetchData: function () {
      this.users = [];
      this.loading = true;

      let usersUrl = "/users/admin/";
      let params = {
        page: this.page,
        search: this.search.term,
        type: this.search.type,
        townId: this.search.townId,
        sort: this.search.sort,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.users = response.data;
          this.pagination.pageSize = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.totalCount = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.pageCount = parseInt(
            response.headers["x-pagination-page-count"]
          );
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchTowns: function () {
      this.townOptions = [];
      this.townOptions.push({ value: "", text: this.$t("common.all") });
      let townUrl = "/pest/towns";
      let params = {};
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.townOptions.push({ value: item.id, text: item.title });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    checkUrl: function () {
      window.scrollTo(0, 0);
      this.fetchData();
      this.fetchTowns();
    },
    linkGen(pageNum) {
      return {
        name: "users-user-list",
        params: { page: pageNum },
      };
    },
    checkCheckboxStatus() {
      this.$nextTick(() => {
        for (let user of this.users) {
          if (user.checked) {
            this.hasCheckedItems = true;
            return;
          }
        }
        this.hasCheckedItems = false;
      });
    },
    deleteSelectedUsers() {
      if (!confirm(this.$t("users.delete_selected_users"))) {
        return;
      }
      let ids = [];
      for (let user of this.users) {
        if (user.checked) {
          ids.push(user.id);
        }
      }

      if (!ids) {
        this.$toast.open({
          message: this.$t("common.choose_at_least_one_record"),
          type: "danger",
        });
        return;
      }

      let deleteUrl;
      deleteUrl = "/users/admin/delete-many";
      this.loading = true;
      this.$http
        .post(deleteUrl, {
          ids: ids,
        })
        .then((response) => {
          this.loading = false;
          this.$toast.open({
            message: this.$t("users.users_deleted"),
            type: "success",
          });
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$toast.open({
              message: this.$t("users.cant_delete_user"),
              type: "danger",
            });
          }
        });
    },
    deleteUser: function (user) {
      if (!confirm(this.$t("users.delete_user_question"))) {
        return;
      }

      let deleteUrl;
      deleteUrl = "/users/admin/" + user.id;
      this.loading = true;
      this.$http
        .delete(deleteUrl)
        .then((response) => {
          this.loading = false;
          this.$toast.open({
            message: this.$t("users.user_deleted"),
            type: "success",
          });
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$toast.open({
              message: this.$t("users.cant_delete_user"),
              type: "danger",
            });
          }
        });
    },
    checkAllCheckboxes() {
      this.$nextTick(() => {
        let status = this.checkAll;
        this.hasCheckedItems = status;
        for (let user of this.users) {
          user.checked = status;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-column {
  width: 1rem;
}

.comment-column {
  max-width: 250px;
}

.town-column {
  width: 135px;
}

.type-column {
  width: 150px;
}

.name-column {
  width: 250px;
}

.phone-column {
  width: 250px;
}

.email-column {
  width: 250px;
}

.telegram-column {
  width: 150px;
}
</style>

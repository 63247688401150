<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <h2 class="mr-2 d-none d-md-block">Статистика</h2>
          <div class="mr-2 mb-2">
            <a
              href="#"
              class="mr-2"
              :class="{ 'active-date': isToday }"
              @click.prevent="setToday"
            >
              {{ $t("schedule.today") }}</a
            >
            <a
              href="#"
              class=""
              :class="{ 'active-date': isYesterday }"
              @click.prevent="setYesterday"
            >
              {{ $t("schedule.yesterday") }}</a
            >
          </div>
          <div class="">
            <date-picker
              v-model="search.date"
              @change="fetchData"
            ></date-picker>
          </div>
        </div>
        <div class="d-flex align-items-center"></div>
      </div>
    </div>
    <div class="ibox-content">
      <div v-if="loading">{{ $t("common.loading") }}</div>

      <div v-else-if="allStats">
        <ul v-if="allStats.hasTeammates" role="tablist" class="nav nav-tabs">
          <li role="presentation" class="nav-item">
            <a
              role="tab"
              aria-selected="false"
              aria-setsize="5"
              aria-posinset="1"
              href="#"
              target="_self"
              class="nav-link"
              :class="{ active: tab === 'my' }"
              tabindex="-1"
              @click.prevent="tab = 'my'"
              >{{ $t("stats.myData") }}</a
            >
          </li>
          <li role="presentation" class="nav-item">
            <a
              role="tab"
              aria-selected="false"
              aria-setsize="5"
              aria-posinset="2"
              href="#"
              target="_self"
              class="nav-link"
              :class="{ active: tab === 'team' }"
              tabindex="-1"
              @click.prevent="tab = 'team'"
              >{{ $t("stats.myTeam") }}</a
            >
          </li>
        </ul>
        <div v-if="tab === 'my'" class="pt-2">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(fieldI, fIndex) in fields" :key="'f' + fIndex">
                  <th class="label-cell">{{ fieldI.label }}</th>

                  <td>
                    {{ formatNumber(allStats[fieldI.key]) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="tab === 'team'" class="pt-2">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{{ $t("requests.assignee") }}</th>
                  <th>{{ $t("requests.requests") }}</th>
                  <th>{{ $t("stats.teamPercentSum") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(worker, iWorker) in allStats.teamStats"
                  :key="'w' + iWorker"
                >
                  <th>{{ worker.name }}</th>
                  <th>{{ worker.count }}</th>
                  <th>{{ worker.bonusSum }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>{{ $t("common.no_data") }}</div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import DatePicker from "@/components/shared/DatePicker.vue";

export default {
  name: "AssigneeStats",
  components: {
    "date-picker": DatePicker,
  },
  data() {
    return {
      tab: "my",
      dateFormat: "DD.MM.YYYY",
      labelWidth: "150px",
      settings: {},
      loading: false,
      allStats: [],
      search: {
        skipEmpty: true,
        date: "",
        townId: 2,
      },
      fields: [
        // {
        //   key: 'name',
        //   // formatter: this.formatNumber,
        //   label: this.$t("stats.name"),
        //   sortable: true,
        // },
        {
          key: "count",
          formatter: this.formatNumber,
          label: this.$t("stats.count"),
          sortable: true,
        },
        // {
        //   key: 'countNew',
        //   formatter: this.formatNumber,
        //   label: this.$t("stats.countNew"),
        //   sortable: true,
        // },
        {
          key: "countSuccess",
          formatter: this.formatNumber,
          label: this.$t("stats.countSuccess"),
          sortable: true,
        },
        {
          key: "countDeclined",
          formatter: this.formatNumber,
          label: this.$t("stats.countDeclined"),
          sortable: true,
        },
        {
          key: "firstTimeCount",
          formatter: this.formatNumber,
          label: this.$t("stats.firstTimeCount"),
          sortable: true,
        },
        {
          key: "firstTimeSum",
          formatter: this.formatNumber,
          label: this.$t("stats.firstTimeSum"),
          sortable: true,
        },
        {
          key: "averageFirstTimeSum",
          formatter: this.formatNumber,
          label: this.$t("stats.averageFirstTimeSum"),
          sortable: true,
        },
        {
          key: "secondTimeCount",
          formatter: this.formatNumber,
          label: this.$t("stats.secondTimeCount"),
          sortable: true,
        },
        {
          key: "secondTimeSum",
          formatter: this.formatNumber,
          label: this.$t("stats.secondTimeSum"),
          sortable: true,
        },
        {
          key: "averageSecondTimeSum",
          formatter: this.formatNumber,
          label: this.$t("stats.averageSecondTimeSum"),
          sortable: true,
        },
        {
          key: "recordCount",
          formatter: this.formatNumber,
          label: this.$t("stats.recordCount"),
          sortable: true,
        },
        {
          key: "recordSum",
          formatter: this.formatNumber,
          label: this.$t("stats.recordSum"),
          sortable: true,
        },
        {
          key: "paymentCashlessCount",
          formatter: this.formatNumber,
          label: this.$t("stats.paymentCashlessCount"),
          sortable: true,
        },
        {
          key: "paymentCashlessSum",
          formatter: this.formatNumber,
          label: this.$t("stats.paymentCashlessSum"),
          sortable: true,
        },
        {
          key: "tripSum",
          formatter: this.formatNumber,
          label: this.$t("stats.tripSum"),
          sortable: true,
        },

        {
          key: "averageTripSum",
          formatter: this.formatNumber,
          label: this.$t("stats.averageTripSum"),
          sortable: true,
        },
        {
          key: "tripDistance",
          formatter: this.formatNumber,
          label: this.$t("stats.tripDistance"),
          sortable: true,
        },
        {
          key: "equipmentSum",
          formatter: this.formatNumber,
          label: this.$t("stats.equipmentSum"),
          sortable: true,
        },
        {
          key: "fineSum",
          formatter: this.formatNumber,
          label: this.$t("stats.fineSum"),
          sortable: true,
        },

        {
          key: "profitSum",
          formatter: this.formatNumber,
          label: this.$t("stats.profitSum"),
          sortable: true,
        },

        {
          key: "teamBonusSum",
          formatter: this.formatNumber,
          label: this.$t("stats.teamBonusSum"),
          sortable: true,
        },

        {
          key: "toPaySum",
          formatter: this.formatNumber,
          label: this.$t("stats.toPaySum"),
          sortable: true,
        },

        {
          key: "toPaySumLeft",
          formatter: this.formatNumber,
          label: this.$t("stats.toPaySumLeft"),
          sortable: true,
        },

        {
          key: "paymentSum",
          formatter: this.formatNumber,
          label: this.$t("stats.paymentSum"),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    isToday() {
      return this.search.date === dayjs().format("DD.MM.YYYY");
    },
    isYesterday() {
      return this.search.date === dayjs().subtract(1, "d").format("DD.MM.YYYY");
    },
    userStatsFiltered() {
      let skipEmpty = this.search.skipEmpty;
      return this.userStats.filter((user) => {
        if (!skipEmpty) {
          return true;
        }
        return user.count > 0;
      });
    },
    ownServer() {
      // console.log(this.settings.emailSelectServer);
      return this.settings.emailSelectServer === "own";
    },
  },
  watch: {},
  mounted: function () {
    this.fetchData();
  },
  created: function () {
    // this.fetchTowns();
    // this.search.date = dayjs().subtract(7, 'd').format('DD.MM.YYYY');
    this.search.date = dayjs().format("DD.MM.YYYY");
    this.fetchData();
  },
  methods: {
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
    fetchTowns: function () {
      this.townList = [];
      this.townList.push({ value: "", text: this.$t("common.all") });
      let townUrl = "/pest/towns";
      let params = {};
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.townList.push({ value: item.id, text: item.title });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },

    userDayClass(user) {
      if (user.count) {
        return "active-user";
      }
      return "";
    },

    fetchData: function () {
      this.loading = true;
      let settingsUrl = "pest/requests/my-stats";
      this.$http
        .get(settingsUrl, {
          params: this.search,
        })
        .then((response) => {
          this.allStats = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    getTotalRowSum(field) {
      let sum = 0;
      for (let day of this.allStats.days) {
        sum += day.total[field];
      }
      return sum;
    },
    getUserRowSum(index, field) {
      let sum = 0;
      for (let day of this.allStats.days) {
        sum += day.users[index][field];
      }
      return sum;
    },
    setToday() {
      this.search.date = dayjs().format("DD.MM.YYYY");
      this.fetchData();
    },
    setYesterday() {
      this.search.date = dayjs().subtract(1, "d").format("DD.MM.YYYY");
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
thead th:not(:first-child) {
  width: 100px !important;
  max-width: 100px !important;
}

thead th,
tr.user-header-row > td,
tr.user-header-row > th {
  position: sticky;
  top: 0;
}

.delimeter-row td {
  border-top: 2px solid gray;
}

.user-header {
  background: #23c6c8;
}

.active-user {
  background: #d09e23;
}

th.label-cell {
  width: 260px;
}

table {
  width: auto;
}

a.active-date {
  color: #d09e23;
  border-bottom: 1px dashed;
}
</style>

<template>
  <div
    v-click-outside="cancelSearch"
    class="autocomplete-wrapper tags-input"
    @keydown.esc="cancelSearch"
  >
    <div class="ti-input">
      <ul class="ti-tags">
        <li
          v-for="(param, pIndex) in selected"
          :key="'p' + pIndex"
          class="ti-tag"
        >
          <div class="ti-content">
            <div class="ti-tag-center">
              {{ getTitle(param) }}
            </div>
          </div>
          <div class="ti-actions">
            <button
              class="btn-remove"
              type="button"
              @click="removeParam(param, pIndex)"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>
        </li>
        <li class="ti-new-tag-input-wrapper">
          <input
            v-model="search"
            class="tag-search-field"
            autocomplete="off"
            @change="startSearch"
            @keyup="startSearch"
            @focus="startSearch"
          />
        </li>
        <li v-if="selected.length" class="ti-clear-all">
          <button class="btn-clear-all" type="button" @click="clear(false)">
            <font-awesome-icon icon="times" @click="clear(false)" />
          </button>
        </li>
      </ul>
    </div>
    <div v-if="items && items.length">
      <ul v-if="showResults" class="autocomplete-search-list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="autocomplete-search-item"
          @click.prevent="selectItem(item)"
        >
          {{ getTitle(item) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiplyAutocomplete",
  components: {},
  props: {
    label: {},
    title: {},
    placeholder: {},
    required: {},
    defaultValue: {},
    getTitle: {},
    getData: {},
    getValue: {},
  },

  data() {
    return {
      loading: false,
      search: "",
      lastSearch: "",
      showResults: false,
      selected: [],
      items: [],
    };
  },
  computed: {},

  watch: {
    defaultValue: {
      immediate: true,
      handler(value) {
        this.search = value;
      },
    },
  },
  mounted() {
    this.fetchData("");
  },
  methods: {
    getItemValue(item) {
      if (this.getValue) {
        return this.getValue(item);
      }
      return item ? item.id : null;
    },
    fetchData(search) {
      new Promise((resolve) => {
        this.getData(search, resolve);
      }).then((result) => (this.items = result));
    },
    selectItem(item) {
      this.showResults = false;
      this.search = "";
      if (
        !this.selected.find(
          (i) => this.getItemValue(i) === this.getItemValue(item)
        )
      ) {
        this.selected.push(item);
      }
      this.$emit("selected", this.selected);
    },
    clear() {
      this.showResults = false;
      this.search = "";
      this.selected = [];
      this.$emit("selected", this.selected);
    },
    startSearch() {
      if (this.search === this.defaultValue) {
        this.search = "";
      }
      this.showResults = true;
      this.fetchData(this.search);
    },
    cancelSearch() {
      if (!this.search || this.search.trim() === "") {
        this.search = this.defaultValue;
      }
      this.showResults = false;
    },
    removeParam(param, index) {
      this.selected.splice(index, 1);
      this.$emit("selected", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-wrapper {
  position: relative;
}

ul.autocomplete-search-list {
  position: absolute;
  top: 35px;
  z-index: 10000;

  background: #ffffff;
  width: 100%;

  list-style: none;
  border: 1px solid lightgray;
  padding: 0;

  max-height: 300px;
  overflow-y: scroll;
}

.autocomplete-search-item:hover {
  cursor: pointer;
  background: #daa520;
  color: white;
}

.autocomplete-search-item {
  width: 100%;
  margin: 0;
  padding: 10px 20px;
}

.tags-input {
}

.ti-input {
  //padding: 4px 10px;
  transition: border-bottom 200ms ease;

  border: 1px solid #ccc;
  display: flex;
  padding: 2px;
  flex-wrap: wrap;
}

.ti-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1em;
  min-height: 29px;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.ti-tag {
  position: relative;
  background: #d09e23;
  color: black;
  //color: #fff;
  border-radius: 2px;
  display: flex;
  padding: 7px 5px 3px;
  margin: 2px;
  //font-size: .85em;
}

.ti-tag .ti-content[data-v-ce5d7b02] {
  display: flex;
  align-items: center;
}

.ti-tag .ti-tag-center {
  position: relative;
}

.ti-new-tag-input-wrapper {
  display: flex;
  flex: 1 0 auto;
  padding: 3px 5px;
  margin: 2px;
  //font-size: .85em;
}

.ti-new-tag-input {
}

.ti-new-tag-input-wrapper input {
  flex: 1 0 auto;
  min-width: 100px;
  border: none;
  padding: 0px;
  margin: 0px;
}

.ti-new-tag-input-wrapper input:focus {
  outline: none;
}

.ti-actions .btn-remove {
  border: none;
  background: inherit;
  padding-top: 0;
}

.btn-clear-all {
  border: none;
  background: inherit;
  padding: 8px;
}

.ti-actions i {
  //padding: 1px 5px;
}
</style>

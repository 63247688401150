export const ru = {
  aftercallFails: {
    aftercallFails: "Причины провала прозвона",
    aftercallFail_list: "Перечень причин провала прозвона",
    setting_hint: "Указываются при отмене заявки",
    aftercallFail: "Причина провала прозвона",
    add_aftercallFail: "Добавить причину провала прозвона",
    new_aftercallFail: "Новый причину провала прозвона",
    aftercallFail_update: "Изменение причины провала прозвона",
    aftercallFail_updated: "Причина провала прозвона отредактирована",
    cant_create_aftercallFail: "Не удалось добавить причину провала прозвона",
    cant_delete_aftercallFail: "Не удалось удалить причину провала прозвона",
    aftercallFail_created: "Причина провала прозвона добавлена",
    aftercallFail_deleted: "Причина провала прозвона удалена",
    delete_aftercallFail_question:
      "Вы действительно хотите удалить причину провала прозвона?",
    other_reason_label: "Другие (показывать окно ввода)",
  },
};
export const en = {
  aftercallFails: {
    aftercallFails: "Aftercall reject causes",
    aftercallFail_list: "Aftercall reject cause list",
    setting_hint: "Choosing on request cancellation",
    aftercallFail: "Aftercall reject cause",
    add_aftercallFail: "Add aftercall reject cause",
    new_aftercallFail: "New aftercall reject cause",
    change_aftercallFail: "Change aftercall reject cause",
    aftercallFail_update: "Update aftercall reject cause",
    aftercallFail_updated: "Aftercall reject cause changed",
    cant_create_aftercallFail: "Can't add aftercall reject cause",
    cant_delete_aftercallFail: "Can't delete aftercall reject cause",
    aftercallFail_created: "Aftercall reject cause added",
    aftercallFail_deleted: "Aftercall reject cause deleted",
    delete_aftercallFail_question: "Do you want to delete the reject сause?",
    other_reason_label: "Other, show comment field",
  },
};

<template>
  <div v-if="show">
    <button v-if="false" class="btn btn-primary" @click.prevent="fetchData">
      Refresh
    </button>
    <b-overlay :show="loadingData" rounded="sm">
      <b-form-group class="ml-4" style="width: 250px">
        <b-form-select
          v-model="period"
          :options="chartPeriodOptions"
          @change="fetchData"
        />
      </b-form-group>

      <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </b-overlay>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import dayjs from "dayjs";
import {
  chartPeriodOptions,
  getSourceTitle,
  PERIOD_DAILY,
} from "@/modules/pest/decorators/cpo";
import { getUserName } from "@/modules/crm/common";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    hasData: { default: false },
    pests: { default: false },
    selectedTowns: { default: false },
    showEmpty: { default: false },
    chartId: {
      type: String,
      default: "line-chart",
    },
    dateTo: {
      type: String,
      default: "",
    },
    dateFrom: {
      type: String,
      default: "",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("toggle-chart", this.toggleChart);
  },
  beforeDestroy() {
    this.$eventHub.$off("toggle-chart");
  },
  data() {
    return {
      chartPeriodOptions,
      selectedData: [],
      data: [],
      period: PERIOD_DAILY,
      loadingData: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      show: false,
      param: null,
      assigneeId: null,
      sourceId: null,
      pestId: null,
    };
  },
  methods: {
    getDateLabels: function () {
      let labels = [];
      let end = new Date(this.dateTo);
      let start = new Date(this.dateFrom);
      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        labels.push(dayjs(d).format("DD.MM.YYYY"));
      }
      return labels;
    },
    getDailyData(info, props) {
      let data = {};
      for (let prop of props) {
        data[prop] = [];
      }
      let end = new Date(this.dateTo);
      let start = new Date(this.dateFrom);
      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        if (!info.days) {
          continue;
        }
        let date = dayjs(d).format("YYYY-MM-DD");
        let day = info.days.find((d) => d.date === date);
        for (let prop of props) {
          // param.val(info, sourceInfo, assigneeInfo)
          data[prop].push(day ? day[prop] : 0);
        }
      }
      return data;
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    toggleChart({
      block,
      data,
      off,
      param,
      assigneeInfo,
      sourceInfo,
      pestInfo,
    }) {
      if (off) {
        this.selectedData = [];
        this.show = false;
      } else {
        this.assigneeId = assigneeInfo ? assigneeInfo.assigneeId : null;
        this.sourceId = sourceInfo ? sourceInfo.sourceId : null;
        this.pestId = pestInfo ? pestInfo.pestId : null;
        this.param = param;
        this.show = true;
        this.selectedData = [
          {
            param: param,
            assigneeInfo: assigneeInfo,
            sourceInfo: sourceInfo,
            pestInfo: pestInfo,
            // data: { days: data.days },
          },
        ];
        this.fetchData();
      }
    },

    fetchData: function () {
      if (!this.show) {
        return;
      }

      this.loadingData = true;
      let reportUrl = "/pest/report/cpo-graph";
      let params = {
        param: this.param.attr,
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        townIds: this.selectedTowns,
        assigneeId: this.assigneeId,
        sourceId: this.sourceId,
        pestId: this.pestId,
        period: this.period,
      };
      this.$http
        .post(reportUrl, params)
        .then((response) => {
          this.loadingData = false;
          this.data = response.data;
          this.filterData();
        })
        .catch((error) => {
          this.loadingData = false;
          this.processRequestError(error);
        });
    },

    getDatasets() {
      let datasets = [];
      for (let info of this.selectedData) {
        let color = this.getRandomColor();
        // let graphData = this.getDailyData(info.data, [info.param.attr]);
        let graphData = Object.values(this.data.data); // this.getDailyData(info.data, [info.param.attr]);
        let title = info.pestInfo.pestTitle;
        if (info.sourceInfo) {
          title += " • " + getSourceTitle(info.sourceInfo);
        }
        if (info.assigneeInfo) {
          title += " • " + getUserName(info.assigneeInfo.assignee);
        }
        title += " • " + info.param.title;
        datasets.push({
          label: title,
          backgroundColor: color,
          borderColor: color,
          data: graphData,
        });
      }
      return datasets;
    },
    filterData() {
      let chartData = {
        labels: Object.keys(this.data.data),
        datasets: this.getDatasets(),
      };
      this.chartData = chartData;
    },
    refreshTable() {},
  },
};
</script>

<style scoped lang="scss">
.w100 {
  width: 200px !important;
}
</style>

import { getTownTitle } from "@/modules/pest/decorators/towns";
import dayjs from "dayjs";
import t from "@/i18n";

export const FINE_STATUS_NEW = 0;
export const FINE_STATUS_PROCESSING = 1;
export const FINE_STATUS_VERIFIED = 3;
export const FINE_STATUS_DECLINED = 4;

export const statusTitles = {
  [FINE_STATUS_NEW]: t("requests_statuses.r_new"),
  [FINE_STATUS_PROCESSING]: t("requests_statuses.r_new"),
  [FINE_STATUS_VERIFIED]: t("requests_statuses.r_new"),
  [FINE_STATUS_DECLINED]: t("requests_statuses.r_new"),
};

export const getFineStatusTitle = function (fine) {
  if (!fine.status && fine.status !== FINE_STATUS_NEW) {
    return t("common.not_set");
  }
  return statusTitles[fine.status];
};

export const getFineTitle = function (fine) {
  if (!fine || !fine.title) {
    return t("common.not_set");
  }
  return fine.title;
};

export const statusIcons = {
  [FINE_STATUS_NEW]: "file",
  [FINE_STATUS_PROCESSING]: "sync",
  [FINE_STATUS_VERIFIED]: "check",
  [FINE_STATUS_DECLINED]: "times",
};

export const getFineStatusIcon = function (fine) {
  if (!fine.status && fine.status !== FINE_STATUS_NEW) {
    return t("common.not_set");
  }
  return statusIcons[fine.status];
};

export const getFineIcon = function (fine) {
  if (!fine || !fine.icon) {
    return t("common.not_set");
  }
  return fine.icon;
};

export const getFineTownTitle = function (fine) {
  if (!fine || !fine.town) {
    return t("common.not_set");
  }
  return getTownTitle(fine.town);
};

export const getFineTime = function (fine) {
  return dayjs.unix(fine.time).format("DD.MM.YYYY HH:mm:ss");
};
export const getFinePayTime = function (fine) {
  return dayjs.unix(fine.payTime).format("DD.MM.YYYY HH:mm:ss");
};
export const getDepositTime = function (fine) {
  return dayjs.unix(fine.depositTime).format("DD.MM.YYYY HH:mm:ss");
};

export const getFineDate = function (fine) {
  return dayjs.unix(fine.time).format("DD.MM.YYYY");
};

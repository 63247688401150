<template>
  <div class="ibox">
    <div class="ibox-title pr-3">
      <div class="d-flex align-items-center">
        <h3>{{ $t("requests.trips_history") }}</h3>
        <div class="ml-auto"></div>
      </div>
    </div>
    <div class="ibox-content">
      <div v-if="sequence && sequence.length > 1">
        <div
          v-for="(aRequest, srIndex) in sequence"
          :key="'sr' + srIndex"
          class="mb-4 pb-3 previous-request"
        >
          <h4>
            <!--                  @click.prevent="showRequestPopup(request)"-->
            <!--                  :class="getRequestTabClass(request.id)"-->
            <span class="label mr-2"> {{ aRequest.indexNumber }}</span>
            {{ getRequestNumber(aRequest) }}
          </h4>

          <div class="mb-1 d-flex">
            <div>
              <font-awesome-icon icon="user-cog" fixed-width class="mr-1" />
              <!-- Исполнитель-->
              {{ getRequestAssigneeName(aRequest) }}
            </div>
          </div>

          <div
            v-if="Object.keys(aRequest.extraTechnicians).length > 0"
            class="mb-1 d-flex"
          >
            <div>
              <font-awesome-icon icon="users-gear" fixed-width class="mr-1" />
              <!-- Доп. техник -->

              <span
                v-for="(extraTechnician, index) in aRequest.extraTechnicians"
                :key="'adp' + index"
              >
                {{ getRequestTechnicianName(extraTechnician)
                }}<template
                  v-if="
                    index !== Object.keys(aRequest.extraTechnicians).length - 1
                  "
                  >,
                </template>
              </span>
            </div>
          </div>

          <div class="mb-1 d-flex">
            <div>
              <!-- Время -->
              <font-awesome-icon icon="clock" fixed-width class="mr-1" />
              {{ getRequestDatetime(aRequest) }}
            </div>
          </div>

          <div class="mb-1">
            <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
            <!--              Сумма -->
            {{
              getRequestDisplayableSum(aRequest)
                ? getRequestDisplayableSum(aRequest)
                : " – "
            }}

            <span v-if="aRequest.earned"> / {{ aRequest.earned }} </span>
            <span v-if="aRequest.hasRecord">
              <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
            </span>

            <span
              v-if="
                aRequest.isOrganization && aRequest.paymentType == PT_BANK_TRANS
              "
            >
              ({{ $t("requests.payment_bank_trans") }})
            </span>
          </div>
          <div v-if="hasAnyResources(aRequest)" class="mb-1 d-flex">
            <font-awesome-icon icon="vial" fixed-width class="mr-1 mt-1" />
            <ul class="inline mb-0 pl-3">
              <li v-for="(resource, index) in aRequest.resources" :key="index">
                {{
                  resource.resource
                    ? resource.resource.title
                    : $t("requests.without_resource")
                }}
                <span v-if="resource.count">
                  – {{ $t("requests.solution_volume_short") }}-
                  {{ resource.solutionVolume }}
                  {{ $t("common.unit_l") }}, {{ $t("requests.dosage_short") }}.-
                  {{ resource.dosage }}
                  {{ $t("common.unit_ml_l") }},
                  {{ $t("requests.consumption") }} - {{ resource.count
                  }}{{ $t("common.unit_ml") }}</span
                >
              </li>
            </ul>
          </div>
          <div v-if="hasAnyServices(aRequest)" class="mb-1 d-flex">
            <font-awesome-icon icon="gears" fixed-width class="mr-1 mt-1" />
            <ul class="inline mb-0 pl-3">
              <li v-for="(service, index) in aRequest.services" :key="index">
                {{ service.title }} - {{ service.price }}
              </li>
            </ul>
          </div>
          <div v-if="hasAnyProducts(aRequest)" class="mb-1 d-flex align-middle">
            <font-awesome-icon icon="box" fixed-width class="mr-1 mt-1" />
            <ul class="inline mb-0 pl-3">
              <li v-for="(product, index) in aRequest.products" :key="index">
                {{ product.product.title }} - {{ product.price }} ({{
                  product.cost
                }})
              </li>
            </ul>
          </div>

          <div class="mb-1">
            <!-- Комментарий менеджера -->
            <font-awesome-icon icon="comment" fixed-width class="mr-1" />
            {{ aRequest.comment ? aRequest.comment : " – " }}
          </div>

          <div class="mb-1">
            <!-- Комментарий мастера -->
            <font-awesome-icon icon="comments" fixed-width class="mr-1" />
            {{ aRequest.assigneeComment ? aRequest.assigneeComment : " – " }}
          </div>
          <div v-if="aRequest.warning" class="mb-1 text-warning">
            <!--              Особое внимание -->
            <font-awesome-icon
              icon="exclamation-triangle"
              fixed-width
              class="mr-1"
            />
            {{ aRequest.warning }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRequestAssigneeName,
  getRequestDatetime,
  getRequestDisplayableSum,
  getRequestNumber,
  getRequestTechnicianName,
  hasAnyProducts,
  hasAnyResources,
  hasAnyServices,
  PT_BANK_TRANS,
} from "@/modules/pest/decorators/requests.js";

export default {
  name: "RequestsSequenceHistory",
  components: {},
  props: ["request", "sequence"],
  data() {
    return {
      curRequest: null,
      loadingRequests: false,
      PT_BANK_TRANS,
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted() {},
  methods: {
    getRequestTechnicianName,
    hasAnyProducts,
    hasAnyServices,
    hasAnyResources,
    getRequestDisplayableSum,
    getRequestAssigneeName,
    getRequestNumber,
    getRequestDatetime,
  },
};
</script>

<style lang="scss" scoped>
.previous-request:not(:last-child) {
  border-bottom: 1px solid grey;
}
</style>

<template>
  <div>
    <div class="ibox">
      <div class="ibox-title d-flex align-items-center">
        <h3>Отчет по менеджерам</h3>
        <div class="ml-2">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>
        <div class="ml-2">Проект:</div>
        <div class="ml-2">
          <b-form-select
            v-model="projectId"
            :options="projectsList"
            @change="fetchData"
          />
        </div>
      </div>
    </div>

    <div class="ibox-content">
      <b-overlay :show="loadingData" rounded="sm">
        <div :class="{ 'd-none': hasData }">Отчет еще не построен</div>
        <div class="table-responsive" :class="{ 'd-none': !hasData }">
          <managers-data-table
            ref="reportTable"
            :has-data="hasData"
            :source-data="data"
          />
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "@/components/shared/DateRangePicker";
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import ManagersDataTable from "@/modules/pest/views/reports/managers/ManagersDataTable";

export default {
  name: "ReportManagers",
  components: {
    DateRangePicker,
    ManagersDataTable,
  },
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      dateFrom: null,
      dateTo: null,
      search: {
        date: "",
      },
      data: [],
      hasData: false,
      loadingData: false,
      sortConversion: true,
      projectId: null,
      projectsList: [],
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.dateFrom, this.dateTo];
      },
      set(val) {
        this.dateFrom = val[0];
        this.dateTo = val[1];
      },
    },
  },
  mounted: function () {
    let filterDates = this.getProp()("report-manager-filter-dates");
    if (filterDates) {
      this.dateTo = filterDates.dateTo;
      this.dateFrom = filterDates.dateFrom;
    }
  },
  created: function () {
    this.fetchProjects();
    let filterDates = this.getProp()("report-manager-filter-dates");
    if (filterDates) {
      this.dateTo = filterDates.dateTo;
      this.dateFrom = filterDates.dateFrom;
      this.projectId = filterDates.projectId;
    }

    if (!this.dateTo) {
      this.dateTo = dayjs().format("YYYY-MM-DD");
    }
    if (!this.dateFrom) {
      this.dateFrom = dayjs().subtract(1, "month").format("YYYY-MM-DD");
    }
    this.fetchData();
  },

  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    fetchData() {
      this.loadingData = true;

      let reportUrl = "/pest/report/managers";
      let params = {
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        projectId: this.projectId,
      };
      this.saveFilterData();
      this.$http
        .post(reportUrl, params)
        .then((response) => {
          this.loadingData = false;
          this.data = response.data;
          this.hasData = true;
          this.sortConversion = true;
          this.$refs.reportTable.createGradient();
          this.$refs.reportTable.$forceUpdate();
        })
        .catch((error) => {
          this.loadingData = false;
          this.processRequestError(error);
        });
    },
    fetchProjects: function () {
      this.projectsList = [];
      let projectUrl = "pest/project/search";
      this.$http
        .get(projectUrl)
        .then((response) => {
          for (let item of response.data) {
            this.projectsList.push({
              value: item.id,
              text: item.title,
            });
          }
          this.$forceUpdate();
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    saveFilterData() {
      this.setProp({
        key: "report-manager-filter-dates",
        value: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          projectId: this.projectId,
        },
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <table class="table table-striped request-info">
      <tbody>
        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.number") }}
          </td>
          <td>
            {{ request.externalId }}
          </td>
        </tr>
        <tr>
          <td><font-awesome-icon icon="paperclip" fixed-width /> Проект</td>
          <td>
            {{ getRequestProjectTitle(request) }}
          </td>
        </tr>
        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.title") }}
          </td>
          <td>
            {{ request.title }}
          </td>
        </tr>

        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.comment") }}
          </td>
          <td>
            {{ request.text }}
          </td>
        </tr>

        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.client_name") }}
          </td>
          <td>
            {{ request.name }}
          </td>
        </tr>

        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.roistat_label") }}
          </td>
          <td>
            {{ request.roistat }}
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon
              v-if="request.isOrganization"
              icon="building"
              fixed-width
            />
            <font-awesome-icon v-else icon="user" fixed-width />
            {{ $t("requests.client") }}
          </td>
          <td>
            <span v-if="request.clientId">
              {{ getRequestClientName(request) }}
              <router-link
                v-if="canOpenToAssignee"
                :to="{
                  name: 'crm-client-view',
                  params: { id: request.clientId },
                }"
                class=""
              >
                <font-awesome-icon
                  icon="external-link-square-alt"
                  fixed-width
                />
              </router-link>
            </span>
            <span v-else>{{ $t("common.not_set") }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="phone" fixed-width />
            {{ $t("requests.phone") }}
          </td>
          <td>
            <div class="d-flex">
              <div v-if="canViewPhone" class="">{{ request.phone }}</div>
              <div v-else class="">{{ $t("requests.phone_is_forbidden") }}</div>
            </div>
          </td>
        </tr>

        <tr v-if="request.comment">
          <td>
            <font-awesome-icon icon="comment" fixed-width />
            {{ $t("requests.comment") }}
          </td>
          <td>
            <span>{{ request.comment }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAssignee1stName,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDuration,
  getRequestManagerName,
  getRequestProjectTitle,
  getRequestStatus,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestUpdateTime,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests";
import { VueMaskDirective } from "v-mask";
import { getUserName } from "@/modules/crm/common";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities";

export default {
  name: "RequestLogRoistatImportedInfo",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["request"],
  data() {
    return {
      STATUS_DONE: STATUS_DONE,
      loading: false,
      closeRequest: false,
      decisionForm: false,
      calling: false,
      opening: false,
      requestingAddress: false,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    canAssigneeViewAppartment() {
      return this.request.assigneeCanSeeAddress;
    },
    canIViewAppartment() {
      return this.canViewPhone || this.request.assigneeCanSeeAddress;
    },
    canOpenToAssignee() {
      return this.canViewPhone;
    },
    canViewPhone() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },
  },
  watch: {
    $route(to, from) {
      this.showAddress();
    },
  },
  created: function () {},
  methods: {
    getRequestProjectTitle,
    getPestTitle,
    getRequestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestAssignee1stName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    getPaymentTypeTitle,
    getFacilityTitle,
    getRequestColor,
  },
};
</script>

<style lang="scss" scoped>
table.request-info td:first-child {
  min-width: 155px !important;
}

td.title-column {
}
</style>

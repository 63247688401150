<template>
  <div>
    {{ $t("notifications.you_ve_made_the_record") }} <strong>{{ sum }}р</strong>
    <br />
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "NotificationUserNewRecord",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {
    sum() {
      return this.formatNumber(this.notification.serviceSum);
    },
  },
  watch: {},
  methods: {
    getUserName,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-form
    id="pest-price-form"
    ref="price"
    :model="price"
    class="pest-price-form"
    novalidate
    @submit.prevent="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-md-3">
          <b-form-group label="Город" prop="townIds">
            <town-autocomplete
              ref="townAutocomplete"
              required=""
              :default-value="price.townId ? getTownTitle(price.town) : ''"
              @selected="selectTown"
            ></town-autocomplete>
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group label="Тип вредителя" prop="title">
            <pest-autocomplete
              v-model="price.pestId"
              :default-value="getRequestPestName(price)"
              @selected="selectPest"
            ></pest-autocomplete>
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group label="Физ\Юр. лицо" prop="title">
            <b-button-group>
              <button
                class="btn"
                :class="getClientTypeClass(CLIENT_TYPE_IND)"
                @click.prevent="selectClientType(CLIENT_TYPE_IND)"
              >
                {{ $t("requests.type_individual") }}
              </button>
              <button
                class="btn"
                :class="getClientTypeClass(CLIENT_TYPE_ORG)"
                @click.prevent="selectClientType(CLIENT_TYPE_ORG)"
              >
                {{ $t("requests.type_organization") }}
              </button>
            </b-button-group>
          </b-form-group>
        </div>
        <div v-if="price.clientType === CLIENT_TYPE_ORG" class="col-md-3">
          <b-form-group label="Тип оплаты" prop="title">
            <b-button-group>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(PT_CASH)"
                @click.prevent="selectClientPaymentType(PT_CASH)"
              >
                Нал
              </button>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(PT_BANK_TRANS)"
                @click.prevent="selectClientPaymentType(PT_BANK_TRANS)"
              >
                Безнал
              </button>
            </b-button-group>
          </b-form-group>
        </div>
      </div>

      <hr />

      <b-overlay :show="loadingPrices" rounded="sm"></b-overlay>

      <div v-if="prices.length">
        <div v-for="(aPrice, pIndex) in prices" :key="pIndex" class="row">
          <div class="col-3">
            <b-form-group label="Объект" prop="title">
              <facility-autocomplete
                v-model="aPrice.facilityId"
                :add-new="true"
                :default-value="getRequestFacilityName(aPrice)"
                @selected="selectFacility($event, aPrice)"
              ></facility-autocomplete>
            </b-form-group>
          </div>

          <div class="col-3">
            <b-form-group label="Стоимость" prop="title">
              <b-form-input
                v-model="aPrice.price"
                placeholder=""
                number
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-3">
            <b-form-group :label="$t('requests.comment')" prop="title">
              <b-form-input
                v-model="aPrice.comment"
                placeholder=""
                number
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-2 pt-3">
            <button
              class="btn btn-danger mt-2"
              @click.prevent="removePrice(pIndex)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </div>
        </div>

        <button class="btn btn-primary" @click.prevent="addPrice">
          <font-awesome-icon icon="plus" />
          Добавить
        </button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import TownAutocomplete from "@/modules/pest/views/towns/TownAutocomplete.vue";
import PestAutocomplete from "@/modules/pest/views/pests/PestAutocomplete.vue";
import FacilityAutocomplete from "@/modules/pest/views/facilities/FacilityAutocomplete.vue";
import {
  getTownTitle,
  DXB,
  MSK,
  MSK_C,
  SPB,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import {
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  getRequestClientName,
  getRequestFacilityName,
  getRequestManagerName,
  getRequestPestName,
  PT_CASH,
  PT_BANK_TRANS,
} from "@/modules/pest/decorators/requests.js";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import { TYPE_MANAGER } from "@/modules/users/decorators/users.js";

export default {
  name: "PestPriceForm",
  directives: { mask: VueMaskDirective },
  components: {
    TownAutocomplete,
    PestAutocomplete,
    FacilityAutocomplete,
  },
  props: ["priceModel"],
  data() {
    return {
      price: {
        id: "new",
        title: "",
        townId: "",
        town: "",
        pestId: "",
        pest: "",
        paymentType: PT_CASH,
        clientType: CLIENT_TYPE_IND,
      },
      prices: [],
      loadingPrices: false,
      MSK,
      SPB,
      DXB,
      MSK_C,
      SPB_C,
      PT_BANK_TRANS,
      PT_CASH,
      CLIENT_TYPE_ORG,
      CLIENT_TYPE_IND,
      TYPE_MANAGER,
      phoneMask: false,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.price.id;
    },
    name() {
      let name = this.price.name ? this.price.name : "Новый цена";
      return name ? name : "Цена";
    },
  },
  watch: {
    priceModel: {
      immediate: true,
      handler(obj) {
        // this.price = Object.assign({}, obj);
      },
    },
  },
  created: function () {},
  methods: {
    getTownTitle,
    getRequestPestName,
    getRequestManagerName,
    getRequestClientName,
    getRequestFacilityName,
    getClientName,
    getUserName,
    getStatusTitle,
    getClientNameWithContacts,

    loadPrices: function () {
      if (
        !this.price.townId ||
        !this.price.pestId ||
        (this.price.clientType === CLIENT_TYPE_ORG && !this.price.paymentType)
      ) {
        return;
      }
      this.loadingPrices = true;
      let pricesUrl = "/pest/prices";
      let params = {
        all: 1,
        townId: this.price.townId,
        pestId: this.price.pestId,
        clientType: this.price.clientType,
        paymentType: this.price.paymentType,
      };
      this.$http
        .get(pricesUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingPrices = false;
          this.prices = response.data;
          if (this.prices.length === 0) {
            this.prices.push({
              facilityId: null,
              price: null,
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loadingPrices = false;
          this.processRequestError(error);
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/prices";
      this.loading = true;
      let data = {
        townId: this.price.townId,
        pestId: this.price.pestId,
        paymentType: this.price.paymentType,
        clientType: this.price.clientType,
        prices: this.prices.map(function (p) {
          return {
            facilityId: p.facilityId,
            price: p.price,
            comment: p.comment,
          };
        }),
      };
      this.$http
        .post(updateUrl, data)
        .then((response) => {
          this.$toast.open({
            message: "Цена обновлены",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processPriceError(error)) {
            this.$toast.open({
              message: "Не удалось обновить цены",
              type: "danger",
            });
          }
        });
    },
    validate() {
      let errors = false;
      if (!this.price.townId) {
        errors = true;
        this.$toast.open({
          message: "Выберите город",
          type: "danger",
        });
      }
      if (!this.price.pestId) {
        errors = true;
        this.$toast.open({
          message: "Выберите вредителя",
          type: "danger",
        });
      }
      if (!this.price.clientType) {
        errors = true;
        this.$toast.open({
          message: "Выберите тип клиента",
          type: "danger",
        });
      }
      if (!this.price.paymentType) {
        errors = true;
        this.$toast.open({
          message: "Выберите способ оплаты для юр лица",
          type: "danger",
        });
      }
      return !errors;
    },
    createOrUpdate: function () {
      let form = document.getElementById("pest-price-form");
      let valid = form.checkValidity() && this.validate();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$toast.open(this.$t("common.data_is_saving"), {
            message: this.$t("common.please_wait"),
            type: "info",
          });
          return;
        }
        this.create();
        form.classList.remove("was-validated");
      } else {
        this.$toast.open({
          message: this.$t("common.form_has_errors"),
          type: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deletePrice() {
      if (confirm("Вы действительно хотите удалить цена?")) {
        let deleteUrl = "/pest/prices/" + this.price.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-price-list" });
            this.$bvToast.toast("Цена удален", {
              title: "Цена удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processPriceError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: "Не удалось удалить цена",
                variant: "danger",
              });
            }
          });
      }
    },
    selectPest(data) {
      if (data && data.value) {
        this.price.pest = data.value;
        this.price.pestId = data.value.id;
      } else {
        this.price.pest = {};
        this.price.pestId = null;
      }
      this.loadPrices();
      this.$forceUpdate();
    },
    selectFacility(data, obj) {
      if (data && data.value) {
        obj.facility = data.value;
        obj.facilityId = data.value.id;
      } else {
        obj.facility = {};
        obj.facilityId = null;
      }
    },
    selectTown(data) {
      if (data && data.value) {
        this.price.town = data.value;
        this.price.townId = data.value.id;
      } else {
        this.price.town = {};
        this.price.townId = null;
      }
      this.loadPrices();
      this.$forceUpdate();
    },
    getTownClass(town) {
      if (this.price.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientType(type) {
      this.price.clientType = type;
      this.$forceUpdate();
      this.loadPrices();
    },
    getClientTypeClass(type) {
      if (this.price.clientType === type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientPaymentType(type) {
      this.price.paymentType = type;
      this.loadPrices();
    },
    getClientPaymentTypeClass(type) {
      if (this.price.paymentType == type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    addPrice() {
      this.prices.push({
        facilityId: null,
        price: null,
      });
    },
    removePrice(index) {
      this.prices.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>

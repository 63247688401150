<template>
  <div>
    <router-link
      v-if="canAddFine"
      :to="{ name: 'pest-fine-update', params: { id: notification.itemId } }"
      class=""
    >
      {{ $t("fines.fine_n") }}{{ notification.itemId }}
    </router-link>
    <span v-else>{{ $t("fines.fine_n") }}{{ notification.itemId }}</span>
    {{ $t("fines.assigned_with_sum") }}:
    <strong>{{ sum }}{{ $t("common.cur") }}</strong> <br />
    <strong>{{ $t("requests.comment") }}:</strong>
    {{ notification.fine.comment }} <br />
    {{ notification.fine.review }}
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { canAddFines } from "@/modules/users/access/common.js";

export default {
  name: "NotificationFullFineApproved",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {
    sum() {
      return this.formatNumber(this.notification.fine.sum);
    },
    canAddFine() {
      return canAddFines(this.$store.state.user);
    },
  },
  watch: {},
  methods: {
    getUserName,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>

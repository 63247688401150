<template>
  <div class="pest-aftercall-fail-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link
              :to="{ name: 'pest-aftercall-fail-list' }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <div>
            <h1>
              {{ aftercallFail.title }}
            </h1>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <pest-aftercall-fail-info
          :aftercall-fail="aftercallFail"
          @update-prop="updateProp"
        ></pest-aftercall-fail-info>
      </div>
    </div>
  </div>
</template>

<script>
import AftercallFailInfo from "./AftercallFailInfo";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "AftercallFail",
  components: {
    "pest-aftercall-fail-info": AftercallFailInfo,
  },
  data() {
    return {
      tabIndex: 0,
      aftercallFail: {
        id: "new",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      this.aftercallFail.phone = "";
      let aftercallFailsUrl = "/pest/aftercall-fails/" + this.id;
      this.loading = true;
      this.$http
        .get(aftercallFailsUrl)
        .then((response) => {
          this.loading = false;
          this.aftercallFail = response.data;
          this.aftercallFail.managerName = this.aftercallFail.manager.username;
          this.header = this.aftercallFail.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/aftercall-fails/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.aftercallFail)
        .then((response) => {
          this.aftercallFail = response.data;
          this.loading = false;
          this.$eventHub.$emit(
            "update-pest-aftercall-fail-info",
            this.aftercallFail
          );
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("aftercallFails.aftercallFail_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>

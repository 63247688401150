import t from "@/i18n";

export const getSourceTitle = function (source) {
  if (!source || !source.title) {
    return t("common.not_set");
  }
  return source.title;
};

export const TYPE_ROISTAT = 0;
export const TYPE_MANUAL = 1;
export const CPO_SOURCE_ROISTAT = "roistat";
export const CPO_SOURCE_NONE = 0;
export const sourceTypeTitles = {
  [TYPE_ROISTAT]: "type_roistat",
  [TYPE_MANUAL]: "type_manual",
};
export const sourceTypeOptions = function () {
  return [
    { value: TYPE_ROISTAT, text: t("sources.type_roistat") },
    { value: TYPE_MANUAL, text: t("sources.type_manual") },
  ];
};
export const getSourceTypeTitle = function (source) {
  return t("sources." + sourceTypeTitles[source.type]);
};

export const TARGET_LEAD = 0;
export const TARGET_IN_CONTRACT = 1;
export const sourceTargetTitles = {
  [TARGET_LEAD]: "target_lead",
  [TARGET_IN_CONTRACT]: "target_contract",
};
export const sourceTargetOptions = function () {
  return [
    { value: TARGET_LEAD, text: t("sources.type_roistat") },
    { value: TARGET_IN_CONTRACT, text: t("sources.type_manual") },
  ];
};
export const getSourceTargetTitle = function (source) {
  return sourceTargetTitles[source.target];
};

<template>
  <div class="pest-deposit-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>{{ $t("инкассация.new_инкассация") }}</h1>
          </div>
          <div class="ibox-content">
            <pest-deposit-form :deposit="deposit"></pest-deposit-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepositForm from "./DepositForm";

export default {
  name: "DepositCreate",
  components: {
    "pest-deposit-form": DepositForm,
  },
  data() {
    return {
      deposit: {
        id: "new",
        title: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>

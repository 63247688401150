import ClientList from "@/modules/crm/views/client/ClientList.vue";
import ClientCreate from "@/modules/crm/views/client/ClientCreate.vue";
import ClientUpdate from "@/modules/crm/views/client/ClientUpdate.vue";
import ClientView from "@/modules/crm/views/client/ClientView.vue";

export default [
  {
    path: "/clients/page:page?",
    name: "crm-client-list",
    components: {
      default: ClientList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/clients/new/:organizationId?",
    name: "crm-client-create",
    components: {
      default: ClientCreate,
      // 'client-header': ClientHeader,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/clients/:id/update",
    name: "crm-client-update",
    components: {
      default: ClientUpdate,
      // 'client-header': ClientHeader,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/clients/:id",
    name: "crm-client-view",
    components: {
      default: ClientView,
      // 'client-header': ClientHeader,
    },
    meta: { requiresAuth: true },
  },
];

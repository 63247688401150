<template>
  <div>
    <div>
      <strong>{{ $t("requests.old_manager") }}: </strong>
      {{ getUserName(log.oldManager) }}
    </div>
    <div>
      <strong>{{ $t("requests.new_manager") }}: </strong>
      {{ getUserName(log.newManager) }}
    </div>
    <div v-if="log.cause">
      <strong>{{ $t("requests.cause") }}: </strong> {{ getCauseTitle(log) }}
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { getCauseTitle } from "@/modules/pest/decorators/logsChangeManager";

export default {
  name: "RequestLogChangedManager",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: { getUserName, getCauseTitle },
};
</script>

<style lang="scss" scoped></style>

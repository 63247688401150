import TownCreate from "@/modules/pest/views/towns/TownCreate";
import TownList from "@/modules/pest/views/towns/TownList";
import TownUpdate from "@/modules/pest/views/towns/TownUpdate";
import TownView from "@/modules/pest/views/towns/TownView";

export default [
  {
    path: "/towns/page:page?",
    name: "pest-town-list",
    components: {
      default: TownList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/towns/new",
    name: "pest-town-create",
    components: {
      default: TownCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/towns/:id/update",
    name: "pest-town-update",
    components: {
      default: TownUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/towns/:id",
    name: "pest-town-view",
    components: {
      default: TownView,
    },
    meta: { requiresAuth: true },
  },
];

import t from "@/i18n";

export const PERCENT_TYPE_DEFAULT = "default";
export const PERCENT_TYPE_MANUAL = "manual";
export const SERVICE_DEFAULT = 0;
export const SERVICE_DOUBLE = 1;

const getPestTitle = function (pest) {
  if (!pest || !pest.title) {
    return t("common.not_set");
  }
  return pest.title;
};

export { getPestTitle };

export const getPestsDoubleNames = function (pest) {
  if (pest && pest.pests && pest.pests.length) {
    let pests = [];
    for (let aPest of pest.pests) {
      pests.push(getPestTitle(aPest));
    }
    return pests.join(", ");
  }
  return t("common.not_set");
};

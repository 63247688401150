import FacilityCreate from "@/modules/pest/views/facilities/FacilityCreate";
import FacilityList from "@/modules/pest/views/facilities/FacilityList";
import FacilityUpdate from "@/modules/pest/views/facilities/FacilityUpdate";
import FacilityView from "@/modules/pest/views/facilities/FacilityView";

export default [
  {
    path: "/facilities/page:page?",
    name: "pest-facility-list",
    components: {
      default: FacilityList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/facilities/new/:userId?",
    name: "pest-facility-create",
    components: {
      default: FacilityCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/:id/update",
    name: "pest-facility-update",
    components: {
      default: FacilityUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/:id",
    name: "pest-facility-view",
    components: {
      default: FacilityView,
    },
    meta: { requiresAuth: true },
  },
];

import ProductList from "@/modules/pest/views/product/ProductList.vue";
import ProductCreate from "@/modules/pest/views/product/ProductCreate.vue";
import ProductUpdate from "@/modules/pest/views/product/ProductUpdate.vue";
import ProductView from "@/modules/pest/views/product/ProductView.vue";

export default [
  //////////////////////////////////////////////////////
  {
    path: "/product/page:page?",
    name: "pest-product-list",
    components: {
      default: ProductList,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/product/new",
    name: "pest-product-create",
    components: {
      default: ProductCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/product/:id/update",
    name: "pest-product-update",
    components: {
      default: ProductUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/product/:id",
    name: "pest-product-view",
    components: {
      default: ProductView,
    },
    meta: { requiresAuth: true },
  },
];

import UserNotifications from "@/modules/notifications/views/notifications/UserNotifications.vue";
import AllNotifications from "@/modules/notifications/views/notifications/AllNotifications.vue";

export default [
  {
    path: "/notifications/my/list:page?",
    name: "notifications-notification-user-list",
    components: {
      default: UserNotifications,
    },
    meta: {
      pageTitle: "menu.messages_notifications",
    },
  },
  {
    path: "/notifications/all/list:page?",
    name: "notifications-notification-all-list",
    components: {
      default: AllNotifications,
    },
  },
  // {
  //     path: '/notifications/all/list:page?',
  //     name: 'notifications-all-notification-list',
  //     components: {
  //         default: Notifications
  //     }
  // },
];

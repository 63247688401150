import { getPestTitle } from "@/modules/pest/decorators/pests";
import { getAccountTitle } from "@/modules/direct/decorators/accounts";
import { townOptions } from "@/modules/pest/decorators/towns";
import t from "@/i18n";

const getCampaignTitle = function (campaign) {
  if (!campaign || !campaign.title) {
    return t("common.not_set");
  }
  return campaign.title;
};

export { getCampaignTitle };

export const getCampaignPestName = function (campaign) {
  if (campaign.pests && campaign.pests.length) {
    let pests = [];
    for (let pest of campaign.pests) {
      pests.push(getPestTitle(pest));
    }
    return pests.join(", ");
  }
  if (!campaign.pest) {
    return "[Не назначен]";
  }
  return getPestTitle(campaign.pest);
};

export const getCampaignRegionName = function (campaign) {
  if (!campaign.regionId || !townOptions[campaign.regionId]) {
    return "[Не назначен]";
  }
  return townOptions[campaign.regionId].text;
};

export const getCampaignAccountName = function (campaign) {
  if (!campaign.account) {
    return "[Не назначен]";
  }
  return getAccountTitle(campaign.account);
};

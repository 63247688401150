<template>
  <b-form
    id="pest-fine-form"
    ref="fine"
    :model="fine"
    class="pest-fine-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('fines.assignee')" prop="employeeId">
            <user-autocomplete
              v-model="fine.employeeId"
              :default-value="getEmployeeName(fine.employee)"
              @selected="selectEmployee"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.description')" prop="sum">
            <b-form-textarea
              v-model="fine.comment"
              :placeholder="$t('common.description')"
              :disabled="!canBeEdited"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('fines.sum')" prop="sum">
            <b-form-input
              v-model="fine.sum"
              tabindex="1"
              :disabled="!canBeEdited"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div v-if="fine.id !== 'new'" class="row">
        <div class="col-12">
          <b-form-group :label="$t('fines.who_assigned')" prop="sum">
            {{ getUserName(fine.manager) }}
          </b-form-group>
        </div>
      </div>

      <div v-if="canBeEdited" class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            <span v-if="fine.id === 'new'">{{ $t("common.save") }}</span>
            <span v-else> {{ $t("common.save") }}</span>
          </b-button>

          <a
            v-if="fine.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteFine"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getClientName } from "@/modules/crm/decorators/client";
import { TYPE_LOGIST } from "@/modules/users/decorators/users";
import {
  getRequestClientName,
  getRequestDatetime,
  getRequestTime,
  getRequestTitle,
  servicesSum,
  toPaySum,
} from "@/modules/pest/decorators/requests";
import { FINE_STATUS_NEW } from "@/modules/pest/decorators/fines";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete";

export default {
  name: "PestFineForm",
  directives: { mask: VueMaskDirective },
  components: { UserAutocomplete },
  props: ["fine"],
  data() {
    return {
      phoneMask: false,
      loading: false,
      requestLoading: false,
      toPay: 0,
      earn: 0,
    };
  },
  computed: {
    id() {
      return this.fine.id;
    },
    name() {
      let name = this.fine.name ? this.fine.name : this.$t("fines.new_fine");
      return name ? name : "fine";
    },
    canBeEdited() {
      let isNew = this.fine.status === FINE_STATUS_NEW;
      // console.log(isNew);
      // let sameUser = this.fine.managerId === this.$store.state.user.id;
      return isNew; //|| sameUser;
    },
  },
  watch: {
    $route() {
      let userId =
        this.$route.params.userId !== undefined
          ? this.$route.params.userId
          : null;
      if (userId) {
        this.fine.assigneeId = userId;
      }
    },
  },
  created: function () {},
  mounted() {
    let userId =
      this.$route.params.userId !== undefined
        ? this.$route.params.userId
        : null;
    if (userId) {
      this.fine.employeeId = userId;
    }
  },
  methods: {
    getRequestTitle,
    getRequestTime,
    getRequestDatetime,
    getRequestClientName,
    getUserName,
    servicesSum,
    toPaySum,

    update: function () {
      let updateUrl;
      updateUrl = "/pest/fines/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.fine)
        .then((response) => {
          this.fine = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-fine-info", this.fine);
          // this.$router.push({name: 'pest-fine-view', params: {id: this.fine.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            sum: this.$t("fines.fine_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              sum: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/fines";
      this.loading = true;
      this.$http
        .post(updateUrl, this.fine)
        .then((response) => {
          this.fine = response.data;
          this.$eventHub.$emit("update-pest-fine-info", this.fine);
          this.$router.push({
            name: "pest-fine-update",
            params: { id: this.fine.id },
          });
          this.$bvToast.toast(this.$t("fines.fine_added"), {
            sum: this.$t("fines.fine_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              sum: this.$t("fines.cant_create_fine"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-fine-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            sum: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    deleteFine() {
      if (confirm(this.$t("fines.delete_fine_question"))) {
        let deleteUrl = "/pest/fines/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast(this.$t("fines.fine_deleted"), {
              sum: this.$t("fines.fine_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                sum: this.$t("fines.cant_delete_fine"),
                variant: "danger",
              });
            }
          });
      }
    },
    getManagerName(user) {
      return getUserName(user);
    },
    getClientName(user) {
      return getClientName(user);
    },
    handleManagerSubmit(result) {
      this.fine.managerId = result.id;
    },
    getResultManagerAutocompleteValue(user) {
      return this.getManagerName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input, type: TYPE_LOGIST },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.fine = response.data;
            // this.header = this.fine.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    getEmployeeName(user) {
      return getUserName(user);
    },
    selectEmployee(data) {
      if (data && data.value) {
        this.fine.employeeId = data.value.id;
        this.fine.employee = data.value;
      } else {
        this.fine.employeeId = null;
        this.fine.employee = {};
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>

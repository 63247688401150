<template>
  <div class="pest-equipment-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>{{ $t("equipment.new_equipment") }}</h1>
          </div>
          <div class="ibox-content">
            <pest-equipment-form :equipment="equipment"></pest-equipment-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EquipmentForm from "./EquipmentForm";
import { EQUIPMENT_PAYMENT_FIXED } from "@/modules/pest/decorators/equipments";

export default {
  name: "Equipment",
  components: {
    "pest-equipment-form": EquipmentForm,
  },
  data() {
    return {
      equipment: {
        id: "new",
        price: "new",
        title: "",
        paymentType: EQUIPMENT_PAYMENT_FIXED,
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="request-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>
              <router-link
                :to="{ name: 'pest-lead-list' }"
                class="h4 text-muted"
              >
                <font-awesome-icon icon="arrow-left" />
              </router-link>
              Создание заявки
            </h1>
          </div>
          <div class="ibox-content">
            <request-form
              :request-model="request"
              :data-loaded="true"
            ></request-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestForm from "./view/RequestForm.vue";

export default {
  name: "Request",
  components: {
    "request-form": RequestForm,
  },
  data() {
    return {
      request: {
        id: "new",
        title: "",
        parentId: "",
        groupId: "",
        statusId: "",
        managerId: "",
        manager: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      this.header = "Создать дело";
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>

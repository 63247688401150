import t from "@/i18n";

export const EQUIPMENT_PAYMENT_FIXED = "fixed";
export const EQUIPMENT_PAYMENT_PERCENT = "percent";

export const getEquipmentTitle = function (equipment) {
  if (!equipment || !equipment.title) {
    return t("common.not_set");
  }
  return equipment.title;
};
